import { getIntlValidLocale, getLocaleFromPathname } from '../helpers/locale';

const getLoginUrl = (baseUrl: string, pathname: string): string => {
  const [sanitizedBase, sanitizedPath] = [baseUrl, pathname].map((path) =>
    path.replace(/^\//, '').replace(/\/$/, ''),
  );
  const returnUrl = encodeURIComponent(
    `${sanitizedBase}/${sanitizedPath}/?loggedIn=true`,
  );

  return `/auth/login/?lang=${getIntlValidLocale(
    getLocaleFromPathname(pathname),
  )}&returnTo=${returnUrl}`;
};

export default getLoginUrl;
