import type { LinkProps as ReactRouterLinkProps } from 'react-router-dom';
import {
  generatePath,
  Link as ReactRouterLink,
  useParams,
} from 'react-router-dom';
import type { LocationDescriptorObject, Pathname } from 'history';

import { ensureTrailingSlash } from '../../helpers/url';

interface LocationDescriptorObjectWithPathname
  extends LocationDescriptorObject {
  pathname: Pathname;
}

export interface LinkProps extends ReactRouterLinkProps {
  className?: string;
  to: Pathname | LocationDescriptorObjectWithPathname;
  params?: Record<string, string>;
}

const Link = ({ to, params = {}, ...props }: LinkProps) => {
  const { language = 'en' } = useParams<{ language: string }>();

  const decodedUri = decodeURIComponent(
    generatePath(typeof to === 'object' ? to.pathname : to, {
      language,
      ...params,
    }),
  );

  const pathname = ensureTrailingSlash(decodedUri);

  return (
    <ReactRouterLink
      {...props}
      to={{ ...(typeof to === 'object' && to), pathname }}
      href={pathname}
    />
  );
};

export function ConditionalLink({
  children,
  condition,
  ...props
}: LinkProps & { condition: boolean }) {
  return Boolean(condition) && props.to ? (
    <Link {...props}>{children}</Link>
  ) : (
    <>{children}</>
  );
}

export default Link;
