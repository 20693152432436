import { css } from '@emotion/react';

export default css`
  .routeTransition-exit {
    min-height: 100%;
    opacity: 1;
    position: absolute;
    transition: opacity 0.2s linear;
    width: 100%;
  }

  .routeTransition-exit-active {
    min-height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }
`;
