import type { FC } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withApollo } from '@apollo/client/react/hoc';

import ComponentErrorBoundary from '@app/components/ComponentErrorBoundary';
import type { AudioPlayerActions } from '@app/redux/actions/audioPlayer';
import audioPlayerActions from '@app/redux/actions/audioPlayer';
import videoOverlayActions from '@app/redux/actions/videoOverlay.actions';

import ProductTile from './ProductTile';
import type { ProductTileProps } from './ProductTile.types';
import ProductTileError from './ProductTileError';

export interface AudioPlayerContainer {
  audioPlayer;
}

const stateToProps = (state): AudioPlayerContainer => {
  return {
    audioPlayer: state.audioPlayer,
  };
};

interface DispatchProps {
  audioPlayerDispatchers: AudioPlayerActions;
  videoOverlayDispatchers;
}
const dispatchToProps = (dispatch): DispatchProps => {
  const audioPlayerDispatchers = bindActionCreators(
    audioPlayerActions,
    dispatch,
  );

  const videoOverlayDispatchers = bindActionCreators(
    videoOverlayActions,
    dispatch,
  );

  return {
    audioPlayerDispatchers,
    videoOverlayDispatchers,
  };
};

const TileComponent = compose(
  withApollo,
  connect(stateToProps, dispatchToProps),
)(ProductTile) as FC<ProductTileProps>;

export default ComponentErrorBoundary(TileComponent, ProductTileError);
