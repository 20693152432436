import type { FC, SVGAttributes } from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

interface Props extends SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;

const SvgSpinner = styled.svg`
  animation: ${rotate} 3s linear infinite;
  display: inline-block;

  & circle {
    stroke: hsl(210, 70, 75);
    stroke-linecap: round;
    animation: ${dash} 1.5s ease-in-out infinite;
  }
`;

const Loader: FC<Props> = ({ color = '#000', size = 16, ...props }) => (
  <SvgSpinner viewBox="0 0 50 50" width={size} height={size} {...props}>
    <circle
      data-testid="loader"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="5"
      stroke={color}
    />
  </SvgSpinner>
);

export default Loader;
