import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Card, Flex, Image, Text } from '@theme-ui/components';
import { css, useTheme } from '@emotion/react';

import FormattedMoney from '@app/components/FormattedMoney';
import { H4 } from '@app/components/typography';
import { getOptimizedImage } from '@app/helpers/image';
import { rem } from '@app/styles/theme/layout';

import messages from './ProductTile.messages';
import styles from './ProductTile.styles';
import type { SimpleProductTileProps } from './ProductTile.types';

const SimpleProductTile: FC<SimpleProductTileProps> = ({ product }) => {
  const theme = useTheme();

  const optimizedImage = getOptimizedImage({
    image: product.image_url,
    width: '600',
  });
  const detailPageUrl = product?.detail_page_url ?? '';

  return (
    <Flex
      data-testid="simple-product-tile"
      sx={{
        flexDirection: 'column',
        a: {
          color: theme.colors.black,
        },
      }}
    >
      <Card
        sx={{ cursor: 'pointer', overflow: 'hidden', aspectRatio: 'auto 4/3' }}
        mb={3}
      >
        <a href={detailPageUrl} data-testid="simple-product-tile-card-link">
          <Image
            sx={{ display: 'block' }}
            css={css`
              &:hover {
                transition: all 0.5s;
                transform: scale(1.03);
              }
            `}
            data-testid="simple-product-tile-image"
            src={optimizedImage}
          />
        </a>
      </Card>

      <Flex mb={2} sx={{ width: '100%', justifyContent: 'space-between' }}>
        <H4
          data-testid="simple-product-tile-title"
          css={css`
            margin-top: 0;
          `}
        >
          <a href={detailPageUrl}> {product.name}</a>
        </H4>
        <Flex
          sx={{ flexDirection: 'column', alignItems: 'end' }}
          color={theme.colors.gradientGreen}
        >
          <Text
            sx={{ display: 'block', fontSize: theme.fontSizes[0] }}
            mb={rem(3)}
          >
            <FormattedMessage {...messages.included} />
          </Text>
          <Text
            data-testid="simple-product-tile-included-price"
            sx={{
              display: 'block',
              fontSize: theme.fontSizes[1],
              fontWeight: '600',
              textDecoration: 'line-through',
            }}
          >
            <FormattedMoney {...product.full_price} />
          </Text>
        </Flex>
      </Flex>
      <Box
        css={styles.description}
        data-testid="simple-product-tile-description"
      >
        <a href={detailPageUrl}>{product.description}</a>
      </Box>
    </Flex>
  );
};

export default SimpleProductTile;
