import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'components.notfound.title',
    defaultMessage: 'Page not found',
  },
  headline: {
    id: 'components.notfound.headline',
    defaultMessage: 'Oops, something went wrong here',
  },
  message: {
    id: 'components.notfound.message',
    defaultMessage: 'Sorry, the requested page could not be found.',
  },
  homepage: {
    id: 'components.notfound.homepage',
    defaultMessage: 'Go to Homepage',
  },
});
