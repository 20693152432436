/*
 * Product Finder SORT BY
 *
 * This contains all the text for the SortBy functionality
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  placeholder: {
    id: 'components.ProductSort.placeholder',
    defaultMessage: 'Sort by',
  },
  relevance: {
    id: 'components.ProductSort.sortyBy.relevance',
    defaultMessage: 'Sort by relevance',
  },
  price_asc: {
    id: 'components.ProductSort.sortyBy.priceAsc',
    defaultMessage: 'Price: low to high',
  },
  price_desc: {
    id: 'components.ProductSort.sortyBy.priceDesc',
    defaultMessage: 'Price: high to low',
  },
  date_desc: {
    id: 'components.ProductSort.sortyBy.dateDesc',
    defaultMessage: 'Latest',
  },
});
