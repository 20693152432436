export const Translations360Tile = {
  en: {
    description:
      'Get easy access to over 150 professional music plugins, instruments, and effects. Choose from three tailored plans',
    cto: 'Try it free for 14 days',
  },
  de: {
    description:
      'Dein Zugang zu über 150 professionellen Musik-Plug-ins, Instrumenten und Effekten. Drei maßgeschneiderte Abo-Modelle stehen zur Auswahl.',
    cto: 'Kostenlos 14 Tage lang testen',
  },
  es: {
    description:
      'Accede fácilmente a más de 150 plug-ins, instrumentos y efectos musicales profesionales. Elige entre tres planes a medida.',
    cto: 'Pruébalo gratis durante 14 días',
  },
  fr: {
    description:
      'Accède facilement à plus de 150 plugins, instruments et effets musicaux professionnels. Choisis parmi trois plans personnalisés.',
    cto: 'Essayez gratuitement pendant 14 jours',
  },
  zh: {
    description:
      '轻松获得 150 多种专业音乐插件、乐器和特效。有三种量身定制的计划可供选择。',
    cto: '免费试用14天',
  },
  ja: {
    description:
      '150以上のプロ仕様の音楽プラグイン、音源、エフェクトに簡単にアクセスできます。ニーズに合わせた3つのプランからお選びください。',
    cto: '14日間無料でお試しください',
  },
};
