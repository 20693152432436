import cookie from 'js-cookie';

import { Magento2_CountryCodeEnum } from '@__generated__/globalTypes';

import { clearAndSetCookie } from './cookie';

export {
  COUNTRY_COOKIE,
  COUNTRY_HEADER,
  LANGUAGE_HEADER,
  SHOP_STORE_CODE_COOKIE,
  SHOP_STORE_HEADER,
  STORE_CODE_DEFAULT,
  getLocalizationCountryCookie,
  setLocalizationCountryCookie,
  type Language,
  mapToShopLocale,
};

const COUNTRY_COOKIE = 'localization_country';

const COUNTRY_HEADER = 'x-country';
const LANGUAGE_HEADER = 'x-language';

const SHOP_STORE_CODE_COOKIE = 'store-view-code';

const SHOP_STORE_HEADER = 'store';

const STORE_CODE_DEFAULT = 'usa_usd_en';

const getLocalizationCountryCookie = (): string =>
  cookie.get(COUNTRY_COOKIE) || Magento2_CountryCodeEnum.US;

const setLocalizationCountryCookie = (country: string): void => {
  clearAndSetCookie(COUNTRY_COOKIE, country);
};

/*
 Shop back-end requires locales with country.

 We ignore country, since using the country can lead to locales that are invalid
 for our stores, e.g. we don't have any GB stores for en_GB, which leads
 to a empty store code, while do have a GB store with en_US that should be used.
*/
type Language = 'en' | 'de' | 'fr' | 'zh' | 'es' | 'jp';

const mapToShopLocale = (language: Language): string =>
  ({
    de: 'de_DE',
    en: 'en_US',
    es: 'es_ES',
    fr: 'fr_FR',
    jp: 'ja_JP',
    zh: 'zh_Hans_CN',
  })[language];
