import type { Comparison, Condition } from '@__generated__/globalTypes';

interface ContentCondition
  extends Omit<Condition, 'and' | 'or' | 'not' | 'in' | 'nin'> {
  and?: (ContentCondition | null)[] | null;
  or?: (ContentCondition | null)[] | null;
  not?: ContentCondition | null;
  in?: Comparison | null;
  nin?: Comparison | null;
}

const transformCondition = (
  condition: ContentCondition | null,
  template: { [key: string]: number[] } = {},
): Condition => {
  if (!condition) {
    return {};
  }

  const keys = Object.keys(condition);
  const type = keys[0];
  const value = condition[type];

  if (['and', 'or'].includes(type)) {
    return {
      [type]: value.map((c) => transformCondition(c, template)),
    };
  }

  if (typeof value.value === 'string' && value.value in template) {
    const templateValues = template[value.value];
    if (templateValues) {
      return {
        [type]: {
          ...value,
          value: templateValues,
        },
      };
    }
  }

  return { [type]: value };
};

export default transformCondition;
