import type { Magento2_RestApi_Product } from '@__graphql__/types';

import type { ProductFragmentFragment } from '../__graphql__/GetProductsForFinder';

export interface ProductTileProps {
  product: ProductFragmentFragment | Magento2_RestApi_Product;
  ratio?: number[];
  variant?: string | null;
}

export interface CatalogProductTileProps {
  product: ProductFragmentFragment;
  ratio?: number[];
}

export interface SimpleProductTileProps {
  product: Magento2_RestApi_Product;
  ratio?: number[];
}

export enum ProductTileVariant {
  CATALOG = 'catalog',
  SIMPLE = 'simple',
}
