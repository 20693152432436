import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '@__graphql__/types';

const defaultOptions = {} as const;
export type CustomerCartIdQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type CustomerCartIdQuery = {
  magento2?: { customerCart: { id: string } } | null;
};

export type GuestCartIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type GuestCartIdQuery = {
  magento2?: { cart?: { id: string } | null } | null;
};

export type MergeCartsMutationVariables = Types.Exact<{
  sourceId: Types.Scalars['String'];
  destinationId: Types.Scalars['String'];
}>;

export type MergeCartsMutation = {
  magento2?: { mergeCarts: { id: string } } | null;
};

export type CreateEmptyCartMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type CreateEmptyCartMutation = {
  magento2?: { createEmptyCart?: string | null } | null;
};

export const CustomerCartIdDocument = gql`
  query CustomerCartId {
    magento2 {
      customerCart {
        id
      }
    }
  }
`;

/**
 * __useCustomerCartIdQuery__
 *
 * To run a query within a React component, call `useCustomerCartIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerCartIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerCartIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerCartIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CustomerCartIdQuery,
    CustomerCartIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerCartIdQuery, CustomerCartIdQueryVariables>(
    CustomerCartIdDocument,
    options
  );
}
export function useCustomerCartIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomerCartIdQuery,
    CustomerCartIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerCartIdQuery, CustomerCartIdQueryVariables>(
    CustomerCartIdDocument,
    options
  );
}
export type CustomerCartIdQueryHookResult = ReturnType<
  typeof useCustomerCartIdQuery
>;
export type CustomerCartIdLazyQueryHookResult = ReturnType<
  typeof useCustomerCartIdLazyQuery
>;
export type CustomerCartIdQueryResult = Apollo.QueryResult<
  CustomerCartIdQuery,
  CustomerCartIdQueryVariables
>;
export const GuestCartIdDocument = gql`
  query GuestCartId($id: String!) {
    magento2 {
      cart(cart_id: $id) {
        id
      }
    }
  }
`;

/**
 * __useGuestCartIdQuery__
 *
 * To run a query within a React component, call `useGuestCartIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestCartIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestCartIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGuestCartIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GuestCartIdQuery,
    GuestCartIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GuestCartIdQuery, GuestCartIdQueryVariables>(
    GuestCartIdDocument,
    options
  );
}
export function useGuestCartIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GuestCartIdQuery,
    GuestCartIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GuestCartIdQuery, GuestCartIdQueryVariables>(
    GuestCartIdDocument,
    options
  );
}
export type GuestCartIdQueryHookResult = ReturnType<typeof useGuestCartIdQuery>;
export type GuestCartIdLazyQueryHookResult = ReturnType<
  typeof useGuestCartIdLazyQuery
>;
export type GuestCartIdQueryResult = Apollo.QueryResult<
  GuestCartIdQuery,
  GuestCartIdQueryVariables
>;
export const MergeCartsDocument = gql`
  mutation MergeCarts($sourceId: String!, $destinationId: String!) {
    magento2 {
      mergeCarts(
        source_cart_id: $sourceId
        destination_cart_id: $destinationId
      ) {
        id
      }
    }
  }
`;
export type MergeCartsMutationFn = Apollo.MutationFunction<
  MergeCartsMutation,
  MergeCartsMutationVariables
>;

/**
 * __useMergeCartsMutation__
 *
 * To run a mutation, you first call `useMergeCartsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeCartsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeCartsMutation, { data, loading, error }] = useMergeCartsMutation({
 *   variables: {
 *      sourceId: // value for 'sourceId'
 *      destinationId: // value for 'destinationId'
 *   },
 * });
 */
export function useMergeCartsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MergeCartsMutation,
    MergeCartsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MergeCartsMutation, MergeCartsMutationVariables>(
    MergeCartsDocument,
    options
  );
}
export type MergeCartsMutationHookResult = ReturnType<
  typeof useMergeCartsMutation
>;
export type MergeCartsMutationResult =
  Apollo.MutationResult<MergeCartsMutation>;
export type MergeCartsMutationOptions = Apollo.BaseMutationOptions<
  MergeCartsMutation,
  MergeCartsMutationVariables
>;
export const CreateEmptyCartDocument = gql`
  mutation CreateEmptyCart {
    magento2 {
      createEmptyCart
    }
  }
`;
export type CreateEmptyCartMutationFn = Apollo.MutationFunction<
  CreateEmptyCartMutation,
  CreateEmptyCartMutationVariables
>;

/**
 * __useCreateEmptyCartMutation__
 *
 * To run a mutation, you first call `useCreateEmptyCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmptyCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmptyCartMutation, { data, loading, error }] = useCreateEmptyCartMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateEmptyCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEmptyCartMutation,
    CreateEmptyCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEmptyCartMutation,
    CreateEmptyCartMutationVariables
  >(CreateEmptyCartDocument, options);
}
export type CreateEmptyCartMutationHookResult = ReturnType<
  typeof useCreateEmptyCartMutation
>;
export type CreateEmptyCartMutationResult =
  Apollo.MutationResult<CreateEmptyCartMutation>;
export type CreateEmptyCartMutationOptions = Apollo.BaseMutationOptions<
  CreateEmptyCartMutation,
  CreateEmptyCartMutationVariables
>;
