import { combineReducers } from 'redux';

import { reducer as catalog } from '../catalog';
import { reducer as modal } from '../modal';
import audioPlayer from './audioPlayer';
import routes from './routes';
import videoOverlay from './videoOverlay.reducer';

const rootReducer = combineReducers({
  routes,
  modal,
  audioPlayer,
  videoOverlay,
  catalog,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
