import type { ComponentType, FC } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { SentryRoute } from '@app/helpers/sentry';

import { APP_BASE_URL, APP_LANGUAGES } from '../../../config/client.config';
import LanguageProvider from '../../context/LanguageProvider';
import type { LanguageQuery as LanguageQueryType } from '../../graphql/__generated__/LanguageQuery';
import LanguageQuery from '../../graphql/LanguageQuery';
import {
  getIntlValidLocale,
  getLocaleFromPathname,
} from '../../helpers/locale';
import { ensureTrailingSlash } from '../../helpers/url';
import Modal from '../Modal';
import generateAlternateLinks from './generateAlternateLinks';
import InterceptorRoute from './InterceptorRoute';

const RedirectWithLang: FC<{
  pathname: string;
  search?: string;
}> = ({ pathname, search }) => {
  const { loading, error, data } = useQuery<LanguageQueryType>(LanguageQuery);
  const language = data?.user?.language;

  if (!(!loading && !error && language)) return null;

  return <Redirect to={{ search, pathname: `/${language}${pathname}` }} />;
};

export interface RouteType {
  path: string;
  component: ComponentType<{ route: RouteType }>;
  layout: ComponentType<{
    route: RouteType;
    children: React.ReactNode;
    noBackgroundColor?: boolean;
  }>;
  name: string;
  redirect?: string;
  exact?: boolean;
}

export interface RoutesWithLayoutProps {
  routes: RouteType[];
}

const RoutesWithLayout: FC<RoutesWithLayoutProps> = ({ routes }) => {
  const location = useLocation();
  const { pathname, search } = location;
  const localeSegment = getLocaleFromPathname(pathname);
  const pathnameWithTrailingSlash = ensureTrailingSlash(pathname);

  // If there is no language segment in the url,
  // detect the user language and redirect
  if (!APP_LANGUAGES.includes(localeSegment)) {
    return (
      <RedirectWithLang pathname={pathnameWithTrailingSlash} search={search} />
    );
  }

  if (pathname !== pathnameWithTrailingSlash) {
    return <Redirect to={{ search, pathname: pathnameWithTrailingSlash }} />;
  }

  const locale = getIntlValidLocale(localeSegment);
  const currentUrl = `${APP_BASE_URL}${pathname}`;
  const altLinks = generateAlternateLinks(pathname);

  return (
    <>
      <LanguageProvider locale={locale}>
        <Switch location={location}>
          {routes.map((route) =>
            route.redirect ? (
              <Redirect
                key={`route-${route.name || route.path}`}
                from={route.path}
                to={{
                  search,
                  pathname: ensureTrailingSlash(route.redirect),
                }}
                exact={route.exact}
              />
            ) : (
              <SentryRoute
                key={`route-${route.name}`}
                path={route.path}
                exact={route.exact}
                render={(props) => {
                  const RouteComponent = route.component;
                  const LayoutComponent = route.layout;
                  return (
                    <>
                      <InterceptorRoute {...props} />
                      <Modal key="modal-key" />
                      <LayoutComponent
                        {...props}
                        noBackgroundColor={route.name === 'subscription'}
                        route={route}
                      >
                        <RouteComponent {...props} route={route} />
                      </LayoutComponent>
                    </>
                  );
                }}
              />
            ),
          )}
        </Switch>
      </LanguageProvider>

      <Helmet>
        <html lang={locale} />
        <meta property="og:url" content={currentUrl} />
        <link href={`${currentUrl}${search}`} rel="canonical" />
        {altLinks.map(({ altLang, url }) => (
          <link
            key={`alternate-${altLang}`}
            rel="alternate"
            hrefLang={altLang}
            href={url}
          />
        ))}
      </Helmet>
    </>
  );
};

export default RoutesWithLayout;
