// fontSizes
//  Array of numbers or string to use as a typographic scale

import { rem } from './layout';

const fontSizes: number | string[] = [
  rem(12),
  rem(14),
  rem(16),
  rem(20),
  rem(24),
  rem(32),
  rem(48),
  rem(64),
];
export default fontSizes;
