import fetch from 'cross-fetch';
import cookie from 'js-cookie';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, type Operation } from '@apollo/client';

import { SHOP_STORE_CODE_COOKIE, SHOP_STORE_HEADER, LANGUAGE_HEADER, COUNTRY_HEADER, type Language } from '@app/helpers/localization';

import { FEATURE_TOKEN_COOKIE, FEATURE_TOKEN_HEADER } from '../../../config/client.config';
import { dataIdFromObject, typeDefs, typePolicies } from './cacheConfig';
import LogErrorsLink from './LogErrorsLink';

const directusSuperClientLink = new ApolloLink((operation, forward) => {
  const { directusSuperClientToken } = operation.getContext();
  if (directusSuperClientToken) {
    operation.setContext({
      headers: {
        directus_super_client_token: directusSuperClientToken,
      },
    });
  }

  return forward(operation);
});

const createApolloClient = ({ shopStoreCode, language, country }: { shopStoreCode: string, language: Language, country: string }) => {
  const cache = new InMemoryCache({
    typePolicies,
    dataIdFromObject,
  }).restore(global.__APOLLO_STATE__);

  const storeCode = shopStoreCode ?? cookie.get(SHOP_STORE_CODE_COOKIE);

  const headersLink = new ApolloLink((operation, forward) => {
    const accessToken = cookie.get('access-token');
    const featureToken = cookie.get(FEATURE_TOKEN_COOKIE);

      operation.setContext({
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
          [FEATURE_TOKEN_HEADER]: featureToken || '',
        },
      });

    return forward(operation);
  });

  const httpLinkConfig = {
    batchInterval: 10,
    batchMax: 10,
    credentials: 'same-origin',
    fetch,
  };

  const defaultClient = "graphql-v1";
  const clients = {
  "graphql-v1": new HttpLink({
    ...httpLinkConfig,
    uri: `${window.location.origin}/graphql`,
    headers: {
      ...(storeCode && {
          [SHOP_STORE_HEADER]:storeCode
      })
    }
  }),
  "graphql-v2": new HttpLink({
    ...httpLinkConfig,
    uri: `${window.location.origin}/graphql/v2`,
    headers: {
        [LANGUAGE_HEADER]: language,
        [COUNTRY_HEADER]: country,
    }
  })
}

const isRequestedClient = (clientName: string) => (op: Operation) =>
  op.getContext().clientName === clientName;

const clientResolverLink = Object.entries(clients)
  .map(([clientName, link]) => ([clientName, ApolloLink.from([link])] as const))
  .reduce(([_, previousLink], [clientName, nextLink]) => {

    const chainedLink = ApolloLink.split(
      isRequestedClient(clientName),
      nextLink,
      previousLink
    )

    return [clientName, chainedLink];
  }, ["_default", clients[defaultClient]])[1]

  const logErrorsLink = new LogErrorsLink();

  const apolloClient = new ApolloClient({
    connectToDevTools: true,
    link: ApolloLink.from([
      headersLink,
      directusSuperClientLink,
      logErrorsLink,
      clientResolverLink,
    ]),
    cache,
    typeDefs,
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
    },
  });

  return { apolloClient };
};

export default createApolloClient;
