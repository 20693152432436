import { Text } from '@theme-ui/components';
import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import theme from '@app/styles/theme';
import { rem } from '@app/styles/theme/layout';

export const PRODUCT_TILE_RATIO = [460, 360];

const wrapper: SerializedStyles = css`
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: box-shadow 100ms ease-out;
  background-color: ${theme.colors.white};
  max-width: ${rem(438)};
  margin: 0 auto;
  &:hover {
    box-shadow: ${rem(0)} ${rem(1)} ${rem(4)} rgba(0, 0, 0, 0.08);
  }
`;

const imageWrapper: SerializedStyles = css`
  width: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    .product-tile__card {
      transition: all 0.5s;
      transform: scale(1.03);
    }
  }
`;

const card: SerializedStyles = css`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  background: ${theme.colors.white};
`;

const contentWrapper: SerializedStyles = css`
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;

  a {
    color: ${theme.colors.black};
  }
`;

const textContent: SerializedStyles = css`
  text-align: center;
  padding: ${rem(30)} ${rem(25)} ${rem(12)};
  margin: 0 auto;
`;

const title: SerializedStyles = css`
  padding-bottom: ${rem(12)};
  font-weight: 500;
  font-size: ${theme.fontSizes[3]};
  margin-bottom: 0;
  margin-top: 0;
`;

const sticker: SerializedStyles = css`
  position: absolute;
  z-index: 1;
  top: ${rem(10)};
  left: ${rem(10)};
  padding: ${rem(6)} ${rem(8)};
  line-height: 1;
  color: #333;
  white-space: nowrap;
  vertical-align: baseline;
  background: ${theme.colors.white};
  font-size: ${rem(14)};
  font-weight: 500;
`;

const mediaWrapper: SerializedStyles = css`
  position: absolute;
  bottom: 6%;
  justify-content: center;
  width: 100%;
  > div:first-of-type {
    margin-right: ${theme.space[3]};
  }
`;

const description: SerializedStyles = css`
  font-size: ${theme.fontSizes[1]};
  line-height: 1.5;
  /* it looks fine to use according to mozilla dev portal:
      https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp
  */
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const mediaIcon: SerializedStyles = css`
  position: absolute;
  top: 82%;
  left: 50%;
  transform: translate(-50%);
  width: ${rem(34)};
  height: ${rem(34)};
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  > img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const priceWrapper: SerializedStyles = css`
  border-top: ${rem(2)} solid ${theme.colors.productFinderBg};
  align-items: center;
`;

export const BundleLabel = styled(Text)`
  font-size: ${rem(12)};
  color: ${theme.colors.productTileAcidGreen};
  text-transform: initial;
  margin: ${rem(6)} 0 0;
  display: block;
`;

export const GreenLabel = styled(Text)`
  display: block;
  font-size: ${rem(14)};
  color: ${theme.colors.productTileAcidGreen};
  text-align: center;
  border-top: ${rem(2)} solid ${theme.colors.productFinderBg};
`;

export default {
  wrapper,
  imageWrapper,
  card,
  contentWrapper,
  textContent,
  title,
  sticker,
  description,
  mediaIcon,
  priceWrapper,
  mediaWrapper,
};
