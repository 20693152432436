import type { ElementType } from 'react';
import styled from '@emotion/styled';

import { tabletPortrait } from '@app/styles/theme/breakpoints';
import { rem } from '@app/styles/theme/layout';

interface TypographyProps {
  as?: ElementType;
}

const getSharedStyles = (_props: TypographyProps) => `
  line-height: 1.15;
  margin-bottom: 0.55rem;
  margin-top: 0.35rem;
  font-weight: 600;
`;

export const H1 = styled.h2<TypographyProps>`
  ${(props: TypographyProps) => getSharedStyles(props)}
  font-size: ${rem(40)};

  @media screen and (max-width: ${tabletPortrait}) {
    font-size: ${rem(30)};
  }
`;

export const H2 = styled.h2<TypographyProps>`
  ${(props: TypographyProps) => getSharedStyles(props)}
  font-size: ${rem(32)};
`;

export const H3 = styled.h3<TypographyProps>`
  ${(props: TypographyProps) => getSharedStyles(props)}
  font-size: ${rem(24)};
`;

export const H4 = styled.h4<TypographyProps>`
  ${(props: TypographyProps) => getSharedStyles(props)}
  font-size: ${rem(20)};
`;
