import { useTheme } from '@emotion/react';
import { Box, Flex } from '@theme-ui/components';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import type { match as MatchInterface } from 'react-router-dom';
import { Link, useHistory, useLocation } from 'react-router-dom';

import type { Condition } from '@__generated__/globalTypes';
import { getButtonStyles } from '@app/components/Button';
import { H2 } from '@app/components/typography';
import useMediaQuery from '@app/helpers/useMediaQuery';
import useLocaleWithFallback from '@app/hooks/useLocaleWithFallback';
import useLocationSearch from '@app/hooks/useLocationSearch';
import { useAppSelector } from '@app/redux/hooks/useAppSelector';
import { tabletLandscape } from '@app/styles/theme/breakpoints';
import { rem } from '@app/styles/theme/layout';

import {
  TITLE_360,
  productListWith360Tile,
  shouldApplyABtest,
} from '../360TileABTest';
import ErrorContent from '../ErrorContent';
import ProductPlaceholders from '../ProductPlaceholders';
import ProductTile from '../ProductTile';
import type {
  GetProductsForFinderQuery,
  GetProductsForFinderQueryVariables,
} from '../__graphql__/GetProductsForFinder';
import { useGetProductsForFinderQuery } from '../__graphql__/GetProductsForFinder';
import type { FinderState } from '../url';
import messages from './ProductList.messages';
import styles from './ProductList.styles';

const PRODUCTS_CHUNK_SIZE = 12;

export const PRODUCT_LIST_GRID = ['100%', '50%', '33,33%', '25%'];

export interface ProductListProps {
  grid?: string[]; // define the columns of the grid.
  match: MatchInterface;
  condition?: Condition | null;
  language?: string;
  searchState?: FinderState;
}

const ProductList = ({
  grid = PRODUCT_LIST_GRID,
  match,
  language,
  searchState,
  condition,
}: ProductListProps) => {
  const { searching, sorting } = searchState ?? {};
  const pageQuery = Number(useLocationSearch('page')) ?? 0;
  const location = useLocation();
  const history = useHistory();
  const { getLanguagesWithFallback } = useLocaleWithFallback();

  const variables = {
    condition,
    page: pageQuery ? pageQuery - 1 : 0,
    language,
    hitsPerPage: PRODUCTS_CHUNK_SIZE,
    ...(searchState && {
      sortBy: sorting as GetProductsForFinderQueryVariables['sortBy'],
      search: searching,
    }),
  };

  const { error, refetch, data, loading } = useGetProductsForFinderQuery({
    variables,
  });

  const { state } = useLocation<{
    previousPage?: number;
    previousItems?: Array<GetProductsForFinderQuery['getProducts']['result']>;
    nextItems?: Array<GetProductsForFinderQuery['getProducts']['result']>;
  }>();

  // clear the previous loaded items from the router state when the component is mounted/updated
  useEffect(() => {
    history.replace({ ...location, state: {} });
    // This will be deleted in ni-v2, no need to fix!
  }, []);

  const productsResult = data?.getProducts?.result;

  const areUserLicensesHidden = useAppSelector(
    (state) => state.catalog.areUserLicensesHidden,
  );
  const isDesktop = useMediaQuery(`(min-width: ${tabletLandscape})`, {
    ssrMatch: true,
  });
  const theme = useTheme();

  if (error) {
    return (
      <ErrorContent
        products={productsResult}
        refetch={refetch}
        data-testid="error-message"
      />
    );
  }

  const pageItems = [
    ...(state?.previousItems ?? []),
    productsResult,
    ...(state?.nextItems ?? []),
  ];

  const initialProducts = pageItems.flatMap((item) => item?.items ?? []);

  const lang = getLanguagesWithFallback()[0];

  const products = shouldApplyABtest(location.pathname, lang)
    ? productListWith360Tile(initialProducts, lang, location.pathname)
    : initialProducts;

  const { currentPage, hasNextPage } =
    pageItems[pageItems.length - 1]?.pageInfo ?? {};

  const isLoadingPrevious =
    loading || Number(pageItems[0]?.pageInfo.currentPage) > 0;

  if (!loading && products.length === 0) {
    return (
      <Box
        px={4}
        my={rem(300)}
        data-testid="no-products"
        sx={{ textAlign: 'center', color: theme.colors.labelGray }}
      >
        <H2>
          <FormattedMessage
            {...messages[
              areUserLicensesHidden && isDesktop ? 'youOwn' : 'noProducts'
            ]}
          />
        </H2>
      </Box>
    );
  }

  const getSearchLink = (page: 'previous' | 'next') => {
    const urlParams = new URLSearchParams({
      ...(searching && { searching }),
      ...(sorting && { sorting }),
    });

    if (page === 'previous') {
      const isFirstPage = pageQuery - 1 < 2;

      if (!isFirstPage) {
        const previousPage = pageItems[0]?.pageInfo?.currentPage || 0;

        urlParams.append('page', previousPage.toString());
      }
    }

    if (page === 'next') {
      const nextPage = pageQuery ? pageQuery + 1 : (currentPage ?? 1) + 2;
      urlParams.append('page', nextPage.toString());
    }

    return urlParams.toString();
  };

  return (
    <Box data-testid="product-finder-product-list-wrapper">
      {loading ? (
        <Box
          css={styles.loadMoreWrapper}
          data-testid="product-finder-product-list-preloader-before"
        >
          <ProductPlaceholders count={8} />
        </Box>
      ) : (
        isLoadingPrevious && (
          <Flex css={styles.loadMoreWrapper} py={4}>
            <Link
              to={{
                pathname: match.url,
                search: getSearchLink('previous'),
                state: {
                  nextItems: pageItems,
                },
              }}
              data-testid="load-previous"
              css={getButtonStyles({ type: 'primary', wide: true, theme })}
            >
              <FormattedMessage {...messages.loadPrevious} />
            </Link>
          </Flex>
        )
      )}

      <Flex css={styles.mainWrapper}>
        {products.map((product, i) => (
          <Box
            id={`${product.id}`}
            css={styles.boxStyle()}
            key={i}
            px={[0, 2]}
            py={3}
            sx={{
              width: grid,
              display: product.id === TITLE_360 ? 'none' : 'flex',
            }}
            className={`product-box ${
              // @ts-expect-error attributes ts error
              product.attributes?.find((x) => x.key === 'abTestClassName')
                ?.value
            }`}
          >
            {/* @ts-expect-error type error with ab tasty tile */}
            <ProductTile product={product} />
          </Box>
        ))}
      </Flex>

      {loading ? (
        <Box
          css={styles.loadMoreWrapper}
          data-testid="product-finder-product-list-preloader"
        >
          <ProductPlaceholders count={8} />
        </Box>
      ) : (
        hasNextPage && (
          <Flex css={styles.loadMoreWrapper} py={4}>
            <Link
              to={{
                pathname: match.url,
                search: getSearchLink('next'),
                state: {
                  previousItems: pageItems,
                },
              }}
              data-testid="load-more"
              css={getButtonStyles({ type: 'primary', wide: true, theme })}
            >
              <FormattedMessage {...messages.loadMore} />
            </Link>
          </Flex>
        )
      )}
    </Box>
  );
};

export default ProductList;
