import { defineMessages } from 'react-intl';

export default defineMessages({
  hideUserLicenses: {
    id: 'catalog.ownedProducts.hide',
    defaultMessage: 'Hide owned products',
  },
  showUserLicenses: {
    id: 'catalog.ownedProducts.show',
    defaultMessage: 'Show owned products',
  },
  login: {
    id: 'catalog.ownedProducts.login',
    defaultMessage: 'Login to filter products',
  },
});
