import {
  APP_LANGUAGES,
  DEFAULT_LOCALE,
  LANGUAGE_TAGS_MAP,
} from '@config/common.config';

import { dateFromISO8601 } from './date';

export const getLocaleFromPathname = (pathname: string): string =>
  pathname.replace(/^\//, '').split('/')[0];

// the website url uses "jp" (e.g. native-instruments.com/jp)
// but "jp" is not a standard intl locale so we need those
export const convertToUrlLocale = (locale: string): string =>
  locale === 'ja' ? 'jp' : locale;

export const getIntlValidLocale = (locale: string): string => {
  const intlValidLocale = locale === 'jp' ? 'ja' : locale;
  return APP_LANGUAGES.includes(locale) ? intlValidLocale : DEFAULT_LOCALE;
};

export const formatDateToLocale = (
  date: string,
  language: string,
  options: Intl.DateTimeFormatOptions | undefined,
): string => {
  try {
    return dateFromISO8601(date).toLocaleDateString(
      LANGUAGE_TAGS_MAP[language],
      options,
    );
  } catch (e) {
    // biome-ignore lint/suspicious/noConsole: We want this
    console.error(e);
    return 'Invalid Date';
  }
};
