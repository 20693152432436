import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import { rem } from '@app/styles/theme/layout';

const wrapper: SerializedStyles = css`
  align-items: center;
  font-size: ${rem(16)};
`;

const input: SerializedStyles = css`
  width: 100%;
  border: none;
  outline: none;
  font-style: italic;

  ::placeholder {
    font-size: ${rem(14)};
  }
`;

export default {
  wrapper,
  input,
};
