import { lazy } from 'react';
import { Box, Card, Flex, Text } from '@theme-ui/components';

import { H2 } from '@app/components/typography';
import VideoOverlayTrigger from '@app/components/VideoOverlayTrigger';
import { useOnMount } from '@app/hooks/lifecycle';
import { ratioToPercentage } from '@app/styles/utils';

import type { AttributeFragmentFragment } from '../__graphql__/GetProductsForFinder';
import ProductImage from '../ProductImage';
import ProductPrice from '../ProductPrice';
import type { ProductPriceProps } from '../ProductPrice/ProductPrice';
import QuickAddToCartButton from '../QuickAddToCartButton';
import styles, { BundleLabel, PRODUCT_TILE_RATIO } from './ProductTile.styles';
import type { CatalogProductTileProps } from './ProductTile.types';

const AudioPlayerTrigger = lazy(
  () =>
    import(
      /*webpackChunkName: 'AudioPlayer' */ '@app/components/AudioPlayerTrigger'
    ),
);

const BUNDLE_LABEL = 'Bundle';

const getAttr = (
  key: string,
  attrs?: (AttributeFragmentFragment | null)[] | null,
) => {
  if (attrs) {
    const target = attrs.find((a) => a?.key === key);
    return target?.value;
  }
  return null;
};

const CatalogProductTile = ({
  product,
  ratio = PRODUCT_TILE_RATIO,
}: CatalogProductTileProps) => {
  const {
    title,
    sku,
    attributes,
    image,
    audioId,
    videoId,
    price,
    quickAddToCart,
    quickAddToCartSku,
  } = product;

  const isMounted = useOnMount();

  const sticker = getAttr('sticker', attributes);
  const description = getAttr('description', attributes);
  const detailPageUrl = getAttr('detail_page', attributes);
  const pricingPageUrl = getAttr('pricing_page', attributes);

  const PricingInfo = () => (
    <ProductPrice
      text={getAttr('custom_price_text', attributes)}
      shouldReplacePrice={getAttr('override_price', attributes)}
      isFree={getAttr('free', attributes)}
      price={price as ProductPriceProps['price']}
      pricingPageUrl={pricingPageUrl}
    />
  );

  return (
    <Flex
      data-testid="catalog-product-tile"
      css={styles.wrapper}
      data-sku={sku}
      className="product-tile-wrapper"
    >
      <Box pb={ratioToPercentage(ratio)} css={styles.imageWrapper}>
        {sticker && (
          <Text sx={{ display: 'block' }} css={styles.sticker}>
            {sticker}
          </Text>
        )}
        <a href={detailPageUrl}>
          <Card className="product-tile__card" css={styles.card}>
            <ProductImage image={getAttr('image_lazy', attributes) || image} />
          </Card>
        </a>
        <Flex css={styles.mediaWrapper}>
          {audioId && <AudioPlayerTrigger audioId={audioId} />}
          {videoId && <VideoOverlayTrigger videoId={videoId} />}
        </Flex>
      </Box>

      <Flex css={styles.contentWrapper} className="product-tile-content">
        <Box css={styles.textContent}>
          <H2 css={styles.title} className="product-tile-title">
            <a href={detailPageUrl}>{title}</a>
            {price?.bundle && <BundleLabel>{BUNDLE_LABEL}</BundleLabel>}
          </H2>

          <Text sx={{ display: 'block' }} mb={3} css={styles.description}>
            <a
              href={detailPageUrl}
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </Text>
        </Box>

        {isMounted && quickAddToCart && quickAddToCartSku ? (
          <Flex
            css={styles.priceWrapper}
            className="product-price-wrapper"
            sx={{ flexDirection: 'column', justifyContent: 'space-between' }}
          >
            <Box pl={3}>
              <PricingInfo />
            </Box>

            <Box p={3} pt={0} sx={{ width: '100%', fontSize: 1 }}>
              <QuickAddToCartButton sku={quickAddToCartSku} />
            </Box>
          </Flex>
        ) : (
          <Box css={styles.priceWrapper} className="product-price-wrapper">
            <PricingInfo />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default CatalogProductTile;
