import type { FC } from 'react';
import { Box, Flex } from '@theme-ui/components';

import Countdown from '../../../components/CountDown';
import {
  CategoryBannerTextAlignment,
  CategoryBannerTheme,
} from './CategoryBanner.constants';
import * as styles from './CategoryBanner.styles';
import useCategoryBanner from './useCategoryBanner';

export interface CategoryBannerProps {
  categoryBannerId: string;
}

const CategoryBanner: FC<CategoryBannerProps> = ({ categoryBannerId }) => {
  const { banner } = useCategoryBanner(categoryBannerId);

  if (!banner) return null;

  const {
    theme,
    text_alignement,
    body,
    countdown_date,
    countdown_template,
    background_mobile,
    background_desktop,
  } = banner;

  return (
    <Flex
      css={styles.containerStyles({
        backgroundMobile: background_mobile || '',
        backgroundDesktop: background_desktop || '',
      })}
    >
      <Box
        css={styles.contentContainerStyles({
          bannerTheme: isValidTheme(theme) ? theme : CategoryBannerTheme.Light,
          textAlignment: isValidTextAlignment(text_alignement)
            ? text_alignement
            : CategoryBannerTextAlignment.Center,
        })}
      >
        <Box dangerouslySetInnerHTML={{ __html: body || '' }} />
        {countdown_date && countdown_template ? (
          <Box
            css={styles.countdownContainerStyles}
            data-testid="category-banner-countdown-container"
          >
            <Countdown
              key="countdown"
              date={countdown_date}
              template={countdown_template}
            />
          </Box>
        ) : null}
      </Box>
    </Flex>
  );
};

const isValidTheme = (theme: unknown): theme is CategoryBannerTheme =>
  Object.values(CategoryBannerTheme).includes(theme as CategoryBannerTheme);

const isValidTextAlignment = (
  textAlignment: unknown,
): textAlignment is CategoryBannerTextAlignment =>
  Object.values(CategoryBannerTextAlignment).includes(
    textAlignment as CategoryBannerTextAlignment,
  );

export default CategoryBanner;
