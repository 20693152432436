import { Flex } from '@theme-ui/components';
import qs from 'query-string';

import RouterLink from '../../../components/Link';
import routes from '../../../routes/routes';
import { SearchContext } from '../ProductFinder';
import type { FinderState } from '../url';
import { generateRoute } from '../url';
import {
  menuItemStyles,
  menuLinkStyles,
  subMenuLinkStyles,
} from './TopMenu.styles';

/*
 * workaround for strange issue
 * that routes module still isn't initialised yet here
 * */
let getProductFinderPath = (): string => {
  const productFinderPath = routes.find(
    ({ name }) => name === 'product-finder',
  )!.path;
  getProductFinderPath = () => productFinderPath;
  return productFinderPath;
};

interface Props {
  children?: React.ReactNode;
  slug: string | null;
  parentSlugs: string[];
  isActive: boolean;
  isSubMenu?: boolean;
}

const MenuItem = ({
  children,
  slug,
  parentSlugs,
  isActive,
  isSubMenu,
}: Props) => {
  const className = `product-finder-${isSubMenu ? 'submenu' : 'menu'}-link`;
  const commonLinkProps = {
    css: isSubMenu ? subMenuLinkStyles : menuLinkStyles,
    className: `${isActive ? 'active' : ''} ${[
      className,
      ...parentSlugs,
      slug || 'root',
    ].join('-')}`,
  };
  return (
    <li css={isSubMenu ? null : menuItemStyles} className={className}>
      <Flex
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: ['row', 'row', 'column'],
        }}
      >
        <SearchContext.Consumer>
          {(searchState: FinderState) => (
            <RouterLink
              {...commonLinkProps}
              to={{
                pathname: getProductFinderPath(),
                ...(searchState.sorting && {
                  search: qs.stringify({ sorting: searchState.sorting }),
                }),
              }}
              params={{ url: generateRoute(parentSlugs.concat(slug || [])) }}
            >
              {children}
            </RouterLink>
          )}
        </SearchContext.Consumer>
      </Flex>
    </li>
  );
};

export default MenuItem;
