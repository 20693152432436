import styled from '@emotion/styled';

import theme from '@app/styles/theme';
import { rem } from '@app/styles/theme/layout';

export const Wrapper = styled.div`
  text-align: center;
  max-width: ${rem(640)};
  margin: ${theme.space[3]} auto;
  line-height: 1.5;
  font-size: ${theme.fontSizes[2]};
`;

export default { Wrapper };
