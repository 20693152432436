export const isNotNullish = <T>(
  value?: T,
): value is Exclude<T, null | undefined> =>
  typeof value !== 'undefined' && value !== null;

// Note that NaN is type number so we can't explicitly exclude it
export const isTruthy = <T>(
  value?: T,
): value is Exclude<T, null | undefined | '' | 0> =>
  typeof value !== 'undefined' && Boolean(value);
