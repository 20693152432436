import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import { rem } from '@app/styles/theme/layout';

export const linkStyles: SerializedStyles = css`
  margin-top: 1rem;
  display: inline-block;
  font-size: ${rem(12)};
`;

export const supportHeadingStyles: SerializedStyles = css`
  font-size: 1rem;
  font-weight: 500;
  margin: 1rem 0 0.4rem 0;
`;

export const supportParagraphStyles: SerializedStyles = css`
  margin: 0;
  font-size: ${rem(12)};
`;
