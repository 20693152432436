import { createActions } from 'redux-actions';

export interface AudioPlayerActions {
  openAudioPlayer: () => void;
  closeAudioPlayer: () => void;
  playAudioPlayer: () => void;
  pauseAudioPlayer: () => void;
  loadAudio: () => void;
  nextAudio: () => void;
  prevAudio: () => void;
  resetAudioPlayer: () => void;
  setData: (arg0) => void;
  setId: (arg0) => void;
}
export const OPEN_AUDIO_PLAYER = 'OPEN_AUDIO_PLAYER';
export const CLOSE_AUDIO_PLAYER = 'CLOSE_AUDIO_PLAYER';
export const PLAY_AUDIO_PLAYER = 'PLAY_AUDIO_PLAYER';
export const PAUSE_AUDIO_PLAYER = 'PAUSE_AUDIO_PLAYER';
export const RESET_AUDIO_PLAYER = 'RESET_AUDIO_PLAYER';
export const PREV_AUDIO = 'PREV_AUDIO';
export const NEXT_AUDIO = 'NEXT_AUDIO';
export const LOAD_AUDIO = 'LOAD_AUDIO';
export const SET_DATA = 'SET_DATA';
export const SET_ID = 'SET_ID';

export const {
  openAudioPlayer,
  closeAudioPlayer,
  pauseAudioPlayer,
  playAudioPlayer,
  resetAudioPlayer,
  nextAudio,
  prevAudio,
  loadAudio,
  setData,
  setId,
}: AudioPlayerActions = createActions({
  OPEN_AUDIO_PLAYER: undefined,
  CLOSE_AUDIO_PLAYER: undefined,
  PAUSE_AUDIO_PLAYER: undefined,
  PLAY_AUDIO_PLAYER: undefined,
  RESET_AUDIO_PLAYER: undefined,
  NEXT_AUDIO: undefined,
  PREV_AUDIO: undefined,
  LOAD_AUDIO: undefined,
  SET_DATA: (payload) => payload,
  SET_ID: (payload) => payload,
});

export default {
  openAudioPlayer,
  closeAudioPlayer,
  pauseAudioPlayer,
  playAudioPlayer,
  resetAudioPlayer,
  nextAudio,
  prevAudio,
  loadAudio,
  setData,
  setId,
};
