import { gql, useQuery } from '@apollo/client';

import { isNotNullish } from '@app/helpers/predicate';
import transformCondition from '@app/helpers/transformCondition';
import type { Condition } from '@__generated__/globalTypes';

import ErrorContent from '../ErrorContent';
import ProductPlaceholders from '../ProductPlaceholders';
import ProtectedContent from '../ProtectedContent';
import type { FinderState } from '../url';
import type {
  UpgradePaths,
  UpgradePaths_userV2_AuthenticatedUser_upgradePaths,
} from './__generated__/UpgradePaths';
import ProductList from './index';

type CustomerOffersProps = {
  condition: Condition | null;
  language: string;
  searchState: FinderState;
  children?: React.ReactNode;
};

const CustomerOffers = ({
  condition,
  language,
  searchState,
}: CustomerOffersProps) => {
  const { loading, error, data } = useQuery<UpgradePaths>(UPGRADE_PATHS, {
    fetchPolicy: 'network-only',
    ssr: false,
  });

  if (loading) {
    return <ProductPlaceholders count={4} />;
  }

  if (error) {
    return <ErrorContent />;
  }

  if (data?.userV2?.__typename !== 'AuthenticatedUser') {
    return <ProtectedContent />;
  }

  const upgradePathsIds = [
    ...new Set<number>(getUpgradePathIds(data.userV2.upgradePaths)),
  ];

  const transformedCondition = upgradePathsIds?.length
    ? transformCondition(condition, {
        '{CUSTOMER_UPGRADE_IDS}': upgradePathsIds,
      })
    : condition;

  return (
    <ProductList
      condition={transformedCondition}
      language={language}
      searchState={searchState}
    />
  );
};

const getUpgradePathIds = (
  upgradePaths: UpgradePaths_userV2_AuthenticatedUser_upgradePaths[],
) =>
  upgradePaths
    .flatMap((item) =>
      item.upgrade_path.map((path) => path?.licensedproduct_id),
    )
    .filter(isNotNullish);

const UPGRADE_PATHS = gql`
  query UpgradePaths {
    userV2 {
      ... on AuthenticatedUser {
        upgradePaths {
          upgrade_path {
            licensedproduct_id
          }
        }
      }
    }
  }
`;

export default CustomerOffers;
