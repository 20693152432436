import { gql } from '@apollo/client';
import { useCallback } from 'react';

import type { Magento2_CartItemInput } from '@app/__graphql__/types';
import type { CartItemInput } from '@app/__graphql__v2/types';
import { useGetCartId } from '@app/context/CartIdProvider/CartIdProvider';
import { isNotNullish } from '@app/helpers/predicate';
import { pushPerpetualEcommerceEvent } from '@app/helpers/tracking';
import { CART_UPDATE_RESULT_FRAGMENT } from '@app/pages/Cart/Cart.queries';
import type { CartUpdateResultFragment } from '@app/pages/Cart/__graphql__/Cart.queries';
import { useAddProductsToCartMutation } from './__graphql__/useAddProductsToCart';

interface CartError {
  code?: string;
  message?: string;
}

export const ADD_PRODUCTS_TO_CART_MUTATION = gql`
  ${CART_UPDATE_RESULT_FRAGMENT}

  mutation AddProductsToCart(
    $cartId: String!
    $cartItems: [Magento2_CartItemInput!]!
  ) {
    magento2 {
      addProductsToCart(cartId: $cartId, cartItems: $cartItems) {
        cart {
          ...CartUpdateResult
        }
        userErrors {
          code
          message
        }
      }
    }
  }
`;

const useAddProductsToCart = () => {
  const { cartId } = useGetCartId();
  const [addProductsToCart, addProductsResult] = useAddProductsToCartMutation();

  const addItems = useCallback(
    async (items: Magento2_CartItemInput[]) => {
      if (!cartId) return;

      const { data, errors } = await addProductsToCart({
        variables: { cartId, cartItems: items },
      });

      const isSuccessful =
        !errors || !data?.magento2?.addProductsToCart?.userErrors.length;

      if (isSuccessful) {
        trackAddToCart({
          cartItemsInput: items,
          cartItemsOutput: data?.magento2?.addProductsToCart?.cart.items,
        });
      }
    },
    [cartId, addProductsToCart],
  );

  const addToCartErrors = [
    addProductsResult.error,
    ...(addProductsResult.data?.magento2?.addProductsToCart?.userErrors?.filter(
      Boolean,
    ) ?? []),
  ].filter(isNotNullish) as CartError[];

  return {
    addProductsToCart,
    addProductsResult,
    addItems,
    addToCartErrors,
    addProductsLoading: addProductsResult.loading,
  };
};

export default useAddProductsToCart;

function trackAddToCart({
  cartItemsInput,
  cartItemsOutput,
}: {
  cartItemsInput: CartItemInput[];
  cartItemsOutput: CartUpdateResultFragment['items'];
}) {
  if (!cartItemsInput || !cartItemsOutput) return null;

  const skus = cartItemsInput.reduce(
    (acc, { sku, quantity }) => ({ ...acc, [sku]: quantity }),
    {},
  );

  const addedItems = Object.keys(skus).map((sku) =>
    cartItemsOutput.find((item) => item?.product.sku === sku),
  );

  addedItems.forEach((addedItem) => {
    const price = addedItem?.prices?.price;
    const sku = addedItem?.product.sku;

    if (!price || !sku) return;

    pushPerpetualEcommerceEvent('add_to_cart', {
      currency: price?.currency || 'EUR',
      value: price?.value || 0,
      items: [
        {
          item_id: sku,
          item_name: addedItem.product?.name || '',
          price: price.value || 0,
          quantity: skus[sku],
        },
      ],
    });
  });
}
