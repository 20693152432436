import { useParams } from 'react-router-dom';

import { DEFAULT_LOCALE } from '@config/common.config';

import { getIntlValidLocale } from '../helpers/locale';

const useDirectusQueryLanguages = () => {
  const { language } = useParams<{ language: string }>();

  const languages = [getIntlValidLocale(DEFAULT_LOCALE)];
  const targetLanguage = getIntlValidLocale(language);

  if (targetLanguage !== DEFAULT_LOCALE) {
    languages.push(targetLanguage);
  }

  return languages;
};

export default useDirectusQueryLanguages;
