import { FormattedMessage } from 'react-intl';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import RouterLink from '@app/components/Link';
import { fadeIn } from '@app/styles/animations';
import theme from '@app/styles/theme';
import { screenSmall } from '@app/styles/theme/breakpoints';
import { rem } from '@app/styles/theme/layout';
import { PRODUCT_FINDER_SEGMENT } from '@config/routes.config';

import messages from './SubMenu.messages';

const Wrapper = styled.nav<{ show: boolean }>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  position: fixed;
  top: ${theme.layout.headerHeight};
  left: 0;
  background: ${theme.colors.white};
  border-bottom: 1px solid ${theme.colors.lightGray};
  border-top: 1px solid ${theme.colors.lightGray};
  width: 100%;
  padding: 0;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.show ? 1 : 0)};
  animation: ${(props) =>
    props.show
      ? css`
          ${fadeIn} 0.15s cubic-bezier(0.33, 1, 0.68, 1) forwards;
        `
      : ''};

  @media (max-width: ${screenSmall}) {
    position: absolute;
    top: 100%;
    height: auto;
    border-top: none;
    padding: ${rem(32)} 0;
    animation: unset;
    opacity: 1;

    &:before {
      content: '';
      position: absolute;
      top: -1px;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: rgb(0 0 0 / 20%);
      z-index: -1;
    }
  }
`;
const List = styled.ul`
  display: flex;
  flex-grow: 2;
  max-width: ${rem(850)};
  justify-content: space-between;
  list-style: none;
  padding: 0;
  @media (max-width: ${screenSmall}) {
    flex-direction: column;
`;

const Link = styled(RouterLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  position: relative;
  padding: ${rem(24)} 0;
  color: ${theme.colors.subMenuLinkTitle};

  &:hover {
    text-decoration: underline;
  }
  @media (max-width: ${screenSmall}) {
    padding: ${rem(16)} 0;
    &:first-of-type {
      padding-top: 0;
    }
  }
`;

const Title = styled.span`
  font-size: ${rem(18)};
  line-height: 1.5;
  white-space: nowrap;
`;

const SubTitle = styled.small`
  font-size: ${theme.fontSizes[0]};
  color: ${theme.colors.subMenuLinkSubTitle};
  font-weight: normal;
  line-height: ${rem(17)};
`;

const links = [
  {
    title: <FormattedMessage {...messages.overviewTitle} />,
    sub: <FormattedMessage {...messages.overviewSub} />,
    to: `${PRODUCT_FINDER_SEGMENT}/`,
  },
  {
    title: <FormattedMessage {...messages.softwareSoundTitle} />,
    sub: <FormattedMessage {...messages.softwareSoundSub} />,
    to: `${PRODUCT_FINDER_SEGMENT}/komplete/`,
  },
  {
    title: <FormattedMessage {...messages.hardwareTitle} />,
    sub: <FormattedMessage {...messages.hardwareSub} />,
    to: `${PRODUCT_FINDER_SEGMENT}/maschine/`,
  },
  {
    title: <FormattedMessage {...messages.djingTitle} />,
    sub: <FormattedMessage {...messages.djingSub} />,
    to: `${PRODUCT_FINDER_SEGMENT}/traktor/`,
  },
];

export function SubMenu({ isMenuVisible }: { isMenuVisible: boolean }) {
  return (
    <Wrapper show={isMenuVisible}>
      <List data-testid="pricing-page-submenu-nav">
        {links.map(({ title, sub, to }, idx) => (
          <li key={idx}>
            <Link
              data-testid={`submenu-nav-item-${idx}`}
              to={`/:language/${to}`}
            >
              <Title>{title}</Title>
              <SubTitle>{sub}</SubTitle>
            </Link>
          </li>
        ))}
      </List>
    </Wrapper>
  );
}
