const CheckIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="13"
    viewBox="0 0 17 13"
    fill="#222222"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2 5L0 7L6 13L17 2L15 0L6 9L2 5Z" />
  </svg>
);

export default CheckIcon;
