const black = '#222';
const blackSecondary = '#1c1c1c';
const gray = '#9d9d9d';
const nearBlack = '#2a2c2e';
const darkGray = '#3c3c3c';
const labelGray = '#9ba7b3';
const mediumGray = '#d5d8d9';
const lightGray = '#e0e0e0';
const lighterGray = '#f8f8f8';
const blueishGray = '#edf0f2';
const shadowGray = '#cfdde6';
const gradientGray = '#939699';
const white = '#fff';
const whiteSecondary = '#f0f0f0';
const lightBlue = '#3e8cbb';
const lighterBlue = '#55b6f2';
const gradientGreen = '#26bf66';
const red = '#d64040';
const orange = '#FFA300';
const brightOrange = '#FFA700';
const darkOrange = '#D89F00';
const lightOrange = '#FBF8E3';
const acidGreen = '#2ECC71';
const blackT25 = 'rgba(0, 0, 0, 0.25)';

export default {
  white,
  whiteSecondary,
  gray,
  darkGray,
  mediumGray,
  lightGray,
  lighterGray,
  blueishGray,
  labelGray,
  shadowGray,
  gradientGray,
  black,
  blackSecondary,
  nearBlack,
  blackT25,
  lightBlue,
  lighterBlue,
  gradientGreen,
  red,
  orange,
  brightOrange,
  darkOrange,
  lightOrange,
  acidGreen,
};
