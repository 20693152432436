import logClientError, { ClientErrorType } from '@app/helpers/sentry';

export function logGraphqlClientError({
  errors,
  operationName,
}: {
  errors: string[];
  operationName: string;
}) {
  logClientError({
    name: `${operationName} Error`,
    message: `Apollo Client failed to execute ${operationName}`,
    extra: { queryErrors: errors },
    tags: [
      { name: 'error_type', value: ClientErrorType.GRAPHQL },
      {
        name: 'graphql_operation',
        value: operationName,
      },
    ],
  });
}
