import { css } from '@emotion/react';

import theme from '../../../styles/theme';
import breakpoints, {
  screenMedium,
  screenSmall,
} from '../../../styles/theme/breakpoints';
import colors from '../../../styles/theme/colors';
import fonts from '../../../styles/theme/fonts';
import { rem } from '../../../styles/theme/layout';

/*
 * TODO: replace all rem's with constants
 * */

export const menuBoxStyles = css`
  background-color: ${colors.topMenuColor};
  font-size: ${rem(16)};
`;

export const subMenuBoxStyles = css`
  background-color: ${colors.topSubMenuColor};

  @media (min-width: ${screenMedium}) {
    top: 0;
    z-index: 3;
  }
`;

export const menuItemStyles = css`
  @media (max-width: ${breakpoints[1]}) {
    width: 100%;
  }
`;

export const menuListStyles = css`
  margin: 0;
  padding: 0;
  text-align: center;
  li {
    list-style: none;
    display: inline-block;
    cursor: pointer;
  }
`;

export const mainMenuStyles = css`
  ${menuListStyles};

  @media (max-width: ${screenMedium}) {
    display: flex;
  }

  @media (max-width: ${screenSmall}) {
    flex-direction: column;
  }
`;

export const subMenuLevelStyles = css`
  &:nth-of-type(n + 2) {
    border-top: ${rem(1)} solid ${colors.subMenuDividerColor};
  }
`;

export const linkStyles = css`
  color: ${colors.whiteSecondary};
  text-decoration: none;
  cursor: pointer;
  &.active,
  &:hover {
    color: ${colors.lighterBlue};
  }

  @media (max-width: ${screenMedium}) {
    width: 100%;
  }
`;

export const menuLinkTitleStyles = css`
  font-weight: 500;
  display: block;

  @media screen and (max-width: ${screenSmall}) {
    margin-right: ${theme.space[3]};
  }
`;

export const menuLinkDescriptionStyles = css`
  font-family: ${fonts.fontNormal};
  font-size: ${rem(14)};
  display: block;
`;

export const menuLinkStyles = css`
  ${linkStyles}
  padding: ${rem(14)} ${rem(32)};
  line-height: 1.5;

  @media screen and (max-width: ${screenMedium}) {
    padding: ${theme.space[2]};
  }

  @media screen and (max-width: ${screenSmall}) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${theme.space[2]} 0;
  }
`;

export const subMenuLinkStyles = css`
  ${linkStyles}
  padding: ${rem(9)} ${rem(15)};
  font-family: ${fonts.fontNormal};
  font-size: ${rem(14)};

  @media screen and (max-width: ${screenSmall}) {
    padding: ${rem(5)};
  }
`;
