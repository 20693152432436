import { useParams } from 'react-router-dom';

import { getIntlValidLocale } from '@app/helpers/locale';
import { DEFAULT_LOCALE } from '@config/common.config';

type Translations =
  | Array<{
      languages_code?: { code: string | null } | null;
      // During NI migration this file will be deleted, so this is fine
      [key: string]: any;
    } | null>
  | null
  | undefined;

const useLocaleWithFallback = () => {
  const { language } = useParams<{
    language: string;
  }>();

  const getLanguagesWithFallback = () => [
    getIntlValidLocale(language),
    ...(language !== DEFAULT_LOCALE ? [DEFAULT_LOCALE] : []),
  ];

  const findByCode = (translations, code) =>
    translations?.find((item) => item?.languages_code?.code === code);

  const findTranslationByKeyWithFallback = ({
    translations,
    key,
  }: {
    translations: Translations;
    key: string;
  }): string =>
    findByCode(translations, getIntlValidLocale(language))?.[key] ||
    findByCode(translations, DEFAULT_LOCALE)?.[key];

  const findTranslationByKey = ({
    translations,
    key,
  }: {
    translations: Translations;
    key: string;
  }): string => findByCode(translations, getIntlValidLocale(language))?.[key];

  return {
    getLanguagesWithFallback,
    findTranslationByKey,
    findTranslationByKeyWithFallback,
  };
};

export default useLocaleWithFallback;
