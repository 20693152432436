import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import qs from 'query-string';
import { useQuery } from '@apollo/client/react/hooks';

import useLocationSearch from '@app/hooks/useLocationSearch';
import { parseRoute } from '@app/pages/ProductFinder/url';
import { DIRECTUS_SUPER_CLIENT_URL_KEY } from '@config/common.config';

import useDirectusQueryLanguages from '../useDirectusQueryLanguages';
import { useUserLicensesFilter } from '../useUserLicensesFilter';
import type { MenuQuery } from './__generated__/MenuQuery';
import { findCategoryPathByHistoryPaths, getActiveCategory } from './helpers';
import { MENU_QUERY } from './query';

const redirectTo = (path, match, history): void => {
  const newPath = generatePath(match.path, {
    language: match.params.language,
    url: path.endsWith('/') ? path : `${path}/`,
  });
  history.replace(decodeURIComponent(newPath));
};

const useMenu = (variant: string) => {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const { url, language = 'en' } = useParams<{
    url: string;
    language: string;
  }>();
  const directusSuperClientToken = useLocationSearch(
    DIRECTUS_SUPER_CLIENT_URL_KEY,
  );
  const {
    isFilterEnabled: isUserLicensesFilterEnabled,
    hideUserLicensesFilter,
  } = useUserLicensesFilter();

  const languages = useDirectusQueryLanguages();

  let menuSlugs: string[] = [''];

  const { loading, data } = useQuery<MenuQuery>(MENU_QUERY, {
    variables: {
      languages,
      variant,
    },
    context: { directusSuperClientToken },
  });

  if (loading) {
    return { loading, menuSlugs };
  }

  const { content, sortingValues } = data ?? {};

  const menuData = content?.menu?.[0]?.menu_categories;
  const menuCategories = menuData
    ?.map((menuCategory) => menuCategory?.category)
    .filter((option) => option);

  menuSlugs = parseRoute(url);

  let activeCategory = getActiveCategory(menuCategories, menuSlugs);

  if (!activeCategory) {
    // look for menu option in parent's slug
    menuSlugs = ['', ...menuSlugs];
    activeCategory = getActiveCategory(menuCategories, menuSlugs);
  }

  if (!activeCategory) {
    const path = findCategoryPathByHistoryPaths(url, menuCategories);
    if (path) {
      redirectTo(path.join('/'), match, history);
      return { loading: true, menuSlugs };
    }
  }

  if (!activeCategory) {
    redirectTo('', match, history); // redirect to root
    return { loading: true, menuSlugs };
  }

  const searchState = qs.parse(location.search);
  const {
    query: menuQuery,
    is_protected: isPageProtected,
    content_elements: contentElements,
  } = activeCategory;

  const productsCondition = isUserLicensesFilterEnabled
    ? {
        and: [...(menuQuery ? [menuQuery] : []), hideUserLicensesFilter],
      }
    : menuQuery;

  const contentElementIds = contentElements?.reduce<Array<string>>(
    (acc, item) => {
      if (item?.item && item.id) {
        return [...acc, item.id];
      }
      return acc;
    },
    [],
  );

  return {
    loading,
    menuSlugs,
    language,
    menu: menuCategories,
    activeCategory,
    isPageProtected,
    sortingValues,
    searchState,
    productsCondition,
    contentElementIds,
  };
};

export default useMenu;
