const productFinderBg = '#f5f5f5';
const productBorderColor = '#ddd';
const priceBoxHover = '#fbfbfb';
const categoryBannerContentBgLight = 'rgba(255, 255, 255, 0.86)';
const protectedContentMessage = '#888';
const personalisedLicenseBg = '#2e9975';
const productTileAcidGreen = '#2ECC71';
const protectedContentLinkHover = '#415d70';
const productTileBlueText = '#419FD9';
const categoryBannerContentBgDark = 'rgba(0, 0, 0, 0.7)';

export default {
  productFinderBg,
  productBorderColor,
  priceBoxHover,
  personalisedLicenseBg,
  protectedContentMessage,
  protectedContentLinkHover,
  categoryBannerContentBgLight,
  categoryBannerContentBgDark,
  productTileAcidGreen,
  productTileBlueText,
};
