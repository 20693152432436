/*
Theme Object
https://theme-ui.com/theme-spec/

| Custom Types
| layout         | Object - define layout values and utils
| typography     | Object - define all typography related values
*/

import breakpoints from './breakpoints';
import colors from './colors';
import fonts from './fonts';
import fontSizes from './fontSizes';
import grid from './grid';
import layout from './layout';
import radii from './radii';
import space from './space';
import zIndices from './zIndices';

const theme = {
  colors,
  breakpoints,
  zIndices,
  space,
  fonts,
  fontSizes,
  layout,
  radii,
  grid,
};

export type AppTheme = typeof theme;

export default theme;
