export const PRODUCT_FINDER_SEGMENT = 'catalog';
export const SUBSCRIPTION_CHECKOUT_SEGMENT = 'account/subscription/checkout';

const productFinder = {
  path: `/:language/${PRODUCT_FINDER_SEGMENT}/:url(.*)*`,
  name: 'product-finder',
};

export const productFinderRoutes = {
  productFinder,
};

export const SUBSCRIPTION_PATHS = {
  subscriptionList: '/:language/account/subscription/:returnType?',
  changePlan:
    '/:language/account/subscription/change-plan/:externalSubscriptionId?',
  subscriptionLanding: '/:language/products/subscription/360-subscription',
};

export const SUBSCRIPTION_CHECKOUT_PATHS = {
  account: `/:language/${SUBSCRIPTION_CHECKOUT_SEGMENT}/account/:itemPriceId?/:licensedProductId?`,
  address: `/:language/${SUBSCRIPTION_CHECKOUT_SEGMENT}/address/:subscriptionIntentId?`,
  payment: `/:language/${SUBSCRIPTION_CHECKOUT_SEGMENT}/payment/:subscriptionIntentId?`,
  review: `/:language/${SUBSCRIPTION_CHECKOUT_SEGMENT}/review/:subscriptionIntentId?`,
  success: `/:language/${SUBSCRIPTION_CHECKOUT_SEGMENT}/success/:subscriptionId?`,
};

export const DOWNLOADS_PATHS = {
  downloadsList: '/:language/account/downloads/',
  downloadDetail: '/:language/account/downloads/:productId',
};
