import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { hideUserLicenses } from '@app/redux/catalog';

export const USER_LICENSES_FILTER_STATE_KEY = 'nicom/shop/user-licenses-hidden';

export function useUserLicensesFilterStorage() {
  const userLicensesFilterState = getStoredUserLicensesFilterState();
  const [loading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hideUserLicenses(userLicensesFilterState));
    setIsLoading(false);
  }, [dispatch, userLicensesFilterState]);

  return {
    userLicensesFilterState,
    loading,
    storeUserLicensesFilterState,
    getStoredUserLicensesFilterState,
  };
}

const storeUserLicensesFilterState = (
  userLicensesFilterState: boolean,
): void => {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(
      USER_LICENSES_FILTER_STATE_KEY,
      JSON.stringify(userLicensesFilterState),
    );
  }
};

const getStoredUserLicensesFilterState = (): boolean =>
  typeof localStorage !== 'undefined'
    ? JSON.parse(
        localStorage.getItem(USER_LICENSES_FILTER_STATE_KEY) ?? 'false',
      )
    : false;
