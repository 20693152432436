import type { RouteComponentProps } from 'react-router-dom';
import type { Reducer } from 'redux';

import type { RoutesAction } from '../actions/routes';
import { SET_PAGE_TITLE, SYNC_ROUTE } from '../actions/routes';

type RequireOnly<T, K extends keyof T> = Partial<T> & Pick<T, K>;

export interface RoutesState {
  pageTitle: string | null;
  location: RequireOnly<RouteComponentProps['location'], 'pathname'>;
  match: RequireOnly<RouteComponentProps['match'], 'path' | 'params'>;
}

const initialState: RoutesState = {
  pageTitle: null,
  location: {
    pathname: '',
  },
  match: {
    path: '',
    params: {},
  },
};

const routesReducer: Reducer<RoutesState, RoutesAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case SYNC_ROUTE:
      return {
        ...state,
        match: action.match,
        location: action.location,
      };
    case SET_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.value,
      };
    default:
      return state;
  }
};

export default routesReducer;
