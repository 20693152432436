import { hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@emotion/react';

import { getLocaleFromPathname } from '@app/helpers/locale';
import {
  getLocalizationCountryCookie,
  type Language,
} from '@app/helpers/localization';

import { appendExternalScripts } from './assets/scripts';
import createApolloClient from './graphql/createApolloClient/client';
import rootReducer from './redux/reducers';
import theme from './styles/theme';
import App from './app';
import createStore from './redux';

declare global {
  interface Window {
    __SHOP_STORE_CODE__: string;
    Chargebee: any;
    Brightback: any;
  }
}

const init = () => {
  const history = createBrowserHistory();

  const country = getLocalizationCountryCookie();
  const language = getLocaleFromPathname(window.location.pathname) as Language;

  const { apolloClient } = createApolloClient({
    shopStoreCode: window?.__SHOP_STORE_CODE__ ?? '',
    language,
    country,
  });

  const store = createStore({ rootReducer });

  const app = (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <App />
          </Router>
        </ThemeProvider>
      </Provider>
    </ApolloProvider>
  );

  const rootElement = document.querySelector('.root-element');

  if (rootElement) {
    hydrateRoot(rootElement, app);
  }

  appendExternalScripts({ history });
};

init();
