import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import theme from '../../../styles/theme';
import { rem } from '../../../styles/theme/layout';
import type { CategoryBannerTextAlignment } from './CategoryBanner.constants';
import { CategoryBannerTheme } from './CategoryBanner.constants';

const breakpointDesktop = theme.breakpoints[0];
const breakpointLarge = theme.breakpoints[2];

export const containerStyles = ({
  backgroundMobile,
  backgroundDesktop,
}: {
  backgroundMobile: string;
  backgroundDesktop: string;
}): SerializedStyles => css`
  align-items: center;
  justify-content: center;
  min-height: ${rem(245)};
  padding: ${rem(35)};
  background-image: url(${backgroundMobile});
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (min-width: ${breakpointDesktop}) {
    background-image: url(${backgroundDesktop});
  }
`;

export const contentContainerStyles = ({
  bannerTheme,
  textAlignment,
}: {
  bannerTheme: CategoryBannerTheme;
  textAlignment: CategoryBannerTextAlignment;
}): SerializedStyles => css`
  max-width: ${rem(600)};
  padding: ${rem(25)};
  font-size: ${theme.fontSizes[3]};
  text-align: ${textAlignment};
  color: ${
    bannerTheme === CategoryBannerTheme.Dark
      ? theme.colors.white
      : theme.colors.black
  };
  background-color: ${
    bannerTheme === CategoryBannerTheme.Dark
      ? theme.colors.categoryBannerContentBgDark
      : theme.colors.categoryBannerContentBgLight
  };

  h1,
  h2,
  h3 {
    font-weight: 600;
    font-size: ${rem(32)};
  }

  p {
    margin: ${theme.space[2]} 0;
    line-height: 1.5;
  }

  @media screen and (min-width: ${breakpointLarge}) {
    p {
      margin: ${theme.space[3]} 0;
    }
  }
`;

export const countdownContainerStyles = css`
  font-weight: 500;
  margin-top: ${theme.space[3]};

  @media screen and (min-width: ${breakpointLarge}) {
    margin-top: ${theme.space[4]};
  }
`;
