import { Box } from '@theme-ui/components';

import {
  Bottom,
  BottomEl,
  ImagePlaceholder,
  TextPlaceholder,
  Wrapper,
} from './ProductPlaceholders.styles';

const ProductPlaceholder = () => (
  <Wrapper>
    <ImagePlaceholder mb={4} />
    <TextPlaceholder sx={{ width: '30%' }} pb="6%" mb={3} />
    <TextPlaceholder sx={{ width: '60%' }} pb="4%" mb={2} />
    <TextPlaceholder sx={{ width: '80%' }} pb="4%" mb={2} />
    <TextPlaceholder sx={{ width: '60%' }} pb="4%" mb={4} />
    <Bottom mt={4}>
      <BottomEl>
        <TextPlaceholder sx={{ width: '100%' }} pb="16%" />
      </BottomEl>
      <Box sx={{ width: '0.8%' }} />
      <BottomEl>
        <TextPlaceholder sx={{ width: '100%' }} pb="16%" />
      </BottomEl>
    </Bottom>
  </Wrapper>
);

export default ProductPlaceholder;
