import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '@__graphql__/types';

import type {
  CartUpdateResultFragment,
  StoreConfigFragment,
} from '../../pages/Cart/__graphql__/Cart.queries';
import {
  CartUpdateResultFragmentDoc,
  StoreConfigFragmentDoc,
} from '../../pages/Cart/__graphql__/Cart.queries';

const defaultOptions = {} as const;
export type SimpleCartQueryQueryVariables = Types.Exact<{
  cartId: Types.Scalars['String'];
}>;

export type SimpleCartQueryQuery = {
  magento2?: {
    storeConfig?: StoreConfigFragment | null;
    cart?:
      | ({
          appliedCoupons?: Array<{ code: string } | null> | null;
          shippingAddresses: Array<{
            __typename: 'Magento2_ShippingCartAddress';
            selectedShippingMethod?: {
              methodCode: string;
              amount: {
                value?: number | null;
                currency?: Types.Magento2_CurrencyEnum | null;
              };
              amountInclTax: {
                value?: number | null;
                currency?: Types.Magento2_CurrencyEnum | null;
              };
            } | null;
          } | null>;
        } & CartUpdateResultFragment)
      | null;
  } | null;
};

export const SimpleCartQueryDocument = gql`
  query SimpleCartQuery($cartId: String!) {
    magento2 {
      storeConfig {
        ...StoreConfig
      }
      cart(cart_id: $cartId) {
        ...CartUpdateResult
        appliedCoupons {
          code
        }
        shippingAddresses {
          selectedShippingMethod {
            methodCode
            amount {
              value
              currency
            }
            amountInclTax {
              value
              currency
            }
          }
          __typename
        }
      }
    }
  }
  ${StoreConfigFragmentDoc}
  ${CartUpdateResultFragmentDoc}
`;

/**
 * __useSimpleCartQueryQuery__
 *
 * To run a query within a React component, call `useSimpleCartQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleCartQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleCartQueryQuery({
 *   variables: {
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function useSimpleCartQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    SimpleCartQueryQuery,
    SimpleCartQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SimpleCartQueryQuery, SimpleCartQueryQueryVariables>(
    SimpleCartQueryDocument,
    options,
  );
}
export function useSimpleCartQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimpleCartQueryQuery,
    SimpleCartQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SimpleCartQueryQuery,
    SimpleCartQueryQueryVariables
  >(SimpleCartQueryDocument, options);
}
export type SimpleCartQueryQueryHookResult = ReturnType<
  typeof useSimpleCartQueryQuery
>;
export type SimpleCartQueryLazyQueryHookResult = ReturnType<
  typeof useSimpleCartQueryLazyQuery
>;
export type SimpleCartQueryQueryResult = Apollo.QueryResult<
  SimpleCartQueryQuery,
  SimpleCartQueryQueryVariables
>;
