import { gql } from '@apollo/client';

import { useAppSelector } from '@app/redux/hooks/useAppSelector';

import { useUserLicensesQuery } from './__graphql__/useUserLicensesFilter';

export const USER_LICENSES = gql`
  query UserLicenses {
    userV2 {
      __typename
      ... on AuthenticatedUser {
        licenses_ids
      }
    }
  }
`;

export const useUserLicensesFilter = () => {
  const { data, loading, error } = useUserLicensesQuery({
    fetchPolicy: 'cache-first',
    ssr: false,
  });

  const areUserLicensesHidden = useAppSelector(
    (state) => state.catalog.areUserLicensesHidden,
  );

  const userLicensesIds =
    data?.userV2?.__typename === 'AuthenticatedUser' &&
    data?.userV2?.licenses_ids;

  const hideUserLicensesFilter = {
    nin: {
      key: 'sdbs_id.value',
      value: userLicensesIds,
    },
  };

  return {
    data,
    loading,
    error,
    hideUserLicensesFilter,
    isFilterEnabled: areUserLicensesHidden && Boolean(userLicensesIds),
  };
};
