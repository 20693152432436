import { compose, createStore } from 'redux';

/* eslint-disable no-underscore-dangle */

const getPreloadedState = () => {
  if (typeof window !== 'undefined') {
    // Grab the state from a global variable injected into the
    // server-generated HTML
    const preloadedState = window.__PRELOADED_STATE__;

    // Allow the passed state to be garbage-collected
    delete window.__PRELOADED_STATE__;
    return preloadedState;
  }

  return {};
};

export default ({ rootReducer }) => {
  const store = createStore(
    rootReducer,
    getPreloadedState(),
    compose(
      // If you are using the devToolsExtension, you can add it here also
      typeof window !== 'undefined' &&
        typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f,
    ),
  );

  return store;
};
