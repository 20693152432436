import { Box, Flex } from '@theme-ui/components';
import styled from '@emotion/styled';

import { pulseAnimation } from '../../../styles/animations';
import theme from '../../../styles/theme';
import { rem } from '../../../styles/theme/layout';
import { ratioToPercentage } from '../../../styles/utils';
import { PRODUCT_TILE_RATIO } from '../ProductTile/ProductTile.styles';

export const Wrapper = styled(Box)`
  width: 100%;
  background-color: ${theme.colors.white};
  max-width: ${rem(460)};
  margin: 0 auto;
`;

export const Bottom = styled(Flex)`
  padding: 0.8% 0 0;
  background: ${theme.colors.productFinderBg};
  justify-content: space-between;
`;

export const BottomEl = styled(Flex)`
  height: 100%;
  background: white;
  width: 50;
  padding: 4% 8%;
  flex: 1;
`;

export const TextPlaceholder = styled(Box)`
  background: ${({ background }: { background?: string }) =>
    background || theme.colors.lightGray};
  margin-left: auto;
  margin-right: auto;
  ${pulseAnimation()};
`;

export const ImagePlaceholder = styled(TextPlaceholder)`
  padding-bottom: ${ratioToPercentage(PRODUCT_TILE_RATIO)};
`;
