// pixel base size
const pxSize = 18;

// convert px to em
export function em(px: number, size: number = pxSize): string {
  return `${px / size}em`;
}

// convert px to rem
export function rem(px: number, size: number = pxSize): string {
  return `${px / size}rem`;
}

// container max width
const containerMaxWidth = rem(1420);

// container large width
const containerLargeWidth = rem(900);

const headerHeight = rem(60);

export default {
  pxSize,
  containerMaxWidth,
  containerLargeWidth,
  headerHeight,
};
