import type { SerializedStyles } from '@emotion/react';
import { css, keyframes } from '@emotion/react';

export const spinning: SerializedStyles = keyframes`100% {transform: rotate(360deg)}`;

export const fadeIn: SerializedStyles = keyframes`
  from { opacity: 0} to { opacity: 1}
`;

export const fadeOut: SerializedStyles = keyframes`
  from { opacity: 1} to { opacity: 0}
`;

export const pulse: SerializedStyles = keyframes`
  from {opacity: 0.4} to {opacity: 1}
`;

export const boxIn = keyframes`
   {
    from {
      opacity: 0;
      transform: translateY(10%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const rotate: SerializedStyles = keyframes`
  from { transform: rotate(0deg)} to { transform: rotate(360deg)}
`;

export const scaleIn: SerializedStyles = keyframes`
  from { transform: scale(0) } to { transform: scale(1) }
`;

export const pulseAnimation = (duration = '0.8s'): SerializedStyles => css`
  animation: ${pulse} ${duration} infinite alternate;
`;
