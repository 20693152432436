import { Flex, Text } from '@theme-ui/components';
import styled from '@emotion/styled';

import theme from '../../../styles/theme';
import { rem } from '../../../styles/theme/layout';

export const Wrapper = styled(Flex)`
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: ${theme.space[2]};
  min-height: ${rem(70)};
  &:hover {
    background-color: ${theme.colors.priceBoxHover};
  }
`;

export const Price = styled(Text)`
  display: block;
  font-weight: 500;
  font-size: ${rem(14)};
`;

export const PriceDiff = styled.span`
  text-decoration: line-through;
  font-size: ${rem(12)};
  padding-left: ${theme.space[1]};
`;

export default { Wrapper, Price, PriceDiff };
