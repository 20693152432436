/*
Breakpoints
Array<string> - representing viewport widths to use for min-width media queries.
*/

import { em } from './layout';

export const mobile = em(375);
export const bigMobile = em(540);
export const tabletPortrait = em(768);
export const tabletLandscape = em(1024);
export const laptop = em(1280);
export const desktop = em(1440);
export const largeScreen = em(1920);

/*
 Keeping the old breakpoints for legacy compatibility.
 TODO: remove old breakpoints when grid/new breakpoints system is implemented in all the pages.
 */
export const screenExtraSmall = '30em';
export const screenSmall = '48em';
export const screenMedium = '60em';
export const screenMediumLarge = '70em';
export const screenLarge = '75em';

const oldBreakpoints: string[] = [
  screenSmall,
  screenMedium,
  screenMediumLarge,
  screenLarge,
];

const breakpoints: string[] = [
  mobile,
  tabletPortrait,
  tabletLandscape,
  laptop,
  desktop,
  largeScreen,
];

export const mediaQueries: string[] = breakpoints.map(
  (minWidth) => `@media (min-width: ${minWidth})`,
);

export default oldBreakpoints;
