import { createContext } from 'react';
import { Box, Flex } from '@theme-ui/components';
import debounce from 'lodash.debounce';
import { css } from '@emotion/react';

import useMediaQuery from '@app/helpers/useMediaQuery';
import type { UseMenuHookOut } from '@app/hooks/useMenu/types';
import { useUserLicensesFilterStorage } from '@app/hooks/useUserLicensesFilterStorage';
import { tabletLandscape } from '@app/styles/theme/breakpoints';

import CustomerOffers from './ProductList/CustomerOffers';
import UserLicensesToggle from './UserLicensesToggle/UserLicensesToggle';
import CatalogContentPage from './CatalogContentPage';
import CatalogMeta from './CatalogMeta';
import ContentSection from './ContentSection';
import styles from './ProductFinder.styles';
import ProductList from './ProductList';
import ProductSearch from './ProductSearch';
import ProductSort from './ProductSort';
import TopMenu from './TopMenu';
import type { FinderState } from './url';

interface ProductFinderProps extends Omit<UseMenuHookOut, 'loading'> {
  onSearch: (newState: FinderState) => void;
}

const defaultSearchState: FinderState = {};

export const SearchContext = createContext(defaultSearchState);
SearchContext.displayName = 'SearchContext';

const SEARCH_DEBOUNCE_TIME = 300;

const debounced = debounce(
  (fn, ...params) => fn(...params),
  SEARCH_DEBOUNCE_TIME,
);

const ProductFinder = ({
  searchState,
  menu,
  menuSlugs,
  isPageProtected,
  sortingValues,
  productsCondition,
  contentElementIds,
  language,
  activeCategory,
  onSearch,
}: ProductFinderProps) => {
  const debouncedOnSearch = (...args: unknown[]) =>
    debounced(onSearch, ...args);

  const { loading: isFilterStateLoading } = useUserLicensesFilterStorage();
  const isDesktop = useMediaQuery(`(min-width: ${tabletLandscape})`, {
    ssrMatch: true,
  });

  const isSearching = typeof searchState.searching !== 'undefined';
  const contentPageId = activeCategory?.content_page?.id;
  const isContentPage = !isSearching && Boolean(contentPageId);
  const isMyKompleteOffersPage = activeCategory?.slug === 'my-komplete-offers';

  return (
    <SearchContext.Provider value={searchState}>
      <CatalogMeta menu={menu} menuSlugs={menuSlugs} />
      <TopMenu config={menu} value={menuSlugs} language={language} />
      <div
        css={css`
          background: white;
        `}
      >
        <Box css={styles.wrapper}>
          <Flex css={styles.header} sx={{ justifyContent: 'flex-end' }}>
            <Box sx={{ flex: 1 }}>
              <ProductSearch
                searchState={searchState}
                onSearch={debouncedOnSearch}
              />
            </Box>

            {!isContentPage &&
              !isMyKompleteOffersPage &&
              isDesktop &&
              !isFilterStateLoading && <UserLicensesToggle />}

            <Box css={styles.productSort}>
              {sortingValues && !isContentPage ? (
                <ProductSort
                  onChange={onSearch}
                  sortingValues={sortingValues}
                  searchState={searchState}
                />
              ) : null}
            </Box>
          </Flex>
        </Box>
      </div>
      {isContentPage && contentPageId && (
        <CatalogContentPage id={contentPageId} />
      )}

      {(isSearching || !isContentPage) && (
        <Box p={2}>
          <Box css={styles.wrapper}>
            <Box css={styles.contentWrapper}>
              <Box>
                {isPageProtected ? (
                  <CustomerOffers
                    condition={productsCondition}
                    language={language}
                    searchState={searchState}
                  />
                ) : (
                  <ProductList
                    condition={productsCondition}
                    language={language}
                    searchState={searchState}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {contentElementIds.length ? (
        <ContentSection contentElementIds={contentElementIds} />
      ) : null}
    </SearchContext.Provider>
  );
};

export default ProductFinder;
