import type { IntlShape } from 'react-intl';
import { FormattedMessage } from 'react-intl';

import Dropdown from '../../../components/Dropdown';
import type { FinderState } from '../url';
import { DEFAULT_SORTING_VALUE } from '../url';
import messages from './ProductSort.messages';

interface InputProps {
  sortingValues;
  searchState: FinderState;
  onChange: (newState: FinderState) => void;
  intl: IntlShape;
}

const ProductSort = ({
  sortingValues,
  searchState,
  onChange,
  intl,
}: InputProps) => {
  return (
    <Dropdown
      className="product-finder-sorting-dropdown"
      menuClassName="product-finder-sorting-dropdown-menu"
      placeholder={intl.formatMessage(messages.placeholder)}
      options={[
        {
          label: <FormattedMessage {...messages.relevance} />,
          value: DEFAULT_SORTING_VALUE,
          className: 'product-finder-sorting-dropdown-option-relevance',
        },
        ...sortingValues.map((val) => ({
          label: <FormattedMessage {...messages[val]} />,
          value: val,
          className: `product-finder-sorting-dropdown-option-${val}`,
        })),
      ]}
      value={
        // The default sorting should not be set to URL
        typeof searchState.sorting === 'undefined'
          ? DEFAULT_SORTING_VALUE
          : searchState.sorting
      }
      onChange={(sorting) => onChange({ ...searchState, sorting })}
    />
  );
};

export default ProductSort;
