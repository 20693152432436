import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import theme from '../../styles/theme';
import { rem } from '../../styles/theme/layout';

const wrapper = css`
  background: ${theme.colors.footerBg};
  color: ${theme.colors.footerText};
  padding: ${rem(12)};
  line-height: 1.5;
  font-size: ${rem(16)};
`;

const linkClass: SerializedStyles = css`
  color: ${theme.colors.footerText};
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: ${theme.colors.white};
  }

  &:not(:last-child)::after {
    content: '|';
    padding-left: ${theme.space[1]};
    padding-right: ${theme.space[1]};
  }
`;

export default {
  wrapper,
  linkClass,
};
