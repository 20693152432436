import type { FC } from 'react';
import { Box } from '@theme-ui/components';
import { useQuery } from '@apollo/client';

import useDirectusQueryLanguages from '@app/hooks/useDirectusQueryLanguages';
import useDirectusPreviewToken from '@app/hooks/useDirectusSuperClientToken';

import ContentElement from '../ContentElement';
import ProductPlaceholders from '../ProductPlaceholders';
import type {
  ContentElementsQuery_content_menu_categories_content_elements as ContentElementItem,
  ContentElementsQuery as QueryType,
  ContentElementsQueryVariables as QueryVariables,
} from './__generated__/ContentElementsQuery';
import ContentSectionQuery from './query';

export interface ContentSectionProps {
  contentElementIds: Array<string>;
}

const sortElements = (
  a: ContentElementItem | null,
  b: ContentElementItem | null,
) => {
  if (
    typeof a?.sort === 'undefined' ||
    typeof b?.sort === 'undefined' ||
    a?.sort === null ||
    b.sort === null
  ) {
    return 0;
  }
  return a?.sort > b.sort ? 1 : -1;
};

const ContentSection: FC<ContentSectionProps> = ({ contentElementIds }) => {
  const directusSuperClientToken = useDirectusPreviewToken();
  const languages = useDirectusQueryLanguages();

  const { data, loading } = useQuery<QueryType, QueryVariables>(
    ContentSectionQuery,
    {
      variables: { contentElementIds, languages },
      context: { directusSuperClientToken },
    },
  );

  if (loading) {
    return <ProductPlaceholders count={4} />;
  }

  const elements = data?.content?.menu_categories_content_elements || [];

  if (!elements?.length) {
    return null;
  }

  return (
    <>
      {elements
        .slice()
        .sort(sortElements)
        .map((element, index) =>
          element?.item ? (
            <Box key={index}>
              <ContentElement data={element.item} />
            </Box>
          ) : null,
        )}
    </>
  );
};

const Wrapper: FC<ContentSectionProps> = (props) => {
  if (!props.contentElementIds.length) {
    return null;
  }
  return <ContentSection {...props} />;
};

export default Wrapper;
