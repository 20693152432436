import { defineMessages } from 'react-intl';

export default defineMessages({
  overviewTitle: {
    id: 'components.submenu.links.overviewTitle',
    defaultMessage: 'Overview',
  },
  overviewSub: {
    id: 'components.submenu.links.overviewSub',
    defaultMessage: 'All products',
  },
  softwareSoundTitle: {
    id: 'components.submenu.links.softwareSoundTitle',
    defaultMessage: 'Software & Sounds',
  },
  softwareSoundSub: {
    id: 'components.submenu.links.softwareSoundSub',
    defaultMessage: 'Komplete instruments & effects',
  },
  hardwareTitle: {
    id: 'components.submenu.links.hardwareTitle',
    defaultMessage: 'Hardware',
  },
  hardwareSub: {
    id: 'components.submenu.links.hardwareSub',
    defaultMessage: 'Keyboards, Maschine, and more',
  },
  djingTitle: {
    id: 'components.submenu.links.djingTitle',
    defaultMessage: 'Digital DJing',
  },
  djingSub: {
    id: 'components.submenu.links.djingSub',
    defaultMessage: 'Traktor',
  },
});
