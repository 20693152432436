import type { FC, HTMLProps } from 'react';
import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Loader from '@app/components/Loader';
import type { AppTheme } from '@app/styles/theme';
import staticTheme from '@app/styles/theme';
import fontSizes from '@app/styles/theme/fontSizes';
import { rem } from '@app/styles/theme/layout';

type ButtonType = 'transparent' | 'primary' | 'secondary' | 'white';

export interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  type: ButtonType;
  wide?: boolean;
  fullWidth?: boolean;
  isLoading?: boolean;
  className?: string;
}

const getStylesByType = (type: ButtonType) =>
  ({
    primary: css`
      background: ${staticTheme.colors.lightBlue};
      color: #fff;
      &:hover:enabled {
        opacity: 0.8;
      }
    `,
    secondary: css`
      background: ${staticTheme.colors.darkGray};
      color: #fff;
      &:hover:enabled {
        opacity: 0.8;
      }
    `,
    transparent: css`
      background: transparent;
      color: ${staticTheme.colors.lightBlue};
      font-weight: 400;
    `,
    white: css`
      background: ${staticTheme.colors.white};
      color: ${staticTheme.colors.black};
      border: ${rem(1)} solid ${staticTheme.colors.black};
    `,
  })[type];

const UnstyledButton = styled.button`
  border-radius: ${({ theme }) => theme.radii.normal};
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
`;

export const getButtonStyles = (
  props: Pick<ButtonProps, 'type' | 'wide' | 'fullWidth'> & { theme: AppTheme },
): SerializedStyles => css`
  display: inline-block;
  text-align: center;
  padding: ${props.wide ? `${rem(8)} ${rem(50)}` : `${rem(8)} ${rem(14)}`};
  line-height: 1.5;
  border: 0;
  color: ${props.theme.colors.darkGray};
  transition: all 0.4s ease;
  position: relative;
  cursor: pointer;
  font-weight: 500;
  width: ${props.fullWidth && '100%'};
  font-size: ${fontSizes[2]};
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  ${getStylesByType(props.type)}
`;

const Button: FC<ButtonProps> = ({
  children,
  isLoading,
  wide,
  type,
  fullWidth,
  ...props
}) => (
  <button
    type="button"
    disabled={isLoading}
    css={(theme: AppTheme) => getButtonStyles({ type, wide, theme, fullWidth })}
    {...props}
  >
    {isLoading && (
      <Loader
        css={css`
          margin-right: ${rem(10)};
        `}
        size={13}
        color="white"
      />
    )}
    {children}
  </button>
);

export { UnstyledButton, Button };
