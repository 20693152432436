import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import { fadeIn } from '../../../styles/animations';
import theme from '../../../styles/theme';

const fullBlockSize: SerializedStyles = css`
  width: 100%;
  height: 100%;
`;

const card: SerializedStyles = css`
  ${fullBlockSize}
  animation: ${fadeIn} 1s;
  background-color: ${theme.colors.productBorderColor};
`;

export default {
  fullBlockSize,
  card,
};
