export const getOptimizedImage = ({
  image,
  width,
  format = 'pjpg',
}: {
  image: string;
  width: string;
  format?: string;
}): string => {
  return `${image}?format=${format}&width=${width}&auto=webp`;
};
