import { useEffect } from 'react';
import { Box } from '@theme-ui/components';
import cookie from 'js-cookie';

import cartSvg from '@app/assets/images/cart.svg';
import Link from '@app/components/Link';
import useSimpleCart from '@app/hooks/useSimpleCart';

import styles from './CartButton.styles';

export const BASKET_COUNTER_COOKIE = 'basketcounter';

const CartButton = () => {
  const { itemsCount } = useSimpleCart();

  useEffect(() => {
    cookie.set(BASKET_COUNTER_COOKIE, itemsCount?.toString() ?? '0');
  }, [itemsCount]);

  const showCounter = Boolean(itemsCount);

  return (
    <Link css={styles.wrapper} to="/:language/shop/cart/">
      <img src={cartSvg} alt="checkout" css={{ maxWidth: 'unset' }} />
      {showCounter && (
        <Box data-testid="basket-counter" css={styles.bubble}>
          {itemsCount}
        </Box>
      )}
    </Link>
  );
};

export default CartButton;
