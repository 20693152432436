import { useLocation } from 'react-router-dom';

import getLoginUrl from '@app/routes/getLoginUrl';
import { APP_BASE_URL } from '@config/common.config';

export const useLoginUrl = () => {
  const { pathname } = useLocation();

  return getLoginUrl(APP_BASE_URL, pathname);
};
