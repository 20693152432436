import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Flex, Image } from '@theme-ui/components';
import { css } from '@emotion/react';

import { getTranslationWithFallback } from '@app/helpers/translations';

import type {
  ContentElement2Col_translations as ContentElement2ColTranslations,
  ContentElement2Col as TContentElement2Col,
} from '../ContentSection/__generated__/ContentElement2Col';
import {
  flexContainerStyles,
  IMAGE_STYLE_FIXED,
  IMAGE_STYLE_FULL_WIDTH,
  ImageWrapper,
  layoutWidth,
} from './ContentElement2Col.style';

const ContentElement2Col: FC<{ data: TContentElement2Col }> = ({
  data: {
    translations,
    image,
    image_style: imageStyle,
    text_position: textPosition,
    background,
  },
}) => {
  const { language } = useParams<{ language: string }>();

  const content = getTranslationWithFallback<
    ContentElement2ColTranslations['content']
  >({
    translations: translations || [],
    language,
    key: 'content',
  });

  const imageSrc = image?.full_url;
  const isImageFixed = image && imageStyle === IMAGE_STYLE_FIXED;
  const isImageFullWidth = image && imageStyle === IMAGE_STYLE_FULL_WIDTH;

  return (
    <Box
      data-testid="seamless-element-2col"
      py={isImageFixed ? 4 : 0}
      css={css`
        position: relative;
        line-height: 0;
        background-color: ${background};
      `}
    >
      <Flex
        css={flexContainerStyles({
          imageStyle,
          textPosition,
        })}
        p={[4, 3]}
        sx={{ width: layoutWidth }}
      >
        {content && (
          <Box
            px={[0, 4]}
            sx={{ width: ['100%', '50%'] }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
        {isImageFixed && imageSrc && (
          <ImageWrapper sx={{ width: ['100%', '50%'] }}>
            <Image src={imageSrc} width="100%" />
          </ImageWrapper>
        )}
      </Flex>
      {isImageFullWidth && imageSrc && <Image src={imageSrc} />}
    </Box>
  );
};

export default ContentElement2Col;
