import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import theme from '../../../styles/theme';
import { rem } from '../../../styles/theme/layout';

const wrapper: SerializedStyles = css`
  font-size: ${theme.fontSizes[4]};
  font-weight: 500;
  color: ${theme.colors.protectedContentMessage};
  padding-top: ${rem(32)};
`;

export default {
  wrapper,
};
