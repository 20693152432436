import { gql } from '@apollo/client';

export const MENU_QUERY = gql`
  query MenuQuery($variant: String, $languages: [String]) {
    content {
      menu(filter: { variant: { _eq: $variant } }) {
        id
        variant
        menu_categories {
          ...MenuCategories
        }
      }
    }
    sortingValues
  }

  fragment MenuCategories on menu_menu_categories {
    id
    category {
      ...MenuCategory
      children {
        id
        category {
          ...MenuCategory
          children {
            id
            category {
              ...MenuCategory
              children {
                id
              }
            }
          }
        }
      }
    }
  }

  fragment MenuCategory on menu_categories {
    id
    name
    slug
    history_paths
    query
    content_page {
      id
    }
    category_banner {
      id
    }
    content_elements {
      id
      item {
        ... on content_elements_1col {
          id
        }
        ... on content_elements_2_col {
          id
        }
        ... on content_elements_2col_grid {
          id
        }
      }
    }
    is_protected
    meta_image_alt
    meta_image {
      full_url
    }
    translations(filter: { languages_code: { code: { _in: $languages } } }) {
      ...MenuTranslation
    }
  }

  fragment MenuTranslation on menu_categories_translations {
    id
    title
    subtitle
    meta_title
    meta_description
    languages_code {
      code
    }
  }
`;
