import { Box, Flex } from '@theme-ui/components';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Support from '@app/components/Support/Support';
import { H1 } from '@app/components/typography';
import { rem } from '@app/styles/theme/layout';

import errorIcon from './assets/error-icon.svg';

const GenericError = ({
  showSupport = true,
  children,
}: {
  showSupport?: boolean;
  children?: React.ReactNode;
}) => {
  return (
    <Flex
      sx={{ flexDirection: 'column' }}
      css={css`
        width: 100%;
        max-width: ${rem(650)};
        margin: auto;
      `}
    >
      <Box>
        <img src={errorIcon} alt="" />
      </Box>
      {children}
      {showSupport && (
        <Box my={75}>
          <Support />
        </Box>
      )}
    </Flex>
  );
};

export default GenericError;

export const GenericErrorTitle = styled(H1)`
  margin-top: ${rem(10)};
`;

export const GenericErrorMessage = styled.p`
  margin: ${rem(15)} 0 ${rem(40)};
  line-height: 1.5;
`;
