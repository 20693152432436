import { defineMessages } from 'react-intl';

export default defineMessages({
  allProducts: {
    id: 'components.BundlePrice.allProducts',
    defaultMessage: 'All Products',
  },
  bundlePrice: {
    id: 'components.BundlePrice.bundlePrice',
    defaultMessage: 'Bundle Price',
  },
  youSave: {
    id: 'components.BundlePrice.youSave',
    defaultMessage: 'You save',
  },
});
