import { useLoginUrl } from '@app/hooks/useLoginUrl';

interface LoginLinkProps {
  className?: string;
  children: React.ReactNode;
}

const LoginLink = ({ className, children }: LoginLinkProps) => {
  const loginUrl = useLoginUrl();

  return (
    <a data-testid="login-link" className={className} href={loginUrl}>
      {children}
    </a>
  );
};

export default LoginLink;
