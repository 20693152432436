export enum CategoryBannerTheme {
  Light = 'light',
  Dark = 'dark',
}

export enum CategoryBannerTextAlignment {
  Center = 'center',
  Left = 'left',
  Right = 'right',
}
