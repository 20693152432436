import { Box, Image, Text } from '@theme-ui/components';

import CrashIcon from '../../../assets/images/crash_face.svg';
import styles from './ProductTileError.styles';

const ProductTileError = () => {
  return (
    <Box p={3} css={styles.wrapper}>
      <Image mb={4} alt="" src={CrashIcon} />
      <Text sx={{ display: 'block', fontSize: 3 }} mt={4} px={4}>
        Oh no!
        <br />
        An error occured while loading this product.
      </Text>
    </Box>
  );
};

export default ProductTileError;
