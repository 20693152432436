import { gql } from '@apollo/client';

export default gql`
  query getMedia($id: Int) {
    media(id: $id) {
      itemId
      mediaType
      title
      description
      thumb
      image
      displayTime
      length
      chapters {
        ... on MP3Chapter {
          uid
          title
          thumb
          length
          mediaType
          mediaHigh
          mediaLow
          displayTime
          description
          waveform
        }
        ... on YouTubeChapter {
          videoId
        }
      }
    }
  }
`;
