const headerNavLink = '#303030';
const headerShadowBottom = 'rgba(0, 0, 0, 0.1)';

const subMenuLinkTitle = '#161819';
const subMenuLinkSubTitle = '#495057';

export default {
  headerNavLink,
  headerShadowBottom,
  subMenuLinkTitle,
  subMenuLinkSubTitle,
};
