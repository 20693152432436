import type { RouterHistory } from '@sentry/react/types/reactrouter';
import Cookies from 'js-cookie';

import { TAG_MANAGER_PROFILE } from '@config/client.config';
import { CONSENT_COOKIE } from '@config/common.config';

import {
  loadScript,
  parseConsentGroups,
  toExternalScript,
} from '../helpers/scripts';
import { initSentry } from '../helpers/sentry';

type DataEvent = Record<string, unknown>;

declare global {
  interface Window {
    dataLayer: DataEvent[];
  }
}

type Manifest = {
  files: Record<string, string>;
  entrypoints: {
    main: {
      assets: {
        js: Array<string>;
      };
    };
    vendor: {
      assets: {
        js: Array<string>;
      };
    };
  };
};

export function getAppAssets(manifest: Manifest): string {
  if (!manifest) {
    throw Error('Cannot obtain application assets. App manifest not found.');
  }

  return [
    ...manifest.entrypoints.vendor.assets.js,
    ...manifest.entrypoints.main.assets.js,
  ]
    .map(toExternalScript)
    .join('');
}

export function appendExternalScripts({
  history,
}: {
  history: RouterHistory;
}): void {
  let hasAnalyticsScripts = false;

  function loadGTM(): void {
    loadScript({
      src: `//www.googletagmanager.com/gtm.js?id=${TAG_MANAGER_PROFILE}`,
      async: true,
    });
    window.dataLayer = [];
    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
  }

  loadGTM();
  function appendAnalyticsScripts(): void {
    const consentCookie = Cookies.get(CONSENT_COOKIE);
    const consentGroups = parseConsentGroups(consentCookie);
    const ANALYTICS_CONSENT_GROUP = 'C0003';

    if (consentGroups[ANALYTICS_CONSENT_GROUP] && !hasAnalyticsScripts) {
      initSentry({ history });
      hasAnalyticsScripts = true;
    }
  }

  appendAnalyticsScripts();
  window.addEventListener('cookieConsent.set', appendAnalyticsScripts);
  window.dispatchEvent(new Event('cookieConsent.set'));
}

export function getTagManagerIframe(): string {
  return `<noscript>
    <iframe src="//www.googletagmanager.com/ns.html?id=${TAG_MANAGER_PROFILE}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
  </noscript>`;
}

export function getCookieConsent(manifest: { [key: string]: string }): string {
  return toExternalScript(manifest['cookie-consent.js']);
}
