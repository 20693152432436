import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Box, Button, Image } from '@theme-ui/components';
import { useTheme } from '@emotion/react';

import Tooltip, { TooltipPosition } from '@app/components/Tooltip';
import { pushData } from '@app/helpers/tracking';
import useAuthentication from '@app/hooks/useAuthentication';
import { useLoginUrl } from '@app/hooks/useLoginUrl';
import { useUserLicensesFilterStorage } from '@app/hooks/useUserLicensesFilterStorage';
import { hideUserLicenses } from '@app/redux/catalog';
import { useAppSelector } from '@app/redux/hooks/useAppSelector';
import { rem } from '@app/styles/theme/layout';

import hideIcon from './assets/hide-icon.svg';
import showIcon from './assets/show-icon.svg';
import messages from './UserLicensesToggle.messages';

const UserLicensesToggle = () => {
  const areUserLicensesHidden = useAppSelector(
    (state) => state.catalog.areUserLicensesHidden,
  );
  const theme = useTheme();

  const dispatch = useDispatch();
  const { loading: isAuthenticatedLoading, isAuthenticated } =
    useAuthentication();
  const [isHovering, setIsHovering] = useState(false);
  const { storeUserLicensesFilterState } = useUserLicensesFilterStorage();

  const loginUrl = useLoginUrl();
  const intl = useIntl();

  const handleClick = () => {
    if (isAuthenticated) {
      dispatch(hideUserLicenses(!areUserLicensesHidden));
      storeUserLicensesFilterState(!areUserLicensesHidden);
    } else {
      window.location.href = loginUrl;
    }
  };

  useEffect(() => {
    pushData({
      action: areUserLicensesHidden ? 'hide' : 'show',
      category: 'owned-products-filter',
      label: '',
      value: '',
      nonInteractive: 'no',
    });
  }, [areUserLicensesHidden]);

  if (isAuthenticatedLoading) return null;

  return (
    <Button
      data-testid="user-licenses-toggle"
      onClick={handleClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      sx={{
        fontSize: rem(14),
        position: 'absolute',
        top: '0',
        left: '60%',
        height: '100%',
        transform: 'translate(-50%, 0)',
        alignItems: 'center',
        border: 0,
        margin: 0,
        padding: 0,
        background: 'transparent',
        color: theme.colors.black,
        cursor: 'pointer',
        width: rem(450),
        display: 'flex',
      }}
    >
      <Image
        sx={{
          width: rem(16),
          height: rem(16),
          position: 'relative',
          top: rem(0.5),
        }}
        src={areUserLicensesHidden ? showIcon : hideIcon}
        alt=""
      />

      <Box
        sx={{
          padding: `0 ${rem(20)} 0 ${rem(8)}`,
          whiteSpace: 'nowrap',
        }}
      >
        <FormattedMessage
          {...messages[
            areUserLicensesHidden ? 'showUserLicenses' : 'hideUserLicenses'
          ]}
        />
      </Box>

      {!isAuthenticated && isHovering && (
        <Tooltip
          content={intl.formatMessage(messages.login)}
          position={TooltipPosition.RIGHT}
          data-testid="user-licenses-toggle-login-tooltip"
        />
      )}
    </Button>
  );
};

export default UserLicensesToggle;
