const HIDE_USER_LICENSES = 'HIDE_USER_LICENSES';

interface CatalogState {
  areUserLicensesHidden: boolean;
}

const initialState = {
  areUserLicensesHidden: false,
};

interface HideUserLicensesAction {
  type: typeof HIDE_USER_LICENSES;
  value: boolean;
}

const hideUserLicenses = (
  hideUserLicenses: boolean,
): HideUserLicensesAction => ({
  type: HIDE_USER_LICENSES,
  value: hideUserLicenses,
});

const reducer = (
  state: CatalogState = initialState,
  action: HideUserLicensesAction,
): CatalogState => {
  switch (action.type) {
    case HIDE_USER_LICENSES:
      return {
        ...state,
        areUserLicensesHidden: action.value,
      };
    default:
      return state;
  }
};

export { hideUserLicenses, initialState, reducer };
