import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '@__graphql__/types';

import type { CartUpdateResultFragment } from '../../pages/Cart/__graphql__/Cart.queries';
import { CartUpdateResultFragmentDoc } from '../../pages/Cart/__graphql__/Cart.queries';

const defaultOptions = {} as const;
export type AddProductsToCartMutationVariables = Types.Exact<{
  cartId: Types.Scalars['String'];
  cartItems: Array<Types.Magento2_CartItemInput> | Types.Magento2_CartItemInput;
}>;

export type AddProductsToCartMutation = {
  magento2?: {
    addProductsToCart?: {
      cart: CartUpdateResultFragment;
      userErrors: Array<{
        code: Types.Magento2_CartUserInputErrorType;
        message: string;
      } | null>;
    } | null;
  } | null;
};

export const AddProductsToCartMutationDocument = gql`
  mutation AddProductsToCartMutation(
    $cartId: String!
    $cartItems: [Magento2_CartItemInput!]!
  ) {
    magento2 {
      addProductsToCart(cartId: $cartId, cartItems: $cartItems) {
        cart {
          ...CartUpdateResult
        }
        userErrors {
          code
          message
        }
      }
    }
  }
  ${CartUpdateResultFragmentDoc}
`;
export type AddProductsToCartMutationFn = Apollo.MutationFunction<
  AddProductsToCartMutation,
  AddProductsToCartMutationVariables
>;

/**
 * __useAddProductsToCartMutation__
 *
 * To run a mutation, you first call `useAddProductsToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductsToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductsToCartMutationMutation, { data, loading, error }] = useAddProductsToCartMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *      cartItems: // value for 'cartItems'
 *   },
 * });
 */
export function useAddProductsToCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProductsToCartMutation,
    AddProductsToCartMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddProductsToCartMutation,
    AddProductsToCartMutationVariables
  >(AddProductsToCartMutationDocument, options);
}
export type AddProductsToCartMutationHookResult = ReturnType<
  typeof useAddProductsToCartMutation
>;
export type AddProductsToCartMutationResult =
  Apollo.MutationResult<AddProductsToCartMutation>;
export type AddProductsToCartMutationOptions =
  Apollo.BaseMutationOptions<
    AddProductsToCartMutation,
    AddProductsToCartMutationVariables
  >;
