import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import theme from '../../../styles/theme';

const wrapper: SerializedStyles = css`
  background-color: ${theme.colors.productFinderBg};
  min-height: 100vh;
`;

export default {
  wrapper,
};
