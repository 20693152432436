import type { getMedia_media } from '../../pages/ProductFinder/__generated__/getMedia';
import {
  CLOSE_AUDIO_PLAYER,
  LOAD_AUDIO,
  NEXT_AUDIO,
  OPEN_AUDIO_PLAYER,
  PAUSE_AUDIO_PLAYER,
  PLAY_AUDIO_PLAYER,
  PREV_AUDIO,
  RESET_AUDIO_PLAYER,
  SET_DATA,
  SET_ID,
} from '../actions/audioPlayer';

export interface AudioPlayerState {
  isOpen: boolean;
  isPlaying: boolean;
  isPause: boolean;
  isAudioLoading: boolean;
  data: getMedia_media | null;
  currentTrack: number;
  id: number | null;
}

export const initialState: AudioPlayerState = {
  isPlaying: false,
  isPause: false,
  isOpen: false,
  isAudioLoading: false,
  data: null,
  currentTrack: 0,
  id: null,
};

// Selectors
export const getPlayList = (state: AudioPlayerState) => {
  return (state.data && state.data.chapters) || [];
};

const audioPlayerReducer = (state = initialState, action): AudioPlayerState => {
  switch (action.type) {
    case OPEN_AUDIO_PLAYER:
      return {
        ...state,
        isOpen: true,
      };
    case CLOSE_AUDIO_PLAYER:
      return {
        ...state,
        isOpen: false,
        isPlaying: false,
        isAudioLoading: false,
        isPause: false,
      };
    case PLAY_AUDIO_PLAYER:
      return {
        ...state,
        isPlaying: true,
        isPause: false,
        isOpen: true,
        isAudioLoading: false,
      };
    case PAUSE_AUDIO_PLAYER:
      return {
        ...state,
        isPlaying: false,
        isPause: true,
        isOpen: true,
        isAudioLoading: false,
      };
    case LOAD_AUDIO:
      return {
        ...state,
        isPlaying: false,
        isPause: false,
        isAudioLoading: true,
      };
    case PREV_AUDIO: {
      const previousTrack = Math.max(state.currentTrack - 1, 0);
      return {
        ...state,
        isPlaying: false,
        isPause: false,
        isAudioLoading: false,
        currentTrack: previousTrack,
      };
    }
    case NEXT_AUDIO: {
      if (state.data && state.data.chapters) {
        const lastTrack = Math.max(state.data.chapters.length - 1, 0);
        const nextTrack = Math.min(state.currentTrack + 1, lastTrack);
        return {
          ...state,
          isPlaying: false,
          isPause: false,
          isAudioLoading: false,
          currentTrack: nextTrack,
        };
      }
      return state;
    }
    case SET_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case SET_ID:
      return {
        ...state,
        id: action.payload,
      };
    case RESET_AUDIO_PLAYER:
      return initialState;
    default:
      return state;
  }
};

export default audioPlayerReducer;
