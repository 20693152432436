import CatalogProductTile from './CatalogProductTile';
import type { ProductTileProps } from './ProductTile.types';
import { ProductTileVariant } from './ProductTile.types';
import SimpleProductTile from './SimpleProductTile';

const CATALOG_PRODUCT = 'FinderProduct';
const INCLUDED_PRODUCT = 'Magento2_RestAPI_Product';

const ProductTile = ({ product, variant }: ProductTileProps) => {
  const isSimple = variant === ProductTileVariant.SIMPLE;

  return (
    <>
      {isSimple && product.__typename === INCLUDED_PRODUCT && (
        <SimpleProductTile product={product} />
      )}

      {product.__typename === CATALOG_PRODUCT && (
        <CatalogProductTile product={product} />
      )}
    </>
  );
};

export default ProductTile;
