import { useEffect, useState } from 'react';

/*
 * DOM-ready lifecycles
 */
export const useOnMount = (onMount?: () => void): boolean => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (onMount) onMount();
    setIsMounted(true);
    return () => setIsMounted(false);
    // This will be deleted in ni-v2, no need to fix!
  }, []);

  return isMounted;
};
