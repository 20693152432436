import { getIntlValidLocale } from '@app/helpers/locale';
import { DEFAULT_LOCALE } from '@config/common.config';

interface GetTranslationByLanguage {
  translations: Array<{
    languages_code?: {
      code?: string | null;
    } | null;
  } | null>;
  language: string;
  key: string;
}

const getTranslationByLanguage = <TranslationType = unknown>({
  translations,
  language,
  key,
}: GetTranslationByLanguage): TranslationType =>
  translations?.find(
    (el) => el?.languages_code?.code === getIntlValidLocale(language),
  )?.[key];

export const getTranslationWithFallback = <TranslationType = unknown>(
  data: GetTranslationByLanguage,
): TranslationType =>
  getTranslationByLanguage<TranslationType>(data) ||
  getTranslationByLanguage<TranslationType>({
    ...data,
    language: DEFAULT_LOCALE,
  });
