import { Box, Text } from '@theme-ui/components';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import { rem } from '@app/styles/theme/layout';

import theme from '../../../styles/theme';
import { Wrapper as PriceBoxWrapper } from '../PriceBox/styles';

export const appendAnimation = keyframes`
  from { transform: scale(0.8); opacity: 0;}
  to { transform: scale(1); opacity: 1;}
`;

export const YouSaveLabel = styled(Text)`
  color: ${theme.colors.productTileAcidGreen};
  font-size: ${rem(12)};
  display: block;
`;

export const PriceToggleText = styled(Text)`
  color: ${theme.colors.productTileBlueText};
  font-size: ${rem(12)};
  display: block;
`;

export const SalePriceBox = styled(Box)`
  animation: ${appendAnimation} 0.5s;
  flex-grow: 1;
  outline: none;
  cursor: pointer;
`;

export const PriceWrapper = styled(PriceBoxWrapper)`
  flex-grow: 0;
  flex-basis: 30%;
`;

export const UpdatePriceBox = styled(SalePriceBox)``;
