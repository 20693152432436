import type { ElementType } from 'react';
import styled from '@emotion/styled';

import theme from '@app/styles/theme';
import { rem } from '@app/styles/theme/layout';

interface CardProps {
  as?: ElementType;
  highlight?: boolean;
  disabled?: boolean;
  hasError?: boolean;
}

//TODO: To integrate within new Card component (WWW-11053)
const Card = styled.div<CardProps>`
  padding: ${rem(20)};
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.radii.normal};
  background: #fff;
  box-shadow: 0px 1px 3px #cfdde6;
  line-height: 1.5;
  transition: all 0.15s ease;

  &:active,
  &:focus {
    box-shadow: none;
  }

  ${(props) => props.highlight && `border-color: ${theme.colors.lightBlue};`}
  ${(props) => props.hasError === true && `border-color: ${theme.colors.red};`}
  ${(props) => props.disabled && 'opacity: 0.7;'}
`;

export default Card;
