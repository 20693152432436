import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@theme-ui/components';

import { getTranslationWithFallback } from '@app/helpers/translations';

import type {
  ContentElement1Col_translations as ContentElement1ColTranslations,
  ContentElement1Col as TContentElement1Col,
} from '../ContentSection/__generated__/ContentElement1Col';
import {
  contentStyles,
  IMAGE_STYLE_FIXED,
  layoutWidth,
  MainImage,
} from './ContentElement1Col.style';

const ContentElement1Col: FC<{ data: TContentElement1Col }> = ({
  data: {
    translations,
    image,
    image_style: imageStyle,
    text_style: textStyle,
    background,
  },
}) => {
  const { language } = useParams<{ language: string }>();

  const content = getTranslationWithFallback<
    ContentElement1ColTranslations['content']
  >({
    translations: translations || [],
    language,
    key: 'content',
  });

  const imageSrc = image?.full_url;

  return (
    <Box
      pt={4}
      backgroundColor={background}
      data-testid="seamless-element-1col"
    >
      {content && (
        <Box
          sx={{ width: layoutWidth, background }}
          px={2}
          pb={4}
          // @ts-expect-error textStyle type in Directus is string, not enum yet
          css={contentStyles(textStyle)}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
      {imageSrc && (
        <MainImage
          sx={{
            width: imageStyle === IMAGE_STYLE_FIXED ? layoutWidth : '100%',
          }}
          px={imageStyle === IMAGE_STYLE_FIXED ? [2, 0] : 0}
          pb={imageStyle === IMAGE_STYLE_FIXED ? 4 : 0}
          src={imageSrc}
        />
      )}
    </Box>
  );
};

export default ContentElement1Col;
