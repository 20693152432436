/* eslint
  class-methods-use-this: ["error", { "exceptMethods": ["trackErrors"] }]
  */

import { ApolloLink, Observable } from '@apollo/client';

import captureError from '../../helpers/sentry';

const OPERATIONS_BLOCK_LIST = [
  'ApplyCoupon',
  'ApplyGiftCard',
  'ApplyCouponMutation', // legacy
  'ApplyGiftCardMutation', // legacy
  'GuestCartId', // handled on CartIdProvider
  'MergeCarts', // handled on CartIdProvider
  'CreateCustomerAddressMutation',
];

export default class LogErrorsLink extends ApolloLink {
  request(operation, forward) {
    const subscriber = forward(operation);

    return new Observable((observer) => {
      const subscription = subscriber.subscribe({
        next: (result) => {
          this.trackErrors(result, operation);
          return observer.next(result);
        },
        error: (networkError) => {
          this.trackErrors(networkError.result, operation);
          return observer.error(networkError);
        },
        complete: (result) => observer.complete(result),
      });

      return () => {
        if (subscription) subscription.unsubscribe();
      };
    });
  }

  trackErrors(result, operation) {
    if (result && result.errors) {
      const queryName = operation.operationName;
      const errors = result.errors.map(({ message }) => message);

      if (OPERATIONS_BLOCK_LIST.includes(queryName)) {
        return;
      }

      captureError({
        name: `${queryName} Query Error`,
        message: `Apollo Client failed to execute ${queryName} query`,
        extra: {
          queryErrors: errors,
        },
        tags: [
          { name: 'error_type', value: 'graphqlQuery' },
          { name: 'graphql_query_name', value: queryName },
        ],
      });
    }
  }
}
