import getCategoryTranslation from '@app/hooks/useMenu/getCategoryTranslation';
import type { Menu, MenuCategory } from '@app/hooks/useMenu/types';

export interface PreparedMenuLineItemConfig {
  slug: string | null;
  title: string | null;
  description: string | null;
  isActive: boolean;
  parentSlugs: string[];
}

export const prepareMenuStructure = (
  menuConfig: Menu,
  urlChunks: string[],
  language: string,
): PreparedMenuLineItemConfig[][] => {
  const combinedUrlChunks: (string | null)[] = [...urlChunks, null];
  const lines: PreparedMenuLineItemConfig[][] = [];
  const parentSlugs: string[] = [];

  let activeItem;

  for (let i = 0; i < combinedUrlChunks.length; i += 1) {
    const children = activeItem?.children?.map(
      (category) => category?.category,
    );
    const options = activeItem ? children : menuConfig;

    const chunkValue = combinedUrlChunks[i];

    if (options?.length) {
      const line: PreparedMenuLineItemConfig[] = [];
      for (let j = 0; j < options.length; j += 1) {
        const item: MenuCategory = options[j];
        const { slug } = item;
        const translation = getCategoryTranslation(item.translations, language);
        const isActive = slug === chunkValue;
        line.push({
          slug,
          title: translation?.title || '',
          description: translation?.subtitle || '',
          isActive,
          parentSlugs: parentSlugs.slice(),
        });
        if (isActive) {
          activeItem = item;
        }
      }
      parentSlugs.push(activeItem?.slug);
      lines.push(line);
    }
  }
  return lines;
};
