import { gql } from '@apollo/client';

const CATALOG_CONTENT_PAGE_QUERY = gql`
  query CatalogContentPageById($id: ID!, $languages: [String]) {
    content {
      catalog_content_pages_by_id(id: $id) {
        featured_product_sections {
          featured_product_sections_id {
            content(filter: { languages_code: { code: { _in: $languages } } }) {
              languages_code {
                code
              }
              body
            }
            cta_buttons {
              cta_buttons_id {
                link
                translations(
                  filter: { languages_code: { code: { _in: $languages } } }
                ) {
                  languages_code {
                    code
                  }
                  label
                }
              }
            }
            products {
              products_id {
                sku
              }
            }
          }
        }
      }
    }
  }
`;

export default CATALOG_CONTENT_PAGE_QUERY;
