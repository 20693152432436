/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */
import { Children } from 'react';
import { IntlProvider } from 'react-intl';
import { gql, useQuery } from '@apollo/client';

import { DEFAULT_LOCALE } from '@config/common.config';

import type {
  TranslationsQuery,
  TranslationsQuery_translations,
} from './__generated__/TranslationsQuery';

type LanguageProviderProps = {
  locale?: string;
  keys?: string[] | null;
  children?: React.ReactNode;
};

const LanguageProvider = ({
  locale = DEFAULT_LOCALE,
  keys = null,
  children,
}: LanguageProviderProps) => {
  const {
    data: { translations } = {},
    loading,
  } = useQuery<TranslationsQuery>(TRANSLATIONS_QUERY, {
    variables: { locale, keys },
  });

  if (loading || !translations) {
    return null;
  }

  return (
    <IntlProvider
      locale={locale}
      key={locale}
      messages={createTranslationMap(translations)}
      // To facilitate migration from react-intl v2
      textComponent="span"
    >
      {Children.only(children)}
    </IntlProvider>
  );
};

const TRANSLATIONS_QUERY = gql`
  query TranslationsQuery($locale: Language!, $keys: [String]) {
    translations(language: $locale, keys: $keys, section: "website") {
      key
      value
    }
  }
`;

const createTranslationMap = (
  translations: (TranslationsQuery_translations | null)[],
) => {
  const messages = {};

  if (translations) {
    translations.forEach((translation) => {
      if (!translation) return;

      const { key, value } = translation;

      if (key) {
        messages[key] = value;
      }
    });
  }
  return messages;
};

export default LanguageProvider;
