import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '@__graphql__/types';

import type { PriceFragmentFragment } from '../../__graphql__/GetProductsForFinder';
import { PriceFragmentFragmentDoc } from '../../__graphql__/GetProductsForFinder';

const defaultOptions = {} as const;
export type GetProductsBySkuQueryVariables = Types.Exact<{
  condition?: Types.InputMaybe<Types.Condition>;
  language?: Types.InputMaybe<Types.Scalars['Language']>;
}>;

export type GetProductsBySkuQuery = {
  getProducts: {
    result: {
      items: Array<{
        id?: string | null;
        title?: string | null;
        sku?: string | null;
        image?: string | null;
        audioId?: string | null;
        videoId?: string | null;
        quickAddToCart?: boolean | null;
        quickAddToCartSku?: string | null;
        price?: {
          main?: PriceFragmentFragment | null;
          min?: PriceFragmentFragment | null;
          bundle?: {
            originalPrice?: string | null;
            diffPrice?: string | null;
            minPrice?: string | null;
          } | null;
        } | null;
        attributes?: Array<{
          key?: string | null;
          value?: any | null;
        } | null> | null;
      }>;
    };
  };
};

export const GetProductsBySkuDocument = gql`
  query getProductsBySku($condition: Condition, $language: Language) {
    getProducts(condition: $condition, language: $language) {
      result {
        items {
          id
          title
          sku
          image
          audioId
          videoId
          quickAddToCart
          quickAddToCartSku
          price {
            main {
              ...priceFragment
            }
            min {
              ...priceFragment
            }
            bundle {
              originalPrice
              diffPrice
              minPrice
            }
          }
          attributes(
            keys: [
              "sticker"
              "description"
              "detail_page"
              "pricing_page"
              "image_lazy"
              "override_price"
              "custom_price_text"
              "free"
            ]
          ) {
            key
            value
          }
        }
      }
    }
  }
  ${PriceFragmentFragmentDoc}
`;

/**
 * __useGetProductsBySkuQuery__
 *
 * To run a query within a React component, call `useGetProductsBySkuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsBySkuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsBySkuQuery({
 *   variables: {
 *      condition: // value for 'condition'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetProductsBySkuQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductsBySkuQuery,
    GetProductsBySkuQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductsBySkuQuery, GetProductsBySkuQueryVariables>(
    GetProductsBySkuDocument,
    options,
  );
}
export function useGetProductsBySkuLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductsBySkuQuery,
    GetProductsBySkuQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductsBySkuQuery,
    GetProductsBySkuQueryVariables
  >(GetProductsBySkuDocument, options);
}
export type GetProductsBySkuQueryHookResult = ReturnType<
  typeof useGetProductsBySkuQuery
>;
export type GetProductsBySkuLazyQueryHookResult = ReturnType<
  typeof useGetProductsBySkuLazyQuery
>;
export type GetProductsBySkuQueryResult = Apollo.QueryResult<
  GetProductsBySkuQuery,
  GetProductsBySkuQueryVariables
>;
