import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import { boxIn } from '../../styles/animations';
import theme from '../../styles/theme';
import { rem } from '../../styles/theme/layout';

const arrow: SerializedStyles = css`
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: ${rem(-3)};
  width: 0;
  height: 0;
  border-style: solid;
  border-width: ${rem(5)} ${rem(5)} 0;
  border-color: #444 transparent transparent;
  transition: transform 0.3s;
`;

const dropdownStyles: SerializedStyles = css`
  .dropdown-placeholder {
    font-size: ${rem(14)};
  }

  .dropdown-control {
    padding: 0 0 0 ${theme.space[3]};
    cursor: pointer;
  }

  .dropdown-menu {
    background: ${theme.colors.white};
    padding: ${theme.space[3]};
    position: absolute;
    width: 100%;
    animation: ${boxIn} 0.3s;
    font-size: ${rem(14)};
  }

  .Dropdown-option {
    padding: 6px 0;
    &.is-selected,
    &:hover {
      cursor: pointer;
      color: ${theme.colors.lighterBlue};
    }
  }
  &.is-open {
    .Dropdown-arrow-wrapper {
      > div {
        transform: rotate(-180deg);
      }
    }
  }
`;

export default {
  arrow,
  dropdownStyles,
};
