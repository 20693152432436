import styled from '@emotion/styled';

import theme from '@app/styles/theme';
import {
  screenLarge,
  screenMedium,
  screenSmall,
} from '@app/styles/theme/breakpoints';
import { rem } from '@app/styles/theme/layout';

import { H3 } from '../typography';

export const FullHeightWrapper = styled.div`
  flex-direction: column;
  display: flex;
  min-height: 100vh;
`;

export const BackgroundWrapper = styled.div`
  background-color: ${theme.colors.blueishGray};
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
  display: flex;
`;

export const SubHeader = styled.header<{
  bgColor?: string | null;
}>`
  background: ${({ bgColor, theme }) => bgColor ?? theme.colors.nearBlack};
  color: #fff;
  display: flex;
  align-items: center;
`;

export const SubHeaderTitle = styled(H3)`
  color: #fff;
  padding: 0 ${rem(32)};
  font-weight: 500;
  margin: ${rem(17)} 0 ${rem(20)};

  & a {
    color: #fff;
  }

  @media screen and (min-width: ${screenLarge}) {
    flex-grow: 0;
  }

  @media screen and (max-width: ${screenMedium}) {
    flex-grow: 1;
  }

  @media screen and (max-width: ${screenSmall}) {
    padding: 0 ${rem(16)};
    margin: ${rem(10)} 0 ${rem(12)};
    flex-grow: 1;
  }
`;

export const SubHeaderMenuWrapper = styled.nav`
  background: inherit;
  margin-left: auto;
  position: relative;
  padding-right: ${rem(25)};

  & a {
    color: #fff;
    display: inline-block;
    font-size: ${rem(14)};
    transition: all 250ms ease-out;
    padding: ${rem(24)} 0;
  }

  @media screen and (min-width: ${screenMedium}) {
    margin-left: ${rem(10)};

    & a {
      margin: 0 ${rem(10)};
      border-bottom: 3px solid transparent;

      &:hover {
        border-color: ${theme.colors.lighterBlue};
      }
    }
  }

  @media screen and (min-width: ${screenLarge}) {
    margin-left: ${rem(20)};

    & a {
      margin: 0 ${rem(15)};
    }
  }

  @media screen and (max-width: ${screenMedium}) {
    & a {
      display: block;
      padding: ${rem(12)};

      &:hover {
        background: ${theme.colors.black};
      }
    }
  }

  @media screen and (min-width: ${screenSmall}) {
    margin-left: 0;
  }
`;

export const SubHeaderMenuItems = styled.div`
  @media screen and (max-width: ${screenMedium}) {
    background: inherit;
    position: absolute;
    z-index: 1;
    top: ${rem(25)};
    right: 0;
    width: ${rem(280)};
    ${(props: { isVisible: boolean }) =>
      props.isVisible ? 'display: block' : 'display: none'}
  }
`;
