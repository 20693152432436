/*
Grid configuration based by the following design specs:
https://www.figma.com/file/ZxucA72dQLGzdycAjCniGe/Design-System---The-Grid?node-id=19%3A150
*/

import { rem } from './layout';

export const GRID_BREAKPOINT_MOBILE = 'mobile';
export const GRID_BREAKPOINT_TABLET_PORTRAIT = 'tabletPortrait';
export const GRID_BREAKPOINT_TABLET_LANDSCAPE = 'tabletLandscape';
export const GRID_BREAKPOINT_LAPTOP = 'laptop';
export const GRID_BREAKPOINT_DESKTOP = 'desktop';
export const GRID_BREAKPOINT_LARGE_SCREEN = 'largeScreen';

export interface GridBreakpoint {
  name: string;
  columns: number;
  columnsWidth: string;
  columnGap: string;
  outerMargin: string;
}

const grid: GridBreakpoint[] = [
  {
    name: GRID_BREAKPOINT_MOBILE,
    columns: 4,
    columnsWidth: rem(64),
    columnGap: rem(24),
    outerMargin: rem(24),
  },
  {
    name: GRID_BREAKPOINT_TABLET_PORTRAIT,
    columns: 4,
    columnsWidth: rem(136),
    columnGap: rem(48),
    outerMargin: rem(40),
  },
  {
    name: GRID_BREAKPOINT_TABLET_LANDSCAPE,
    columns: 8,
    columnsWidth: rem(72),
    columnGap: rem(48),
    outerMargin: rem(56),
  },
  {
    name: GRID_BREAKPOINT_LAPTOP,
    columns: 12,
    columnsWidth: rem(48),
    columnGap: rem(48),
    outerMargin: rem(88),
  },
  {
    name: GRID_BREAKPOINT_DESKTOP,
    columns: 12,
    columnsWidth: rem(70),
    columnGap: rem(40),
    outerMargin: rem(80),
  },
  {
    name: GRID_BREAKPOINT_LARGE_SCREEN,
    columns: 12,
    columnsWidth: rem(80),
    columnGap: rem(64),
    outerMargin: rem(128),
  },
];

export default grid;
