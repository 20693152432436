import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Text } from '@theme-ui/components';

import type { GetProductsForFinder_getProducts_result_items_price_bundle as PriceBundle } from '../__generated__/GetProductsForFinder';
import theme from '../../../styles/theme';
import { Price, Wrapper } from '../PriceBox/styles';
import { Wrapper as SmallText } from '../ProductLicense/ProductLicense.styles';
import messages from './BundlePrice.messages';
import { AllProductsLabel } from './BundlePrice.styles';

const BundlePrice: FC<
  Pick<PriceBundle, 'diffPrice' | 'minPrice' | 'originalPrice'>
> = ({ diffPrice, minPrice, originalPrice }) => {
  return (
    <>
      <Wrapper data-testid="bundle-price">
        <Box mb={1}>
          <SmallText>
            <FormattedMessage {...messages.allProducts} />
          </SmallText>
        </Box>

        <Price>
          <AllProductsLabel>
            <Text sx={{ display: 'block' }} color={theme.colors.black}>
              {originalPrice}
            </Text>
          </AllProductsLabel>
        </Price>
      </Wrapper>
      <Wrapper>
        <SmallText>
          <FormattedMessage {...messages.bundlePrice} />
        </SmallText>
        <Price>{minPrice}</Price>
        <SmallText color={theme.colors.productTileAcidGreen}>
          <FormattedMessage {...messages.youSave} />: {diffPrice}
        </SmallText>
      </Wrapper>
    </>
  );
};

export default BundlePrice;
