import type { ComponentType } from 'react';
import { Component } from 'react';
import type { MapDispatchToProps, MapStateToProps } from 'react-redux';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import { syncRoute } from '../../redux/actions/routes';
import type { RoutesState } from '../../redux/reducers/routes';

interface Props extends RouteComponentProps {
  syncRoute: (
    match: RouteComponentProps['match'],
    location: RouteComponentProps['location'],
  ) => unknown;
  currentRoute: RoutesState;
  children?: React.ReactNode;
}

interface State {
  path: string;
}

class InterceptorRoute extends Component<Props, State> {
  state: State = {
    path: '',
  };

  componentDidMount() {
    this.sync();
  }

  componentDidUpdate() {
    setImmediate(() => this.sync());
  }

  sync() {
    const routerPath = `${this.props.location.pathname}?${this.props.location.search}`;
    const statePath = this.state.path;

    if (routerPath !== statePath) {
      this.setState(
        () => ({ path: routerPath }),
        () => this.props.syncRoute(this.props.match, this.props.location),
      );
    }
  }

  render() {
    return null;
  }
}

// currentRoute prop seems unused but avoiding potential functional changes right now
const mapStateToProps: MapStateToProps<
  Pick<Props, 'currentRoute'>,
  void,
  { routes: RoutesState }
> = ({ routes }) => ({ currentRoute: routes });

const mapDispatchToProps: MapDispatchToProps<Pick<Props, 'syncRoute'>, void> = {
  syncRoute,
};

/* NOTE: Wrap this container in `withRouter()` or else redux's `connect()` will
         prevent the route props from propagating down.
         https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md#the-solution
*/
const ConnectedInterceptorRoute: ComponentType<RouteComponentProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InterceptorRoute);

export default withRouter(ConnectedInterceptorRoute);
