import type { MenuCategory } from './types';

export const findCategoryBySlug = (
  categories: MenuCategory[],
  searchSlug: string,
): MenuCategory | null | undefined => {
  return categories.find((category) => {
    if (category) {
      return category.slug === searchSlug;
    }
    return null;
  });
};

export const getActiveCategory = (
  categories: (MenuCategory | null | undefined)[] | undefined | null,
  urlChunks: string[],
): MenuCategory | null => {
  let activeItem;
  for (let i = 0; i < urlChunks.length; i += 1) {
    const children = activeItem?.children?.map(
      (category) => category?.category,
    );
    activeItem = findCategoryBySlug(
      activeItem ? children : categories,
      urlChunks[i],
    );
  }

  return activeItem || null;
};

export const findCategoryPathByHistoryPaths = (
  searchPath: string,
  categories?: (MenuCategory | null | undefined)[] | null,
  path: string[] = [],
): string[] | undefined => {
  if (categories) {
    for (let i = 0; i < categories.length; i++) {
      // @ts-expect-error: no recursive structure in shema
      const category = categories[i].category || categories[i];

      searchPath = searchPath.endsWith('/')
        ? searchPath.slice(0, -1)
        : searchPath;

      if (category.history_paths?.includes(searchPath)) {
        path.push(category.slug);
        return path;
      }

      if (category.children) {
        path.push(category.slug);

        const match = findCategoryPathByHistoryPaths(
          searchPath,
          category.children,
          path,
        );

        if (match) {
          return path;
        }

        path.pop();
      }
    }
  }
};
