import type { ComponentType } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import type { Condition } from '@__generated__/globalTypes';

import type { FinderState } from '../url';
import ProductList from './ProductList';

export default compose(withRouter)(ProductList) as ComponentType<{
  condition?: Condition | null;
  searchState?: FinderState;
  language?: string;
}>;
