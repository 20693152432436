// space
// Array<number|string> - for use as margin and pixel values

import { rem } from './layout';

const space: (number | string)[] = [
  0,
  rem(4), // 0.25 rem
  rem(8), // 0.5  rem
  rem(16), // 1  rem
  rem(32), // 2  rem
  rem(64), // 4  rem
  rem(128), // 8  rem
  rem(256), // 16  rem
];

export default space;
