import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as Types from '@__graphql__/types';

const defaultOptions = {} as const;
export type CartTotalsFragment = {
  subtotalExcludingTax?: {
    currency?: Types.Magento2_CurrencyEnum | null;
    value?: number | null;
  } | null;
  subtotalIncludingTax?: {
    currency?: Types.Magento2_CurrencyEnum | null;
    value?: number | null;
  } | null;
  discounts?: Array<{
    label: string;
    amount: {
      currency?: Types.Magento2_CurrencyEnum | null;
      value?: number | null;
    };
  } | null> | null;
  appliedTaxes?: Array<{
    label: string;
    amount: {
      currency?: Types.Magento2_CurrencyEnum | null;
      value?: number | null;
    };
  } | null> | null;
  grandTotal?: {
    currency?: Types.Magento2_CurrencyEnum | null;
    value?: number | null;
  } | null;
};

export type CartUpdateResultFragment = {
  id: string;
  isVirtual: boolean;
  items?: Array<
    | {
        __typename: 'Magento2_BundleCartItem';
        uid: string;
        quantity: number;
        prices?: {
          price: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
          priceIncludingTax: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
          rowTotalIncludingTax: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
          rowTotal: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
        } | null;
        product:
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            };
      }
    | {
        __typename: 'Magento2_ConfigurableCartItem';
        uid: string;
        quantity: number;
        prices?: {
          price: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
          priceIncludingTax: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
          rowTotalIncludingTax: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
          rowTotal: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
        } | null;
        product:
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            };
      }
    | {
        __typename: 'Magento2_DownloadableCartItem';
        uid: string;
        quantity: number;
        prices?: {
          price: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
          priceIncludingTax: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
          rowTotalIncludingTax: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
          rowTotal: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
        } | null;
        product:
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            };
      }
    | {
        __typename: 'Magento2_GiftCardCartItem';
        uid: string;
        quantity: number;
        prices?: {
          price: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
          priceIncludingTax: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
          rowTotalIncludingTax: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
          rowTotal: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
        } | null;
        product:
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            };
      }
    | {
        __typename: 'Magento2_SimpleCartItem';
        uid: string;
        quantity: number;
        prices?: {
          price: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
          priceIncludingTax: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
          rowTotalIncludingTax: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
          rowTotal: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
        } | null;
        product:
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            };
      }
    | {
        __typename: 'Magento2_VirtualCartItem';
        uid: string;
        quantity: number;
        prices?: {
          price: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
          priceIncludingTax: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
          rowTotalIncludingTax: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
          rowTotal: {
            currency?: Types.Magento2_CurrencyEnum | null;
            value?: number | null;
          };
        } | null;
        product:
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            }
          | {
              uid: string;
              name?: string | null;
              sku?: string | null;
              sdbsId?: string | null;
              stockStatus?: Types.Magento2_ProductStockStatus | null;
              smallImage?: {
                url?: string | null;
                label?: string | null;
                position?: number | null;
                disabled?: boolean | null;
              } | null;
            };
      }
    | null
  > | null;
  prices?: CartTotalsFragment | null;
};

export type StoreConfigFragment = {
  storeCode?: string | null;
  taxSalesDisplayPrice?: string | null;
  taxSalesDisplaySubtotal?: string | null;
};

export type CartQueryQueryVariables = Types.Exact<{
  cartId: Types.Scalars['String'];
}>;

export type CartQueryQuery = {
  magento2?: {
    storeConfig?: StoreConfigFragment | null;
    cart?:
      | ({
          appliedCoupons?: Array<{ code: string } | null> | null;
          appliedGiftCards?: Array<{
            code?: string | null;
            appliedBalance?: {
              value?: number | null;
              currency?: Types.Magento2_CurrencyEnum | null;
            } | null;
          } | null> | null;
          shippingAddresses: Array<{
            __typename: 'Magento2_ShippingCartAddress';
            selectedShippingMethod?: {
              amount: {
                value?: number | null;
                currency?: Types.Magento2_CurrencyEnum | null;
              };
              amountInclTax: {
                value?: number | null;
                currency?: Types.Magento2_CurrencyEnum | null;
              };
            } | null;
          } | null>;
        } & CartUpdateResultFragment)
      | null;
  } | null;
};

export const CartTotalsFragmentDoc = gql`
  fragment CartTotals on Magento2_CartPrices {
    subtotalExcludingTax {
      currency
      value
    }
    subtotalIncludingTax {
      currency
      value
    }
    discounts {
      amount {
        currency
        value
      }
      label
    }
    appliedTaxes {
      label
      amount {
        currency
        value
      }
    }
    grandTotal {
      currency
      value
    }
  }
`;
export const CartUpdateResultFragmentDoc = gql`
  fragment CartUpdateResult on Magento2_Cart {
    id
    isVirtual
    items {
      uid
      quantity
      prices {
        price {
          currency
          value
        }
        priceIncludingTax {
          currency
          value
        }
        rowTotalIncludingTax {
          currency
          value
        }
        rowTotal {
          currency
          value
        }
      }
      product {
        uid
        name
        sku
        sdbsId
        stockStatus
        smallImage {
          url
          label
          position
          disabled
        }
      }
      __typename
    }
    prices {
      ...CartTotals
    }
  }
  ${CartTotalsFragmentDoc}
`;
export const StoreConfigFragmentDoc = gql`
  fragment StoreConfig on Magento2_StoreConfig {
    storeCode
    taxSalesDisplayPrice
    taxSalesDisplaySubtotal
  }
`;
export const CartQueryDocument = gql`
  query CartQuery($cartId: String!) {
    magento2 {
      storeConfig {
        ...StoreConfig
      }
      cart(cart_id: $cartId) {
        ...CartUpdateResult
        appliedCoupons {
          code
        }
        appliedGiftCards {
          code
          appliedBalance {
            value
            currency
          }
        }
        shippingAddresses {
          selectedShippingMethod {
            amount {
              value
              currency
            }
            amountInclTax {
              value
              currency
            }
          }
          __typename
        }
      }
    }
  }
  ${StoreConfigFragmentDoc}
  ${CartUpdateResultFragmentDoc}
`;

/**
 * __useCartQueryQuery__
 *
 * To run a query within a React component, call `useCartQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartQueryQuery({
 *   variables: {
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function useCartQueryQuery(
  baseOptions: Apollo.QueryHookOptions<CartQueryQuery, CartQueryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CartQueryQuery, CartQueryQueryVariables>(
    CartQueryDocument,
    options,
  );
}
export function useCartQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CartQueryQuery,
    CartQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CartQueryQuery, CartQueryQueryVariables>(
    CartQueryDocument,
    options,
  );
}
export type CartQueryQueryHookResult = ReturnType<typeof useCartQueryQuery>;
export type CartQueryLazyQueryHookResult = ReturnType<
  typeof useCartQueryLazyQuery
>;
export type CartQueryQueryResult = Apollo.QueryResult<
  CartQueryQuery,
  CartQueryQueryVariables
>;
