import type { FC } from 'react';
import { Box, Text } from '@theme-ui/components';

import type { Menu } from '@app/hooks/useMenu/types';

import { rem } from '../../../styles/theme/layout';
import CategoryBanner from '../CategoryBanner';
import MenuItem from './MenuItem';
import type { PreparedMenuLineItemConfig } from './prepareMenuStructure';
import { prepareMenuStructure } from './prepareMenuStructure';
import {
  mainMenuStyles,
  menuBoxStyles,
  menuLinkDescriptionStyles,
  menuLinkTitleStyles,
  menuListStyles,
  subMenuBoxStyles,
  subMenuLevelStyles,
} from './TopMenu.styles';

export interface TopMenuProps {
  config: Menu;
  value: string[];
  language: string;
}

interface MenuLineProps {
  items: PreparedMenuLineItemConfig[];
}

interface SubMenuLineProps {
  items: PreparedMenuLineItemConfig[][];
}

export const MainMenu: FC<MenuLineProps> = ({ items }) => {
  return (
    <Box
      px={[0, 0, 4]}
      py={[3, 2, 0]}
      css={menuBoxStyles}
      data-testid="product-finder-top-menu-root"
      as="nav"
    >
      <ul css={mainMenuStyles}>
        {items?.map(({ title, description, ...itemConfig }, itemIndex) => (
          <MenuItem isSubMenu={false} {...itemConfig} key={itemIndex}>
            <Text sx={{ display: 'block' }} css={menuLinkTitleStyles}>
              {title}
            </Text>
            <Text sx={{ display: 'block' }} css={menuLinkDescriptionStyles}>
              {description}
            </Text>
          </MenuItem>
        ))}
      </ul>
    </Box>
  );
};

export const SubMenu: FC<SubMenuLineProps> = ({ items }) => (
  <Box css={subMenuBoxStyles} px={[2, 3, 3]} as="nav">
    {items.map((subItemItems, itemIndex) => (
      <Box
        data-testid={`product-finder-top-menu-sub-${itemIndex}`}
        key={itemIndex}
        css={subMenuLevelStyles}
        py={[2, 2, rem(6)]}
      >
        <ul css={menuListStyles}>
          {subItemItems.map(({ title, ...itemConfig }, subItemIndex) => (
            <MenuItem isSubMenu {...itemConfig} key={subItemIndex}>
              {title}
            </MenuItem>
          ))}
        </ul>
      </Box>
    ))}
  </Box>
);

const getCategoryBannerId = (
  config: TopMenuProps['config'],
  value: TopMenuProps['value'],
): string | null => {
  const [currentSlug, ...restValue] = value;
  const currentItem = config?.find((item) => item?.slug === currentSlug);
  if (!currentItem) {
    return null;
  }
  const categoryBannerId = currentItem.category_banner?.id;
  if (categoryBannerId) {
    return categoryBannerId;
  }
  if (currentItem.children?.length && restValue.length) {
    return getCategoryBannerId(
      currentItem.children.map(
        (item) => item?.category,
      ) as TopMenuProps['config'],
      restValue,
    );
  }
  return null;
};

export const TopMenu: FC<TopMenuProps> = ({
  config,
  language,
  value = [''],
}) => {
  const [mainItems, ...subItems] = prepareMenuStructure(
    config,
    value,
    language,
  );

  const categoryBannerId = getCategoryBannerId(config, value);

  return (
    <>
      <MainMenu items={mainItems} />
      {categoryBannerId ? (
        <CategoryBanner categoryBannerId={categoryBannerId} />
      ) : null}
      <SubMenu items={subItems} />
    </>
  );
};
