import { defineMessages } from 'react-intl';

export default defineMessages({
  supportHeader: {
    id: 'components.header.links.support',
    defaultMessage: 'Support',
  },
  helpTitle: {
    id: 'components.success.helpTitle',
    defaultMessage: 'Questions about your order?',
  },
  shopFaq: {
    id: 'components.success.shopFaq',
    defaultMessage: 'Online Shop FAQ',
  },
  orderSupport: {
    id: 'components.success.orderSupport',
    defaultMessage: 'Order Support',
  },
  contactSupportDescription: {
    id: 'components.success.contactSupportDescription',
    defaultMessage: 'Contact our order support team for order-related issues',
  },
  faqDescription: {
    id: 'components.success.faqDescription',
    defaultMessage:
      'The most frequently asked questions and answers about the NI Online Shop',
  },
});
