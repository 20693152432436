import { lazy, Suspense } from 'react';

import Footer from '@app/components/Footer';
import Header from '@app/components/Header';

import styles from './ProductFinderLayout.styles';

const LanguageSuggestPopup = lazy(
  () =>
    import(
      /*webpackChunkName: 'LanguageSuggestPopup' */ '@app/components/LanguageSuggestPopup'
    ),
);

const LoginPromptPopup = lazy(
  () =>
    import(
      /*webpackChunkName: 'LoginPromptPopup' */ '@app/components/LoginPromptPopup'
    ),
);

const AudioPlayer = lazy(
  () =>
    import(/*webpackChunkName: 'AudioPlayer' */ '@app/components/AudioPlayer'),
);

const VideoOverlay = lazy(
  () =>
    import(
      /*webpackChunkName: 'ProductFinder' */ '@app/components/VideoOverlay'
    ),
);

const ProductFinderLayout = ({ children }: ProductFinderLayoutProps) => {
  return (
    <div>
      <Suspense fallback={null}>
        <LanguageSuggestPopup />
      </Suspense>

      <Suspense fallback={null}>
        <LoginPromptPopup />
      </Suspense>

      <div css={styles.wrapper}>
        <Header />
        {children}
      </div>

      <Footer />

      <Suspense fallback={null}>
        <AudioPlayer />
      </Suspense>

      <Suspense fallback={null}>
        <VideoOverlay />
      </Suspense>
    </div>
  );
};

export interface ProductFinderLayoutProps {
  children: React.ReactNode;
}

export default ProductFinderLayout;
