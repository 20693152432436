import { css, Global, withTheme } from '@emotion/react';

import normalize from './lib/normalize';
import fontFace from './fontFace';
import { modalStyles } from './modal';
import routeTransition from './routeTransition';
import type { AppTheme } from './theme';

const GlobalBaseStyles = ({ theme }: { theme: AppTheme }) => (
  <Global
    styles={css`
      ${normalize}

      ${fontFace}

      html {
        height: 100%;
        font-size: ${theme.layout.pxSize}px;
      }

      body {
        color: ${theme.colors.black};
        min-height: 100%;
        font-family: ${theme.fonts.fontNormal};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
      }

      a {
        text-decoration: none;
        color: ${theme.colors.lightBlue};
      }

      b,
      strong {
        font-weight: 500;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 0.55rem;
        margin-top: 0.35rem;
      }

      ${modalStyles}

      ${routeTransition}
    `}
  />
);

export default withTheme(GlobalBaseStyles);
