import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import theme from '../../../styles/theme';

const wrapper: SerializedStyles = css`
  border: 1px solid ${theme.colors.productBorderColor};

  height: 100%;
  width: 100%;
  background-color: #eee;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
`;

export default {
  wrapper,
};
