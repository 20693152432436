/*
 * Header messages
 *
 * This contains all the text for the Header component
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  products: {
    id: 'components.header.links.products',
    defaultMessage: 'Products',
  },
  community: {
    id: 'components.header.links.community',
    defaultMessage: 'Community',
  },
  support: {
    id: 'components.header.links.support',
    defaultMessage: 'Support',
  },
});
