import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import type { IntlShape } from 'react-intl';
import { Box, Flex } from '@theme-ui/components';

import searchIcon from '../../../assets/images/search.svg';
import type { FinderState } from '../url';
import messages from './ProductSearch.messages';
import styles from './ProductSearch.styles';

interface Props {
  onSearch: (searchText) => void;
  searchState: FinderState;
  intl: IntlShape;
}

const ProductSearch = ({ onSearch, searchState, intl }: Props) => {
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    setSearchText(searchState.searching || '');
  }, [searchState]);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target as HTMLInputElement;
    setSearchText(value);
    onSearch({ ...searchState, searching: value });
  };

  return (
    <Flex css={styles.wrapper}>
      <Box mr={1}>
        <img
          alt={intl.formatMessage(messages.searchPlaceholder)}
          src={searchIcon}
        />
      </Box>

      <input
        id="product-finder-search-input"
        data-testid="product-finder-search-input"
        css={styles.input}
        type="text"
        value={searchText}
        placeholder={intl.formatMessage(messages.searchPlaceholder)}
        onChange={onInputChange}
      />
    </Flex>
  );
};

export default ProductSearch;
