import type { FC } from 'react';

import type { ContentElementsQuery_content_menu_categories_content_elements_item as ContentElementType } from '../ContentSection/__generated__/ContentElementsQuery';
import ContentElement1Col from './ContentElement1Col';
import ContentElement2Col from './ContentElement2Col';
import ContentElement2ColGrid from './ContentElement2ColGrid';

const ContentElement: FC<{ data: ContentElementType }> = ({ data }) => {
  switch (data.__typename) {
    case 'content_elements_1col':
      return <ContentElement1Col data={data} />;
    case 'content_elements_2_col':
      return <ContentElement2Col data={data} />;
    case 'content_elements_2col_grid':
      return <ContentElement2ColGrid data={data} />;
    default:
      return null;
  }
};

export default ContentElement;
