import { css } from '@emotion/react';

export default css`
  @font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
      url('/assets/fonts/Graphik-Regular-Web.woff2') format('woff2'),
      url('/assets/fonts/Graphik-Regular-Web.woff') format('woff');
  }

  @font-face {
    font-family: 'Graphik';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src:
      url('/assets/fonts/Graphik-RegularItalic-Web.woff2') format('woff2'),
      url('/assets/fonts/Graphik-RegularItalic-Web.woff') format('woff');
  }

  @font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src:
      url('/assets/fonts/Graphik-Medium-Web.woff2') format('woff2'),
      url('/assets/fonts/Graphik-Medium-Web.woff') format('woff');
  }

  @font-face {
    font-family: 'Graphik';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src:
      url('/assets/fonts/Graphik-MediumItalic-Web.woff2') format('woff2'),
      url('/assets/fonts/Graphik-MediumItalic-Web.woff') format('woff');
  }

  @font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src:
      url('/assets/fonts/Graphik-Semibold-Web.woff2') format('woff2'),
      url('/assets/fonts/Graphik-Semibold-Web.woff') format('woff');
  }

  @font-face {
    font-family: 'Graphik';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src:
      url('/assets/fonts/Graphik-SemiboldItalic-Web.woff2') format('woff2'),
      url('/assets/fonts/Graphik-SemiboldItalic-Web.woff') format('woff');
  }
`;
