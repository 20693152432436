import { LoadingBox, Wrapper } from './MediaIcon.styles';

export interface MediaIconProps {
  svg: string;
  isLoading: boolean;
  altText: string;
  onClick: () => void;
}

const MediaIcon = ({ altText, svg, isLoading, onClick }: MediaIconProps) => (
  <Wrapper onClick={onClick}>
    <img alt={altText} src={svg} />
    {isLoading && <LoadingBox data-testid="loading-box" />}
  </Wrapper>
);

export default MediaIcon;
