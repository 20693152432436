/*
 * ProductList messages
 *
 * This contains all the text for the ProductList component
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  loadMore: {
    id: 'components.ProductList.loadMore',
    defaultMessage: 'Show more results',
  },
  loadPrevious: {
    id: 'catalog.pagination.previous',
    defaultMessage: 'Load previous',
  },
  noProducts: {
    id: 'components.ProductList.noProducts',
    defaultMessage: 'No products found, please try a different search.',
  },
  youOwn: {
    id: 'catalog.ownedProducts.youOwn',
    defaultMessage: 'You own all of the products in this category.',
  },
});
