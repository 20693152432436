import type { Magento2_CurrencyEnum } from 'ni-login/src/graphql';
import type { ApolloError } from '@apollo/client';
import { gql } from '@apollo/client';

import type {
  CartQuery_magento2,
  CartQuery_magento2_cart,
} from '@app/pages/Cart/__generated__/LegacyCartQueriesTypes';
import {
  CART_UPDATE_RESULT_FRAGMENT,
  STORE_CONFIG_FRAGMENT,
} from '@app/pages/Cart/Cart.queries';

import { useGetCartId } from '../context/CartIdProvider/CartIdProvider';
import type { SimpleCartQueryQuery } from './__graphql__/useSimpleCart';
import { useSimpleCartQueryQuery } from './__graphql__/useSimpleCart';

type CartItems = CartQuery_magento2_cart['items'];
type CartPrices = CartQuery_magento2_cart['prices'];
type CartStoreConfig = CartQuery_magento2['storeConfig'];

interface SimpleCart {
  cartId?: string;
  loading: boolean;
  items?: CartItems;
  itemsCount?: number;
  prices?: CartPrices;
  storeConfig?: CartStoreConfig;
  isVirtual?: boolean;
  error?: ApolloError;
  appliedCoupons?: string[];
  selectedShippingMethod?:
    | {
        methodCode: string;
        amount: {
          value?: number | null;
          currency?: Magento2_CurrencyEnum | null;
        };
        amountInclTax: {
          value?: number | null;
          currency?: Magento2_CurrencyEnum | null;
        };
      }
    | null
    | undefined;
}

const useSimpleCart = (callbacks?: {
  onCompleted?: (data: SimpleCartQueryQuery) => void;
  onError?: (error: ApolloError) => void;
}): SimpleCart => {
  const { cartId, loading: cartIdLoading } = useGetCartId();

  const {
    loading: cartQueryLoading,
    data,
    error,
  } = useSimpleCartQueryQuery({
    variables: {
      cartId: cartId || '',
    },
    skip: !cartId,
    fetchPolicy: 'cache-and-network',
    ...callbacks,
  });

  // ensure cardId comes from cartQuery not cartId hook
  const id = data?.magento2?.cart?.id;

  // since useSimpleCartQuery is skiped until cartId is available it's initial loading state is false
  const loading = cartIdLoading || cartQueryLoading;

  // get cart data and items count
  const cartData = getCartData(data);
  const itemsCount = cartData.items?.reduce(
    (acc, item) => acc + (item?.quantity ?? 0),
    0,
  );

  return { cartId: id, loading, error, itemsCount, ...getCartData(data) };
};

export default useSimpleCart;

const getCouponCodes = (
  coupons:
    | ({
        code: string;
      } | null)[]
    | null
    | undefined,
) => {
  if (coupons) {
    const couponCodes = coupons
      .filter(
        (coupon) => coupon?.code !== undefined && coupon?.code !== undefined,
      )
      .map((coupon) => coupon?.code || '');
    if (couponCodes.length) {
      return couponCodes;
    }
  }
  return undefined;
};

export const getCartData = (data?: SimpleCartQueryQuery) => ({
  items: data?.magento2?.cart?.items as CartItems,
  prices: data?.magento2?.cart?.prices as CartPrices,
  storeConfig: data?.magento2?.storeConfig as CartStoreConfig,
  isVirtual: data?.magento2?.cart?.isVirtual,
  appliedCoupons: getCouponCodes(data?.magento2?.cart?.appliedCoupons),
  selectedShippingMethod: data?.magento2?.cart?.shippingAddresses.length
    ? data?.magento2?.cart?.shippingAddresses[0]?.selectedShippingMethod
    : undefined,
});

export const SIMPLE_CART_QUERY = gql`
  ${CART_UPDATE_RESULT_FRAGMENT}
  ${STORE_CONFIG_FRAGMENT}

  query SimpleCartQuery($cartId: String!) {
    magento2 {
      storeConfig {
        ...StoreConfig
      }
      cart(cart_id: $cartId) {
        ...CartUpdateResult
        appliedCoupons {
          code
        }
        shippingAddresses {
          selectedShippingMethod {
            methodCode
            amount {
              value
              currency
            }
            amountInclTax {
              value
              currency
            }
          }
          __typename
        }
      }
    }
  }
`;
