const CrossIcon = ({
  fill: fillDefault = '#222222',
  height: heightDefault = '12',
  width: widthDefault = '12',
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={widthDefault}
      height={heightDefault}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0L12 2L8 6L12 10L10 12L6 8L2 12L0 10L4 6L0 2L2 0L6 4L10 0Z"
        fill={fillDefault}
      />
    </svg>
  );
};

export default CrossIcon;
