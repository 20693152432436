import type { ReactElement } from 'react';

const OPEN_MODAL = 'OPEN_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';

export interface OpenModalAction {
  type: typeof OPEN_MODAL;
  value: ModalState;
}

export interface CloseModalAction {
  type: typeof CLOSE_MODAL;
}

export interface ModalState {
  isOpen?: boolean;
  content: ReactElement | string;
  label: ReactElement | string;
  closeOnEscape?: boolean;
  additionalClass?: string;
}

const openModal = (content: ModalState): OpenModalAction => ({
  type: OPEN_MODAL,
  value: content,
});

const closeModal = (): CloseModalAction => ({ type: CLOSE_MODAL });

export const INITIAL_STATE = {
  isOpen: false,
  content: '',
  label: '',
  closeOnEscape: false,
};

const reducer = (
  state: ModalState = INITIAL_STATE,
  action: OpenModalAction | CloseModalAction,
): ModalState => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        isOpen: true,
        content: action.value.content,
        label: action.value.label,
        closeOnEscape: action.value.closeOnEscape,
        additionalClass: action.value.additionalClass,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        isOpen: false,
        content: INITIAL_STATE.content,
        label: INITIAL_STATE.label,
      };
    default:
      return state;
  }
};

export { OPEN_MODAL, CLOSE_MODAL, openModal, closeModal, reducer };
