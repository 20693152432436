import { getIntlValidLocale } from '@app/helpers/locale';
import { DEFAULT_LOCALE } from '@config/common.config';

import type {
  MenuQuery_content_menu_menu_categories_category_children_category_children_category_translations,
  MenuQuery_content_menu_menu_categories_category_children_category_translations,
} from './__generated__/MenuQuery';
import type { MenuTranslation } from './__generated__/MenuTranslation';

type Translations =
  | (MenuQuery_content_menu_menu_categories_category_children_category_translations | null)[]
  | (MenuQuery_content_menu_menu_categories_category_children_category_children_category_translations | null)[]
  | null
  | undefined;

export default (
  translations: Translations,
  language,
): MenuTranslation | null => {
  const getTranslationByLanguage = (language) =>
    translations?.find(
      (el) => el?.languages_code?.code === getIntlValidLocale(language),
    );

  return (
    getTranslationByLanguage(language) ||
    getTranslationByLanguage(DEFAULT_LOCALE) ||
    null
  );
};
