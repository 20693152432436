import { APP_BASE_URL, APP_LANGUAGES } from '../../../config/client.config';
import { getIntlValidLocale } from '../../helpers/locale';

export default (path: string): { altLang: string; url: string }[] =>
  APP_LANGUAGES.map((altLang) => {
    return {
      altLang: getIntlValidLocale(altLang),
      url: APP_BASE_URL + path.replace(/^\/(.*?)\//, `/${altLang}/`),
    };
  });
