/**
 * All the colors used in the theme.
 */

import alerts from './alerts.colors';
import audioPlayer from './audioPlayer.colors';
import base from './base.colors';
import footer from './footer.colors';
import header from './header.colors';
import productFinder from './productFinder.colors';
import topMenu from './topMenu.colors';

export default {
  ...base,
  ...topMenu,
  ...audioPlayer,
  ...productFinder,
  ...header,
  ...footer,
  ...alerts,
};
