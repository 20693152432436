import { Box } from '@theme-ui/components';

import ErrorContent from '../ErrorContent';
import FeaturedProducts from '../FeaturedProducts';
import styles from '../ProductFinder.styles';
import ProductPlaceholders from '../ProductPlaceholders';
import useContentPage from './useContentPage';

const CatalogContentPage = ({ id }: { id: string }) => {
  const { loading, error, contentPage } = useContentPage(id);

  if (loading) {
    return (
      <Box p={2} mb={2}>
        <Box css={styles.wrapper}>
          <Box css={styles.contentWrapper}>
            <ProductPlaceholders count={4} />
          </Box>
        </Box>
      </Box>
    );
  }

  if (error) {
    return <ErrorContent />;
  }

  const sections = contentPage?.featuredProductSections.map(
    (section, index) => (
      <Box key={index} pt={2} pb={4} px={2}>
        <Box data-testid="product-finder-content-section" css={styles.wrapper}>
          <Box css={styles.contentWrapper}>
            <FeaturedProducts {...section} />
          </Box>
        </Box>
      </Box>
    ),
  );

  return sections ? <>{sections}</> : null;
};

export default CatalogContentPage;
