import { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { Card } from '@theme-ui/components';

import { screenMedium, screenSmall } from '../../../styles/theme/breakpoints';
import styles from './ProductImage.styles';

export const RESOLUTION_TOKEN = '[[display]]';

export const MEDIA_QUERIES = [
  // mobile
  { key: 'm', keyHd: 'm@2x', media: `(max-width: ${screenSmall})` },
  // tablet
  {
    key: 't',
    keyHd: 't@2x',
    media: `(min-width: ${screenSmall}) and (max-width: ${screenMedium})`,
  },
  // desktop
  { key: 'd', keyHd: 'd@2x', media: `(min-width: ${screenMedium})` },
];

export const DEFAULT_MEDIA_QUERY_KEY = 'd';

interface ProductImageProps {
  image: string | null;
  disableLazyLoad?: boolean;
}

const ProductImage = ({ image, disableLazyLoad }: ProductImageProps) => {
  const [isVisible, setVisible] = useState(disableLazyLoad);

  if (!image) {
    return null;
  }

  const responsiveImages = MEDIA_QUERIES.map(({ key, keyHd, media }) => ({
    key,
    srcSet: [
      image.replace(RESOLUTION_TOKEN, key),
      `${image.replace(RESOLUTION_TOKEN, keyHd)} 2x`,
    ].join(', '),
    media,
  }));

  const defaultImage = image.replace(RESOLUTION_TOKEN, DEFAULT_MEDIA_QUERY_KEY);

  const onChange = (inViewport): void => {
    if (!isVisible && inViewport) {
      setVisible(true);
    }
  };

  return (
    <VisibilitySensor partialVisibility onChange={onChange}>
      <Card css={styles.card}>
        {isVisible ? (
          <picture>
            {responsiveImages.map((props) => (
              <source {...props} key={props.key} />
            ))}
            <img
              alt=""
              css={styles.fullBlockSize}
              src={defaultImage}
              data-testid="fallback-image"
            />
          </picture>
        ) : null}
      </Card>
    </VisibilitySensor>
  );
};

export default ProductImage;
