import { Box } from '@theme-ui/components';

import { Button } from '@app/components/Button';

import type { GetProductsForFinderQuery } from './__graphql__/GetProductsForFinder';

interface InputProps {
  products?: GetProductsForFinderQuery['getProducts']['result'];
  refetch?: () => void;
}

const ErrorContent = ({ products, refetch }: InputProps) => {
  const retryButton = (
    <Button
      type="primary"
      onClick={refetch}
      data-testid="product-finder-try-again-button"
    >
      Try again
    </Button>
  );

  return (
    <Box
      pt={4}
      pb={4}
      sx={{ textAlign: 'center' }}
      data-testid="product-finder-error-message"
    >
      Oops! We seem to be having trouble completing your request.
      <br />
      Please try again in a moment.
      <br />
      <br />
      {typeof products !== 'undefined' && retryButton}
    </Box>
  );
};

export default ErrorContent;
