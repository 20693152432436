import { Box } from '@theme-ui/components';
import styled from '@emotion/styled';

import { screenSmall } from '../../../styles/theme/breakpoints';
import { rem } from '../../../styles/theme/layout';

export const contentGridWidth = ['100%', '48%'];
export const contentGridPadding = [3, 0];

export const Wrapper = styled(Box)`
  margin: 0 auto;
`;

export const MainContent = styled(Box)`
  line-height: 1.5;

  h2,
  h3 {
    font-size: ${rem(32)};
    margin-bottom: ${rem(20)};
    line-height: 1.15;
  }

  p {
    font-size: ${rem(16)};
  }

  @media screen and (max-width: ${screenSmall}) {
    h2,
    h3 {
      margin-bottom: ${rem(10)};
      margin-top: ${rem(10)};
    }
  }
`;

export const ContentGrid = styled(Box)`
  line-height: 1.5;

  h2,
  h3 {
    font-size: ${rem(24)};
    margin-bottom: ${rem(10)};
    line-height: 1.15;
  }
  p {
    font-size: ${rem(16)};
  }

  @media screen and (max-width: ${screenSmall}) {
    h2,
    h3 {
      font-size: ${rem(24)};
      margin-bottom: ${rem(10)};
      margin-top: ${rem(10)};
    }
  }
`;
