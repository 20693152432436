import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import { fadeIn } from '../../../styles/animations';
import theme from '../../../styles/theme';

const boxStyle = (): SerializedStyles => css`
  opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
  animation-delay: ${1 / 10}s;
`;

const loadingWrapper: SerializedStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${theme.space[5]};
  padding-bottom: ${theme.space[5]};
  animation: ${fadeIn} 1s;
`;

const loadMoreWrapper: SerializedStyles = css`
  justify-content: center;
  width: 100%;
`;

const pageTransition: SerializedStyles = css`
  animation: 0.4s ${fadeIn} forwards ease-in-out;
`;

const mainWrapper: SerializedStyles = css`
  opacity: 0;
  flex-wrap: wrap;
  width: 100%;
  ${pageTransition}
`;

export default {
  boxStyle,
  loadingWrapper,
  loadMoreWrapper,
  mainWrapper,
};
