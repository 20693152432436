import { Box, Flex } from '@theme-ui/components';

import type { GetProductsForFinder_getProducts_result_items_price_main as PriceProps } from '../__generated__/GetProductsForFinder';
import ProductLicense from '../ProductLicense';
import { Price, PriceDiff, Wrapper } from './styles';

const HARDWARE_ITEM_TYPE = 'Hardware';

type PriceBoxProps = DeepOmit<PriceProps, '__typename' | 'savePrice'>;

const PriceBox = ({
  itemType,
  license,
  licenseId,
  originalPrice,
  minPrice,
  personalized,
}: PriceBoxProps) => {
  return (
    <Wrapper>
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        {license && itemType !== HARDWARE_ITEM_TYPE && (
          <Box mb={1}>
            <ProductLicense
              data-testid="product-license"
              itemType={itemType}
              license={license}
              licenseId={licenseId}
              personalized={personalized}
            />
          </Box>
        )}
        <Price>
          <span
            data-testid="product-tile-price"
            className="product-tile-original-price"
          >
            {minPrice || originalPrice}
          </span>
        </Price>
        {minPrice && minPrice !== originalPrice && (
          <PriceDiff
            data-testid="product-tile-diff-price"
            className="product-tile-diff-price"
          >
            {originalPrice}
          </PriceDiff>
        )}
      </Flex>
    </Wrapper>
  );
};

export default PriceBox;
