import { useParams } from 'react-router-dom';
import { useQuery, type ApolloError } from '@apollo/client';

import { getIntlValidLocale } from '@app/helpers/locale';
import useLocationSearch from '@app/hooks/useLocationSearch';
import {
  DEFAULT_LOCALE,
  DIRECTUS_SUPER_CLIENT_URL_KEY,
} from '@config/common.config';

import type {
  CategoryBannerByIdQuery_content_category_banners_by_id,
  CategoryBannerByIdQuery_content_category_banners_by_id_background_desktop,
  CategoryBannerByIdQuery_content_category_banners_by_id_background_mobile,
  CategoryBannerByIdQuery_content_category_banners_by_id_translations,
  CategoryBannerByIdQuery as CategoryBannerByIdQueryType,
  CategoryBannerByIdQueryVariables,
} from './__generated__/CategoryBannerByIdQuery';
import CategoryBannerQuery from './CategoryBanner.query';

interface CategoryBanner
  extends Pick<
    CategoryBannerByIdQuery_content_category_banners_by_id,
    'theme' | 'text_alignement'
  > {
  body?: CategoryBannerByIdQuery_content_category_banners_by_id_translations['body'];
  countdown_template?: CategoryBannerByIdQuery_content_category_banners_by_id_translations['countdown_template'];
  background_mobile?: CategoryBannerByIdQuery_content_category_banners_by_id_background_mobile['full_url'];
  background_desktop?: CategoryBannerByIdQuery_content_category_banners_by_id_background_desktop['full_url'];
  countdown_date?: string | null;
}

interface UseCategoryBanner {
  loading?: boolean;
  error?: ApolloError;
  banner?: CategoryBanner | null;
}

const useCategoryBanner = (categoryBannerId: string): UseCategoryBanner => {
  const { language } = useParams<{ language: string }>();
  const directusSuperClientToken = useLocationSearch(
    DIRECTUS_SUPER_CLIENT_URL_KEY,
  );

  const languages = [getIntlValidLocale(DEFAULT_LOCALE)];
  const targetLanguage = getIntlValidLocale(language);

  if (targetLanguage !== DEFAULT_LOCALE) {
    languages.push(targetLanguage);
  }
  const { data, error, loading } = useQuery<
    CategoryBannerByIdQueryType,
    CategoryBannerByIdQueryVariables
  >(CategoryBannerQuery, {
    variables: { categoryBannerId, languages },
    context: { directusSuperClientToken },
    fetchPolicy: 'network-only',
  });

  if (!data) {
    return { error, loading };
  }

  const banner = data?.content?.category_banners_by_id;

  if (!banner) {
    return { banner: null };
  }

  const {
    translations,
    background_mobile,
    background_desktop,
    theme,
    text_alignement,
    countdown_date,
  } = banner;

  const getTranslationByLanguage = (language, key) =>
    translations?.find(
      (el) => el?.languages_code?.code === getIntlValidLocale(language),
    )?.[key];

  return {
    banner: {
      theme,
      text_alignement,
      body:
        getTranslationByLanguage(language, 'body') ||
        getTranslationByLanguage(DEFAULT_LOCALE, 'body'),
      countdown_template:
        getTranslationByLanguage(language, 'countdown_template') ||
        getTranslationByLanguage(DEFAULT_LOCALE, 'countdown_template'),
      countdown_date,
      background_mobile: background_mobile?.full_url,
      background_desktop: background_desktop?.full_url,
    },
  };
};

export default useCategoryBanner;
