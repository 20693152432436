import { mobile } from './theme/breakpoints';

export const modalStyles = `
  .ReactModal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.8);
  }

  .ReactModal-body-open {
    overflow: hidden!important;
  }

  .ReactModal-content {
    position: relative;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .ReactModal-overlay.return-request {
    z-index: 9;
  }

  .ReactModal-content.return-request {
    @media screen and (max-width: ${mobile}) {
      height: auto;
      min-height: 100vh;
    }
  }
  `;
