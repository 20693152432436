import { FormattedMessage } from 'react-intl';

import type { GetProductsForFinder_getProducts_result_items_price_main as PriceProps } from '../__generated__/GetProductsForFinder';
import messages from './ProductLicense.messages';
import { PersonalizedWrapper, Wrapper } from './ProductLicense.styles';

export type LicenseProps = Pick<
  PriceProps,
  'itemType' | 'license' | 'licenseId' | 'personalized'
>;

const SOFTWARE_LICENSE = 'Software';

const ProductLicense = ({
  itemType,
  license,
  licenseId,
  personalized,
}: LicenseProps) => {
  if (itemType !== SOFTWARE_LICENSE) {
    return null;
  }
  if (personalized) {
    return (
      <PersonalizedWrapper>
        <FormattedMessage {...messages[`my-${licenseId}`]} />
      </PersonalizedWrapper>
    );
  }

  const message = licenseId ? messages[licenseId] : null;

  return (
    <Wrapper>{message ? <FormattedMessage {...message} /> : license}</Wrapper>
  );
};

export default ProductLicense;
