import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { Flex } from '@theme-ui/components';
import cookie from 'js-cookie';
import qs from 'query-string';

import Loader from '@app/components/Loader';
import useMenu from '@app/hooks/useMenu';
import type { UseMenuHookOut } from '@app/hooks/useMenu/types';
import theme from '@app/styles/theme';

import ProductFinder from './ProductFinder';
import type { FinderState } from './url';
import { DEFAULT_SORTING_VALUE } from './url';

const DEFAULT_MENU_VARIANT = 'default';
const MENU_VARIANT_COOKIE = 'catalog_menu_variant';

const ProductFinderPage = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { language, url } = useParams<{ language: string; url: string }>();

  const { loading, ...menuConfig } = useMenu(
    cookie.get(MENU_VARIANT_COOKIE) || DEFAULT_MENU_VARIANT,
  );

  if (loading) {
    return (
      <Flex py={4} sx={{ justifyContent: 'center' }}>
        <Loader color={theme.colors.lighterBlue} size={30} />
      </Flex>
    );
  }

  const getPathFromSearchState = (newSearchState: FinderState) => {
    const { sorting, ...newSearchStateWithoutSorting } = newSearchState;
    const query = {
      ...newSearchStateWithoutSorting,
      ...(sorting !== DEFAULT_SORTING_VALUE && { sorting }),
    };

    return decodeURIComponent(
      generatePath(path, {
        language,
        url: qs.stringifyUrl({ url, query }),
      }),
    );
  };

  const updateUrl = (newSearchState: FinderState) => {
    history.push(getPathFromSearchState(newSearchState));
  };

  return (
    <ProductFinder
      onSearch={updateUrl}
      {...(menuConfig as Omit<UseMenuHookOut, 'loading'>)}
    />
  );
};

export default ProductFinderPage;
