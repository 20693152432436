import { Text } from '@theme-ui/components';
import styled from '@emotion/styled';

import theme from '../../../styles/theme';
import { rem } from '../../../styles/theme/layout';

export const Wrapper = styled(Text)`
  font-size: ${theme.fontSizes[0]};
  min-height: ${rem(16)};
  padding-bottom: ${rem(2)};
  display: block;
`;

export const PersonalizedWrapper = styled(Wrapper)`
  background: ${theme.colors.productTileAcidGreen};
  color: ${theme.colors.white};
  padding: ${rem(2)} ${rem(4)};
  text-transform: capitalize;
  font-size: ${rem(10)};
  position: relative;
  top: ${rem(2)};
  font-weight: 500;
  border-radius: ${rem(2)};
  display: inline-block;
`;

export default { Wrapper, PersonalizedWrapper };
