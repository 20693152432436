import {
  CLOSE_VIDEO_OVERLAY,
  FETCH_VIDEO_LOADING,
  FETCH_VIDEO_SUCCESS,
  OPEN_VIDEO_OVERLAY,
  SET_VIDEO_ERROR,
  SET_VIDEO_ID,
} from '../actions/videoOverlay.actions';

const initialState = {
  isOpen: false,
  isLoading: false,
  error: null,
  id: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_VIDEO_OVERLAY:
      return {
        ...state,
        isOpen: true,
      };

    case CLOSE_VIDEO_OVERLAY:
      return {
        ...state,
        isOpen: false,
      };

    case FETCH_VIDEO_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_VIDEO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    case SET_VIDEO_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case SET_VIDEO_ID:
      return {
        ...state,
        id: action.payload,
      };

    default:
      return state;
  }
};
