import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import { fadeIn } from '../../styles/animations';
import theme from '../../styles/theme';
import { screenSmall } from '../../styles/theme/breakpoints';
import { rem } from '../../styles/theme/layout';

const wrapper: SerializedStyles = css`
  max-width: ${theme.layout.containerMaxWidth};
  margin: 0 auto;
`;

const header: SerializedStyles = css`
  position: relative;
  z-index: 2;
  padding: ${rem(16)} ${rem(20)};
`;

const contentWrapper: SerializedStyles = css`
  animation: ${fadeIn} 1s;
`;

const productSort: SerializedStyles = css`
  @media screen and (max-width: ${screenSmall}) {
    display: none;
  }
`;

export default {
  wrapper,
  header,
  productSort,
  contentWrapper,
};
