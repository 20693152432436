/**
 * <AudioPlayer />
 * app/components/AudioPlayer/AudioPlayer.tsx
 */

const audioPlayerBg = '#2a2c2e';

export default {
  audioPlayerBg,
};
