import { css, Global, withTheme } from '@emotion/react';

import type { AppTheme } from './theme';

const GlobalFormStyles = ({ theme }: { theme: AppTheme }) => (
  <Global
    styles={css`
      /* Input elements, checkbox type */
      .checkbox label {
        height: 25px;
        left: 0;
        width: 25px;
      }

      .checkbox label::after {
        background: transparent;
        border: 3px solid #333;
        border-right: 0;
        border-top: 0;
        content: '';
        height: 6px;
        left: 6px;
        opacity: 0;
        position: absolute;
        top: 6px;
        transform: rotate(-45deg);
        width: 11px;
      }

      .checkbox input[type='checkbox'] {
        left: 5px;
        position: relative;
      }

      .checkbox input[type='checkbox']:checked + label::after {
        opacity: 1;
      }

      /* Input (text) elements */
      .input .text label {
        color: ${theme.colors.labelGray};
        top: 50%;
        transform: translateY(-50%) translateX(1.3rem);
        transition: transform 0.2s ease;
      }

      /*	1. Moves label text above the input
  *      area when active or filled.
  */
      .input .active.text label,
      .input .filled.text label {
        top: 50%;
        font-size: 70%;
        transform: translateY(-170%) translateX(1.3rem); /* 1 */
      }

      .input input::placeholder {
        color: transparent;
      }

      .input input:required {
        box-shadow: none;
      }

      .input select {
        color: ${theme.colors.labelGray};
      }

      .input input:focus,
      .input select:focus {
        border-color: transparent;
        box-shadow: none;
        outline-style: none;
      }

      /*	1. Hide default dropdown icon in IE10/11
  *   2. Ensure the this mask doesn't interfere with clicking dropdown.
  */
      .input .select::before {
        background-color: ${theme.colors.white}; /* 1 */
        content: '';
        height: 100%;
        position: absolute;
        right: 0.5rem;
        width: 2rem;
        z-index: -1; /* 2 */
      }

      /*	1. Make the dropdown icon clickable.
  *
  */
      .input .select::after {
        border-bottom: 3px solid ${theme.colors.labelGray};
        border-left: 3px solid ${theme.colors.labelGray};
        content: '';
        height: 6px;
        position: absolute;
        right: 1rem;
        top: 45%;
        transform: rotate(-45deg) translateY(-50%);
        width: 6px;
        z-index: 1; /* 1 */
      }

      /* HTML5 Boilerplate accessible hidden styles
  *  Fixes issues on IE10/11 too.
  */
      input[type='radio'] {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
    `}
  />
);

export default withTheme(GlobalFormStyles);
