import { defineMessages } from 'react-intl';

export default defineMessages({
  body: {
    id: 'components.protectedContent.body',
    defaultMessage:
      'PLEASE {loginLink} TO SEE IF YOU QUALIFY FOR KOMPLETE UPDATES OR UPGRADES',
  },
  linkText: {
    id: 'components.protectedContent.linkText',
    defaultMessage: 'LOGIN',
  },
});
