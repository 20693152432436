import { gql } from '@apollo/client';

const CategoryBannerQuery = gql`
  query CategoryBannerByIdQuery($categoryBannerId: ID!, $languages: [String]) {
    content {
      category_banners_by_id(id: $categoryBannerId) {
        id
        theme
        text_alignement
        translations(
          filter: { languages_code: { code: { _in: $languages } } }
        ) {
          languages_code {
            code
          }
          body
          countdown_template
        }
        background_mobile {
          full_url
        }
        background_desktop {
          full_url
        }
        countdown_date
      }
    }
  }
`;

export default CategoryBannerQuery;
