// adapted from https://github.com/beautifulinteractions/beautiful-react-hooks/blob/master/src/useMediaQuery.js
import { useEffect, useMemo, useState } from 'react';

interface UseMediaQueryOptions {
  // Match state for server render
  ssrMatch: boolean;
}

const useMediaQuery = (
  query: string,
  options: UseMediaQueryOptions = { ssrMatch: false },
): boolean => {
  const mediaQueryList = useMemo(() => getMediaQueryList(query), [query]);

  const [isMatch, setIsMatch] = useState(
    mediaQueryList ? mediaQueryList.matches : options.ssrMatch,
  );

  useEffect(() => {
    if (!mediaQueryList) return;

    const changeHandler = () => setIsMatch(Boolean(mediaQueryList.matches));

    // handle addListener deprecation, see: https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener
    // decide if can drop opera
    if (typeof mediaQueryList.addEventListener === 'function') {
      mediaQueryList.addEventListener('change', changeHandler);
    } else if (typeof mediaQueryList.addListener === 'function') {
      mediaQueryList.addListener(changeHandler);
    }

    changeHandler();

    return () => {
      if (typeof mediaQueryList.removeEventListener === 'function') {
        mediaQueryList.removeEventListener('change', changeHandler);
      } else if (typeof mediaQueryList.addListener === 'function') {
        mediaQueryList.removeListener(changeHandler);
      }
    };
  }, [mediaQueryList]);

  return isMatch;
};

export default useMediaQuery;

const isMatchMediaSupported = () =>
  typeof window !== 'undefined' && 'matchMedia' in window;

const getMediaQueryList = (query: string): MediaQueryList | null =>
  isMatchMediaSupported() ? window.matchMedia(query) : null;
