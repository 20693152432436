import { Box, Flex } from '@theme-ui/components';
import { useTheme } from '@emotion/react';

import { fadeIn } from '@app/styles/animations';
import { rem } from '@app/styles/theme/layout';

interface TooltipProps {
  content: string;
  position: TooltipPosition;
}

export enum TooltipPosition {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
}

const Tooltip = ({ content, position, ...props }: TooltipProps) => {
  const theme = useTheme();

  return (
    <Flex
      {...props}
      sx={{
        animation: `${fadeIn} 0.5s`,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: rem(10),
          height: rem(10),
          backgroundColor: theme.colors.lighterBlue,
          ...getStylesByPosition(position),
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          padding: `${rem(9)} ${rem(10)}`,
          top: 11,
          backgroundColor: theme.colors.lighterBlue,
          color: theme.colors.white,
          fontWeight: '500',
          borderRadius: rem(2),
          whiteSpace: 'nowrap',
          fontSize: rem(12),
        }}
      >
        {content}
      </Box>
    </Flex>
  );
};

export default Tooltip;

const getStylesByPosition = (position: TooltipPosition) => {
  switch (position) {
    case TooltipPosition.RIGHT:
      return { transform: 'rotate(45deg)', top: 0, right: 1 };
    case TooltipPosition.LEFT:
      return { transform: 'rotate(45deg)', top: 0, right: -206 };
    case TooltipPosition.TOP:
      return { transform: 'rotate(45deg)', top: 15, left: 100 };
    case TooltipPosition.BOTTOM:
      return { transform: 'rotate(45deg)', top: -14, left: 100 };
    default:
      return {};
  }
};
