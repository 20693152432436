import { useParams } from 'react-router-dom';
import { useQuery, type ApolloError } from '@apollo/client';

import { getTranslationWithFallback } from '@app/helpers/translations';
import useDirectusQueryLanguages from '@app/hooks/useDirectusQueryLanguages';
import useLocationSearch from '@app/hooks/useLocationSearch';
import { DIRECTUS_SUPER_CLIENT_URL_KEY } from '@config/common.config';

import type {
  CatalogContentPageById,
  CatalogContentPageByIdVariables,
} from './__generated__/CatalogContentPageById';
import CATALOG_CONTENT_PAGE_QUERY from './CatalogContentPage.query';

interface CtaButton {
  link: string;
  text: string;
}

export interface ProductsSection {
  content: string | null;
  skus: string[];
  ctaButtons: Array<CtaButton>;
}

type FeaturedProductSections = Array<ProductsSection>;

interface ContentPage {
  featuredProductSections: FeaturedProductSections;
}

interface UseContentPageProps {
  loading?: boolean;
  error?: ApolloError;
  contentPage?: ContentPage | null;
}

const useContentPage = (contentPageId: string): UseContentPageProps => {
  const directusSuperClientToken = useLocationSearch(
    DIRECTUS_SUPER_CLIENT_URL_KEY,
  );
  const { language } = useParams<{ language: string }>();
  const languages = useDirectusQueryLanguages();

  const { data, error, loading } = useQuery<
    CatalogContentPageById,
    CatalogContentPageByIdVariables
  >(CATALOG_CONTENT_PAGE_QUERY, {
    variables: { id: contentPageId, languages },
    context: { directusSuperClientToken },
  });

  if (!data) {
    return { error, loading };
  }

  const contentPage = data?.content?.catalog_content_pages_by_id;

  if (!contentPage) {
    return { contentPage: null };
  }

  const featuredProductSectionsData =
    contentPage.featured_product_sections || [];

  const featuredProductSections =
    featuredProductSectionsData.reduce<FeaturedProductSections>((acc, item) => {
      const section = item?.featured_product_sections_id;

      if (!section) {
        return acc;
      }

      const content = section.content
        ? getTranslationWithFallback<string>({
            translations: section.content,
            language,
            key: 'body',
          })
        : null;

      const ctaButtons =
        section.cta_buttons?.reduce<Array<CtaButton>>((acc, item) => {
          const button = item?.cta_buttons_id;
          if (!button?.link || !button?.translations) {
            return acc;
          }
          return [
            ...acc,
            {
              link: button.link,
              text: getTranslationWithFallback<string>({
                translations: button.translations,
                language,
                key: 'label',
              }),
            },
          ];
        }, []) || [];

      const skus =
        section.products?.reduce<Array<string>>((acc, product) => {
          const sku = product?.products_id?.sku;
          if (sku) {
            return [...acc, sku];
          }
          return acc;
        }, []) || [];

      return [
        ...acc,
        {
          content,
          ctaButtons,
          skus,
        },
      ];
    }, []);

  return {
    contentPage: {
      featuredProductSections: featuredProductSections,
    },
  };
};

export default useContentPage;
