import { Image } from '@theme-ui/components';
import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { screenSmall } from '../../../styles/theme/breakpoints';
import { rem } from '../../../styles/theme/layout';

export const IMAGE_STYLE_FIXED = 'fixed';
export const layoutWidth = ['100%', `${rem(620)}`, `${rem(920)}`];

const setContentStyle = (style: string): SerializedStyles =>
  CONTENT_STYLE_HASH[style] || null;

const contentStyleLarge: SerializedStyles = css`
  font-size: ${rem(24)};
  line-height: 1.5;

  h2,
  h3 {
    font-size: ${rem(52)};
    line-height: 1.15;
  }

  @media screen and (max-width: ${screenSmall}) {
    font-size: ${rem(22)};

    h2,
    h3 {
      font-size: ${rem(42)};
      margin-bottom: ${rem(10)};
      margin-top: ${rem(10)};
    }
  }
`;

const contentStyleFootNote: SerializedStyles = css`
  h2,
  h3 {
    font-size: ${rem(24)};
  }

  p {
    font-style: italic;
    font-size: ${rem(13)};
  }
`;

const CONTENT_STYLE_HASH = {
  large: contentStyleLarge,
  'foot-note': contentStyleFootNote,
};

export const contentStyles = (
  textStyle: keyof typeof CONTENT_STYLE_HASH | null,
) => css`
  text-align: center;
  font-size: ${rem(16)};
  line-height: 1.5;
  margin: 0 auto;

  h2,
  h3 {
    font-size: ${rem(32)};
    margin-bottom: ${rem(20)};
  }

  @media screen and (max-width: ${screenSmall}) {
    h2,
    h3 {
      margin-bottom: ${rem(10)};
      margin-top: ${rem(10)};
    }
  }

  ${textStyle && setContentStyle(textStyle)}
`;

export const MainImage = styled(Image)`
  display: block;
  margin: 0 auto;
`;
