import { defineMessages } from 'react-intl';

export default defineMessages({
  add: {
    id: 'components.QuickAddToCartButton.add',
    defaultMessage: 'Add to cart',
  },
  remove: {
    id: 'components.QuickAddToCartButton.remove',
    defaultMessage: 'Remove from cart',
  },
});
