import { Box, Flex } from '@theme-ui/components';

import { PRODUCT_LIST_GRID } from '../ProductList/ProductList';
import ProductPlaceholder from './ProductPlaceholder';

const ProductPlaceholders = ({ count = 8 }: { count: number }) => (
  <Flex
    data-testid="product-placeholders"
    sx={{ justifyContent: 'space-between', flexWrap: 'wrap' }}
  >
    {[...Array(count).keys()].map((_, index) => (
      <Box key={index} px={[0, 2]} py={3} sx={{ width: PRODUCT_LIST_GRID }}>
        <ProductPlaceholder />
      </Box>
    ))}
  </Flex>
);

export default ProductPlaceholders;
