export interface FinderState {
  sorting?: string;
  searching?: string;
  page?: string;
}

const trimSlashes = (str: string): string => str.replace(/^\/|\/$/, '');

export const generateRoute = (chunks: string[] = []): string => {
  return trimSlashes(chunks.join('/'));
};

export const parseRoute = (url = ''): string[] => {
  return trimSlashes(url).split('/');
};

export const DEFAULT_SORTING_VALUE = 'relevance';
