/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Magento2_CartUserInputErrorType {
  INSUFFICIENT_STOCK = "INSUFFICIENT_STOCK",
  NOT_SALABLE = "NOT_SALABLE",
  PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND",
  UNDEFINED = "UNDEFINED",
}

/**
 * The list of countries codes
 */
export enum Magento2_CountryCodeEnum {
  AD = "AD",
  AE = "AE",
  AF = "AF",
  AG = "AG",
  AI = "AI",
  AL = "AL",
  AM = "AM",
  AN = "AN",
  AO = "AO",
  AQ = "AQ",
  AR = "AR",
  AS = "AS",
  AT = "AT",
  AU = "AU",
  AW = "AW",
  AX = "AX",
  AZ = "AZ",
  BA = "BA",
  BB = "BB",
  BD = "BD",
  BE = "BE",
  BF = "BF",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BJ = "BJ",
  BL = "BL",
  BM = "BM",
  BN = "BN",
  BO = "BO",
  BR = "BR",
  BS = "BS",
  BT = "BT",
  BV = "BV",
  BW = "BW",
  BY = "BY",
  BZ = "BZ",
  CA = "CA",
  CC = "CC",
  CD = "CD",
  CF = "CF",
  CG = "CG",
  CH = "CH",
  CI = "CI",
  CK = "CK",
  CL = "CL",
  CM = "CM",
  CN = "CN",
  CO = "CO",
  CR = "CR",
  CU = "CU",
  CV = "CV",
  CX = "CX",
  CY = "CY",
  CZ = "CZ",
  DE = "DE",
  DJ = "DJ",
  DK = "DK",
  DM = "DM",
  DO = "DO",
  DZ = "DZ",
  EC = "EC",
  EE = "EE",
  EG = "EG",
  EH = "EH",
  ER = "ER",
  ES = "ES",
  ET = "ET",
  FI = "FI",
  FJ = "FJ",
  FK = "FK",
  FM = "FM",
  FO = "FO",
  FR = "FR",
  GA = "GA",
  GB = "GB",
  GD = "GD",
  GE = "GE",
  GF = "GF",
  GG = "GG",
  GH = "GH",
  GI = "GI",
  GL = "GL",
  GM = "GM",
  GN = "GN",
  GP = "GP",
  GQ = "GQ",
  GR = "GR",
  GS = "GS",
  GT = "GT",
  GU = "GU",
  GW = "GW",
  GY = "GY",
  HK = "HK",
  HM = "HM",
  HN = "HN",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  IC = "IC",
  ID = "ID",
  IE = "IE",
  IL = "IL",
  IM = "IM",
  IN = "IN",
  IO = "IO",
  IQ = "IQ",
  IR = "IR",
  IS = "IS",
  IT = "IT",
  JE = "JE",
  JM = "JM",
  JO = "JO",
  JP = "JP",
  KE = "KE",
  KG = "KG",
  KH = "KH",
  KI = "KI",
  KM = "KM",
  KN = "KN",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KY = "KY",
  KZ = "KZ",
  LA = "LA",
  LB = "LB",
  LC = "LC",
  LI = "LI",
  LK = "LK",
  LR = "LR",
  LS = "LS",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  LY = "LY",
  MA = "MA",
  MC = "MC",
  MD = "MD",
  ME = "ME",
  MF = "MF",
  MG = "MG",
  MH = "MH",
  MK = "MK",
  ML = "ML",
  MM = "MM",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MQ = "MQ",
  MR = "MR",
  MS = "MS",
  MT = "MT",
  MU = "MU",
  MV = "MV",
  MW = "MW",
  MX = "MX",
  MY = "MY",
  MZ = "MZ",
  NA = "NA",
  NC = "NC",
  NE = "NE",
  NF = "NF",
  NG = "NG",
  NI = "NI",
  NL = "NL",
  NO = "NO",
  NP = "NP",
  NR = "NR",
  NU = "NU",
  NZ = "NZ",
  OM = "OM",
  PA = "PA",
  PE = "PE",
  PF = "PF",
  PG = "PG",
  PH = "PH",
  PK = "PK",
  PL = "PL",
  PM = "PM",
  PN = "PN",
  PR = "PR",
  PS = "PS",
  PT = "PT",
  PW = "PW",
  PY = "PY",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RS = "RS",
  RU = "RU",
  RW = "RW",
  SA = "SA",
  SB = "SB",
  SC = "SC",
  SD = "SD",
  SE = "SE",
  SG = "SG",
  SH = "SH",
  SI = "SI",
  SJ = "SJ",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SN = "SN",
  SO = "SO",
  SR = "SR",
  SS = "SS",
  ST = "ST",
  SV = "SV",
  SY = "SY",
  SZ = "SZ",
  TC = "TC",
  TD = "TD",
  TF = "TF",
  TG = "TG",
  TH = "TH",
  TJ = "TJ",
  TK = "TK",
  TL = "TL",
  TM = "TM",
  TN = "TN",
  TO = "TO",
  TR = "TR",
  TT = "TT",
  TV = "TV",
  TW = "TW",
  TZ = "TZ",
  UA = "UA",
  UG = "UG",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VA = "VA",
  VC = "VC",
  VE = "VE",
  VG = "VG",
  VI = "VI",
  VN = "VN",
  VU = "VU",
  WF = "WF",
  WS = "WS",
  YE = "YE",
  YT = "YT",
  ZA = "ZA",
  ZM = "ZM",
  ZW = "ZW",
}

/**
 * The list of available currency codes.
 */
export enum Magento2_CurrencyEnum {
  AED = "AED",
  AFN = "AFN",
  ALL = "ALL",
  AMD = "AMD",
  ANG = "ANG",
  AOA = "AOA",
  ARS = "ARS",
  AUD = "AUD",
  AWG = "AWG",
  AZM = "AZM",
  AZN = "AZN",
  BAM = "BAM",
  BBD = "BBD",
  BDT = "BDT",
  BGN = "BGN",
  BHD = "BHD",
  BIF = "BIF",
  BMD = "BMD",
  BND = "BND",
  BOB = "BOB",
  BRL = "BRL",
  BSD = "BSD",
  BTN = "BTN",
  BUK = "BUK",
  BWP = "BWP",
  BYN = "BYN",
  BZD = "BZD",
  CAD = "CAD",
  CDF = "CDF",
  CHE = "CHE",
  CHF = "CHF",
  CHW = "CHW",
  CLP = "CLP",
  CNY = "CNY",
  COP = "COP",
  CRC = "CRC",
  CUP = "CUP",
  CVE = "CVE",
  CZK = "CZK",
  DJF = "DJF",
  DKK = "DKK",
  DOP = "DOP",
  DZD = "DZD",
  EEK = "EEK",
  EGP = "EGP",
  ERN = "ERN",
  ETB = "ETB",
  EUR = "EUR",
  FJD = "FJD",
  FKP = "FKP",
  GBP = "GBP",
  GEK = "GEK",
  GEL = "GEL",
  GHS = "GHS",
  GIP = "GIP",
  GMD = "GMD",
  GNF = "GNF",
  GQE = "GQE",
  GTQ = "GTQ",
  GYD = "GYD",
  HKD = "HKD",
  HNL = "HNL",
  HRK = "HRK",
  HTG = "HTG",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  INR = "INR",
  IQD = "IQD",
  IRR = "IRR",
  ISK = "ISK",
  JMD = "JMD",
  JOD = "JOD",
  JPY = "JPY",
  KES = "KES",
  KGS = "KGS",
  KHR = "KHR",
  KMF = "KMF",
  KPW = "KPW",
  KRW = "KRW",
  KWD = "KWD",
  KYD = "KYD",
  KZT = "KZT",
  LAK = "LAK",
  LBP = "LBP",
  LKR = "LKR",
  LRD = "LRD",
  LSL = "LSL",
  LSM = "LSM",
  LTL = "LTL",
  LVL = "LVL",
  LYD = "LYD",
  MAD = "MAD",
  MDL = "MDL",
  MGA = "MGA",
  MKD = "MKD",
  MMK = "MMK",
  MNT = "MNT",
  MOP = "MOP",
  MRO = "MRO",
  MUR = "MUR",
  MVR = "MVR",
  MWK = "MWK",
  MXN = "MXN",
  MYR = "MYR",
  MZN = "MZN",
  NAD = "NAD",
  NGN = "NGN",
  NIC = "NIC",
  NOK = "NOK",
  NPR = "NPR",
  NZD = "NZD",
  OMR = "OMR",
  PAB = "PAB",
  PEN = "PEN",
  PGK = "PGK",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  PYG = "PYG",
  QAR = "QAR",
  RHD = "RHD",
  ROL = "ROL",
  RON = "RON",
  RSD = "RSD",
  RUB = "RUB",
  RWF = "RWF",
  SAR = "SAR",
  SBD = "SBD",
  SCR = "SCR",
  SDG = "SDG",
  SEK = "SEK",
  SGD = "SGD",
  SHP = "SHP",
  SKK = "SKK",
  SLL = "SLL",
  SOS = "SOS",
  SRD = "SRD",
  STD = "STD",
  SVC = "SVC",
  SYP = "SYP",
  SZL = "SZL",
  THB = "THB",
  TJS = "TJS",
  TMM = "TMM",
  TND = "TND",
  TOP = "TOP",
  TRL = "TRL",
  TRY = "TRY",
  TTD = "TTD",
  TWD = "TWD",
  TZS = "TZS",
  UAH = "UAH",
  UGX = "UGX",
  USD = "USD",
  UYU = "UYU",
  UZS = "UZS",
  VEB = "VEB",
  VEF = "VEF",
  VND = "VND",
  VUV = "VUV",
  WST = "WST",
  XCD = "XCD",
  XOF = "XOF",
  XPF = "XPF",
  YER = "YER",
  YTL = "YTL",
  ZAR = "ZAR",
  ZMK = "ZMK",
  ZWD = "ZWD",
}

/**
 * Specifies the gift card type.
 */
export enum Magento2_GiftCardTypeEnum {
  COMBINED = "COMBINED",
  PHYSICAL = "PHYSICAL",
  VIRTUAL = "VIRTUAL",
}

/**
 * This enumeration states whether a product stock status is in stock or out of stock
 */
export enum Magento2_ProductStockStatus {
  IN_STOCK = "IN_STOCK",
  OUT_OF_STOCK = "OUT_OF_STOCK",
}

export enum SortBy {
  date_desc = "date_desc",
  price_asc = "price_asc",
  price_desc = "price_desc",
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SubscriptionUpdateStatuses {
  active = "active",
  cancel = "cancel",
}

export interface Comparison {
  key?: string | null;
  value?: any | null;
}

export interface Condition {
  eq?: Comparison | null;
  neq?: Comparison | null;
  gt?: Comparison | null;
  lt?: Comparison | null;
  gteq?: Comparison | null;
  lteq?: Comparison | null;
  in?: InComparison | null;
  nin?: NinComparison | null;
  and?: (Condition | null)[] | null;
  or?: (Condition | null)[] | null;
  not?: Condition | null;
}

export interface InComparison {
  key?: string | null;
  value?: (any | null)[] | null;
}

/**
 * Defines an item to be added to the cart.
 */
export interface Magento2_CartItemInput {
  entered_options?: (Magento2_EnteredOptionInput | null)[] | null;
  parent_sku?: string | null;
  quantity: number;
  selected_options?: (string | null)[] | null;
  sku: string;
}

/**
 * A single item to be updated.
 */
export interface Magento2_CartItemUpdateInput {
  cart_item_id?: number | null;
  cart_item_uid?: string | null;
  customizable_options?: (Magento2_CustomizableOptionInput | null)[] | null;
  gift_message?: Magento2_GiftMessageInput | null;
  gift_wrapping_id?: string | null;
  quantity?: number | null;
}

/**
 * Specifies the attribute code and value of a customer attribute.
 */
export interface Magento2_CustomerAddressAttributeInput {
  attribute_code: string;
  value: string;
}

/**
 * Contains details about a billing or shipping address.
 */
export interface Magento2_CustomerAddressInput {
  city?: string | null;
  company?: string | null;
  country_code?: Magento2_CountryCodeEnum | null;
  country_id?: Magento2_CountryCodeEnum | null;
  custom_attributes?: (Magento2_CustomerAddressAttributeInput | null)[] | null;
  default_billing?: boolean | null;
  default_shipping?: boolean | null;
  fax?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  middlename?: string | null;
  postcode?: string | null;
  prefix?: string | null;
  region?: Magento2_CustomerAddressRegionInput | null;
  street?: (string | null)[] | null;
  suffix?: string | null;
  telephone?: string | null;
  vat_id?: string | null;
}

/**
 * Defines the customer's state or province.
 */
export interface Magento2_CustomerAddressRegionInput {
  region?: string | null;
  region_code?: string | null;
  region_id?: number | null;
}

/**
 * Defines a customizable option.
 */
export interface Magento2_CustomizableOptionInput {
  id?: number | null;
  value_string: string;
}

/**
 * Defines a customer-entered option.
 */
export interface Magento2_EnteredOptionInput {
  uid: string;
  value: string;
}

/**
 * Defines a gift message.
 */
export interface Magento2_GiftMessageInput {
  from: string;
  message: string;
  to: string;
}

export interface NinComparison {
  key?: string | null;
  value?: (any | null)[] | null;
}

export interface SortOrder {
  field: string;
  direction: SortDirection;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
