import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

import { useGetCartId } from '@app/context/CartIdProvider/CartIdProvider';
import { CART_UPDATE_RESULT_FRAGMENT } from '@app/pages/Cart/Cart.queries';

import type {
  RemoveCartItemMutation,
  RemoveCartItemMutationVariables,
} from './__generated__/RemoveCartItemMutation';
import useAlerts from './useAlerts';

export const REMOVE_CART_ITEM_MUTATION = gql`
  ${CART_UPDATE_RESULT_FRAGMENT}

  mutation RemoveCartItemMutation($cartId: String!, $cartItemId: ID!) {
    magento2 {
      removeItemFromCart(
        input: { cart_id: $cartId, cart_item_uid: $cartItemId }
      ) {
        cart {
          ...CartUpdateResult
        }
      }
    }
  }
`;

export const useRemoveCartItem = () => {
  const { cartId } = useGetCartId();
  const { clearAlerts } = useAlerts([]);

  const [removeCartItem, removeCartItemResult] = useMutation<
    RemoveCartItemMutation,
    RemoveCartItemMutationVariables
  >(REMOVE_CART_ITEM_MUTATION);

  const removeItem = useCallback(
    async (itemId) => {
      if (!cartId) return;

      await removeCartItem({
        variables: {
          cartId,
          cartItemId: itemId,
        },
      });

      const localItems = localStorage.getItem('localItemsCart');

      if (localItems) {
        const localItemsArray: string[] = JSON.parse(localItems);
        localItemsArray.splice(localItemsArray.indexOf(itemId), 1);
        localStorage.setItem('localItemsCart', JSON.stringify(localItemsArray));
      }

      clearAlerts();
    },
    [cartId, clearAlerts, removeCartItem],
  );

  return {
    removeItem,
    data: removeCartItemResult,
    loading: removeCartItemResult?.loading,
    error: removeCartItemResult?.error,
  };
};
