import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import { rem } from '@app/styles/theme/layout';

import theme from '../../styles/theme';

const wrapper: SerializedStyles = css`
  position: relative;
  cursor: pointer;
`;

const bubble: SerializedStyles = css`
  position: absolute;
  padding: 1px 3px;
  color: white;
  font-size: ${rem(11)};
  border-radius: 3px;
  line-height: 1.3;
  background-color: ${theme.colors.lightBlue};
  top: -8px;
  left: 8px;
`;

export default {
  wrapper,
  bubble,
};
