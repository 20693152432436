import type { RouteComponentProps } from 'react-router-dom';
import type { ActionCreator } from 'redux';

const SYNC_ROUTE = 'SYNC_ROUTE';
const SET_ROUTE = 'SET_ROUTE';
const SET_URL = 'SET_URL';
const SET_PAGE_TITLE = 'SET_PAGE_TITLE';

interface SyncRouteAction
  extends Pick<RouteComponentProps, 'match' | 'location'> {
  type: typeof SYNC_ROUTE;
}

const syncRoute: ActionCreator<SyncRouteAction> = (
  match: RouteComponentProps['match'],
  location: RouteComponentProps['location'],
) => ({ type: SYNC_ROUTE, match, location });

interface SetRouteAction {
  type: typeof SET_ROUTE;
  value: string;
  params?: Record<string, unknown>;
}

const setRoute: ActionCreator<SetRouteAction> = (
  value: string,
  params = {},
) => ({
  type: SET_ROUTE,
  value,
  params,
});

interface SetUrlAction {
  type: typeof SET_URL;
  value: string;
}

const setUrl: ActionCreator<SetUrlAction> = (value: string) => ({
  type: SET_URL,
  value,
});

interface SetPageTitleAction {
  type: typeof SET_PAGE_TITLE;
  value: string;
}

const setPageTitle: ActionCreator<SetPageTitleAction> = (value: string) => ({
  type: SET_PAGE_TITLE,
  value,
});

export type RoutesAction =
  | SyncRouteAction
  | SetUrlAction
  | SetRouteAction
  | SetPageTitleAction;

export {
  SYNC_ROUTE,
  SET_ROUTE,
  SET_URL,
  SET_PAGE_TITLE,
  syncRoute,
  setRoute,
  setUrl,
  setPageTitle,
};
