import type { FC } from 'react';
import { useEffect, useState } from 'react';
import Countdown from 'react-countdown';

interface CountDownProps {
  template: string;
  date: string;
}

const TIMESERVER_URL = 'https://time.akamai.com';

const CountDown: FC<CountDownProps> = ({ date, template }: CountDownProps) => {
  const [timeCorrection, setTimeCorrection] = useState(0);
  useEffect(() => {
    fetch(TIMESERVER_URL)
      .then((response) => response.text())
      .then((response) => Number.parseInt(response) * 1000)
      .then((serverTimestamp) => {
        const currentDate = Date.now();

        if (Math.abs(serverTimestamp - currentDate) > 0) {
          setTimeCorrection(serverTimestamp - currentDate);
        }
      });
  }, []);

  const countDownDate = new Date(`${date}Z`);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) return null;

    const formattedMessage = template
      .replace('{days}', days)
      .replace('{hours}', hours)
      .replace('{minutes}', minutes)
      .replace('{seconds}', seconds);

    return <span>{formattedMessage}</span>;
  };

  return (
    <Countdown
      date={countDownDate}
      now={() => Date.now() + timeCorrection}
      renderer={renderer}
    />
  );
};

export default CountDown;
