import { Route } from 'react-router-dom';

const Status = ({
  code = 200,
  children,
}: {
  code: number;
  children: React.ReactNode;
}) => (
  <Route
    render={({ staticContext }) => {
      if (staticContext) staticContext.statusCode = code;
      return children;
    }}
  />
);

export default Status;
