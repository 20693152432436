import { lazy } from 'react';

import DefaultLayout from '@app/components/DefaultLayout';
import NaAuthHandler from '@app/components/NaAuthHandler/NaAuthHandler';
import NotFound from '@app/components/NotFound';
import ProductFinder from '@app/pages/ProductFinder';
import ProductFinderLayout from '@app/pages/ProductFinder/ProductFinderLayout';

import { productFinderRoutes } from '../../config/routes.config';
import { routes as SHOP_ROUTES } from './shop';

const Pricing = lazy(
  () => import(/*webpackChunkName: 'Pricing' */ '@app/pages/Pricing/Pricing'),
);
const PricingLayout = lazy(
  () =>
    import(/*webpackChunkName: 'Pricing' */ '@app/pages/Pricing/PricingLayout'),
);
const WhatsNewForMe = lazy(
  () =>
    import(
      /*webpackChunkName: 'WhatsNewForMe' */ '@app/pages/WhatsNewForMe/WhatsNewForMe'
    ),
);
const NotFoundLayout = lazy(
  () =>
    import(
      /*webpackChunkName: 'NotFoundLayout' */ '@app/components/NotFound/NotFoundLayout'
    ),
);

const routes = [
  ...SHOP_ROUTES,
  {
    ...productFinderRoutes.productFinder,
    component: ProductFinder,
    layout: ProductFinderLayout,
  },
  {
    path: '/:language/pricing/:url_key',
    name: 'pricing',
    component: Pricing,
    layout: PricingLayout,
  },
  {
    path: '/:language/whats-new/:url_key',
    name: 'whats-new',
    component: WhatsNewForMe,
    layout: PricingLayout,
  },
  {
    path: '/:language/native-access/auth-handler/',
    name: 'na-auth-handler',
    component: NaAuthHandler,
  },
  {
    path: '*',
    name: 'notfound',
    component: NotFound,
    layout: NotFoundLayout,
  },
].map((route) => ({
  ...route,
  layout: route.layout || DefaultLayout,
}));

export default routes;
