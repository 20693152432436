import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Box, Flex, Text } from '@theme-ui/components';

import { useOnMount } from '@app/hooks/lifecycle';
import { rem } from '@app/styles/theme/layout';
import { APP_LANGUAGES_LABELS } from '@config/common.config';

import { UnstyledButton } from '../Button';
import messages from './Footer.messages';
import styles from './Footer.styles';

declare global {
  interface Window {
    OneTrust: {
      ToggleInfoDisplay: () => void;
    };
  }
}

const Footer = () => {
  const { language } = useParams<{ language: string }>();
  const location = useLocation();
  const history = useHistory();
  const LINKS = {
    legal: `https://www.native-instruments.com/${language}/company/legal-information/`,
    privacy: `https://www.native-instruments.com/${language}/company/legal-information/privacy-policy/`,
    contact: `https://www.native-instruments.com/${language}/company/contact-ni/`,
    faq: `https://www.native-instruments.com/${language}/shop-information/`,
  };
  const [isCookieConsentLoaded, setIsCookieConsentLoaded] = useState(false);

  useOnMount(() => {
    setIsCookieConsentLoaded(typeof window?.OneTrust !== 'undefined');
  });

  function changeLanguage(newLanguage = 'en') {
    history.push(location.pathname.replace(language, newLanguage));
    window.location.reload();
  }

  return (
    <Flex
      css={styles.wrapper}
      sx={{
        flexDirection: ['column', 'column', 'row'],
        textAlign: ['center', 'center', 'left'],
      }}
      as="footer"
    >
      <Text
        sx={{
          flexBasis: 0,
          flexGrow: 1,
          paddingLeft: [0, 0, rem(10)],
          paddingRight: [0, 0, rem(10)],
        }}
      >
        &copy;
        {new Date().getFullYear()} Native Instruments GmbH
      </Text>

      <Box sx={{ maxWidth: ['auto', 'auto', '40%'], textAlign: 'center' }}>
        <a css={styles.linkClass} href={LINKS.legal}>
          <FormattedMessage {...messages.legal} />
        </a>
        <a css={styles.linkClass} href={LINKS.privacy}>
          <FormattedMessage {...messages.privacy} />
        </a>
        <a css={styles.linkClass} href={LINKS.contact}>
          <FormattedMessage {...messages.contact} />
        </a>
        <a css={styles.linkClass} href={LINKS.faq}>
          <FormattedMessage {...messages.faq} />
        </a>

        {isCookieConsentLoaded && (
          <UnstyledButton
            css={styles.linkClass}
            onClick={window?.OneTrust?.ToggleInfoDisplay}
          >
            <FormattedMessage {...messages.manageCookie} />
          </UnstyledButton>
        )}
      </Box>

      <Box
        sx={{
          flexBasis: 0,
          flexGrow: 1,
          textAlign: ['center', 'center', 'right'],
          paddingRight: [0, 0, rem(10)],
          paddingLeft: [0, 0, rem(10)],
        }}
      >
        {Object.keys(APP_LANGUAGES_LABELS).map((key) => (
          <Text
            key={key}
            css={styles.linkClass}
            onClick={() => changeLanguage(key)}
          >
            {APP_LANGUAGES_LABELS[key]}
          </Text>
        ))}
      </Box>
    </Flex>
  );
};

export default Footer;
