import type { FC } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withApollo } from '@apollo/client/react/hoc';

import type { VideoOverlayActions } from '../../redux/actions/videoOverlay.actions';
import videoOverlayActions from '../../redux/actions/videoOverlay.actions';
import type { VideoOverlayTriggerProps } from './VideoOverlayTrigger';
import VideoOverlayTrigger from './VideoOverlayTrigger';

interface VideoDispatchers {
  dispatchers: VideoOverlayActions;
}

const dispatchToProps = (dispatch): VideoDispatchers => {
  return {
    dispatchers: bindActionCreators(videoOverlayActions, dispatch),
  };
};

export default compose(
  withApollo,
  connect(null, dispatchToProps),
)(VideoOverlayTrigger) as FC<Pick<VideoOverlayTriggerProps, 'videoId'>>;
