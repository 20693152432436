import type { FC } from 'react';
import { memo } from 'react';
import { FormattedNumber } from 'react-intl';
import styled from '@emotion/styled';

import { Magento2_CurrencyEnum } from '@__generated__/globalTypes';

/*
For formatting money only, BYO styles in use
*/

export interface FormattedMoneyProps {
  value?: number | null;
  currency?: keyof typeof Magento2_CurrencyEnum | string | null;
  // Needed to extend via @emotion/styled
  className?: string;
}

const FormattedMoney: FC<FormattedMoneyProps> = ({
  currency,
  value,
  className,
}) => (
  <Container className={className}>
    <FormattedNumber
      value={value || 0}
      style="currency"
      currency={currency || Magento2_CurrencyEnum.USD}
    />
  </Container>
);

export default memo(FormattedMoney);

const Container = styled.span`
  white-space: nowrap;
`;
