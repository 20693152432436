import { useSelector } from 'react-redux';
import { css } from '@emotion/react';

import type { RoutesState } from '@app/redux/reducers/routes';

import { SubHeader, SubHeaderTitle } from './Skeleton/Skeleton.styles';
import Footer from './Footer';
import Header from './Header';

const DefaultLayout = ({ children }: { children?: React.ReactNode }) => {
  const pageTitle = useSelector(
    (state: { routes: RoutesState }) => state.routes.pageTitle,
  );

  return (
    <div
      css={css`
        flex-direction: column;
        display: flex;
        min-height: 100vh;
      `}
    >
      <div
        css={css`
          background-color: #edf0f2;
          flex-direction: column;
          flex: 1 1 auto;
          min-width: 0;
          min-height: 0;
          display: flex;
        `}
      >
        <Header />
        <SubHeader>
          <SubHeaderTitle>{pageTitle || <span />}</SubHeaderTitle>
        </SubHeader>
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default DefaultLayout;
