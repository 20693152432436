import type { FC } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Flex, Text } from '@theme-ui/components';
import { useTheme } from '@emotion/react';

import type { GetProductsForFinder_getProducts_result_items_price as PriceType } from '@app/pages/ProductFinder/__generated__/GetProductsForFinder';
import { AllProductsLabel as TextLineThrough } from '@app/pages/ProductFinder/BundlePrice/BundlePrice.styles';
import {
  Price,
  Wrapper as PriceBoxWrapper,
} from '@app/pages/ProductFinder/PriceBox/styles';
import ProductLicense from '@app/pages/ProductFinder/ProductLicense/';
import { Wrapper as ProductLicenseWrapper } from '@app/pages/ProductFinder/ProductLicense/ProductLicense.styles';

import messages from './PriceToggle.messages';
import {
  PriceToggleText,
  PriceWrapper,
  SalePriceBox,
  UpdatePriceBox,
  YouSaveLabel,
} from './PriceToggle.styles';

interface PriceToggleProps {
  price: DeepOmit<PriceType, '__typename'>;
  pricingPageUrl: string;
}

const PriceToggle: FC<PriceToggleProps> = ({ price, pricingPageUrl }) => {
  const { min, main } = price;
  const [showUpdatePrice, setShowUpdatePrice] = useState<boolean>(false);
  const isPersonalized = min?.personalized || main?.personalized;
  const theme = useTheme();

  const currentPrice = showUpdatePrice && min ? min : main;

  if (currentPrice === null) {
    return null;
  }

  return (
    <Flex sx={{ justifyContent: 'space-between' }} data-testid="price-toggle">
      <PriceWrapper>
        <a href={pricingPageUrl}>
          <ProductLicenseWrapper>
            <FormattedMessage {...messages.regularPrice} />
          </ProductLicenseWrapper>
          <Price data-testid="price-toggle-regular-price">
            {showUpdatePrice && !currentPrice.minPrice ? (
              <Text sx={{ display: 'block' }} color={theme.colors.black}>
                {currentPrice.originalPrice}
              </Text>
            ) : (
              <TextLineThrough>
                <Text sx={{ display: 'block' }} color={theme.colors.black}>
                  {currentPrice.originalPrice}
                </Text>
              </TextLineThrough>
            )}
          </Price>
        </a>
      </PriceWrapper>
      {showUpdatePrice ? (
        <UpdatePriceBox
          onClick={() => setShowUpdatePrice(!showUpdatePrice)}
          data-testid="price-toggle-update-box"
        >
          <PriceBoxWrapper>
            {currentPrice.minPrice && (
              <>
                <ProductLicenseWrapper>
                  <FormattedMessage {...messages.updatePrice} />
                </ProductLicenseWrapper>
                <Price data-testid="price-toggle-update-price">
                  {currentPrice.minPrice}
                </Price>
              </>
            )}
            <PriceToggleText>
              <FormattedMessage {...messages.seeFullPrice} />
            </PriceToggleText>
          </PriceBoxWrapper>
        </UpdatePriceBox>
      ) : (
        <SalePriceBox
          onClick={() => setShowUpdatePrice(!showUpdatePrice)}
          data-testid="price-toggle-sale-box"
        >
          <PriceBoxWrapper>
            {isPersonalized ? (
              <Box mb={1}>
                <ProductLicense
                  itemType={currentPrice.itemType}
                  license={currentPrice.license}
                  licenseId={currentPrice.licenseId}
                  personalized={currentPrice.personalized}
                />
              </Box>
            ) : (
              <ProductLicenseWrapper>
                <FormattedMessage {...messages.salePrice} />
              </ProductLicenseWrapper>
            )}

            <Price data-testid="price-toggle-sale-price">
              {currentPrice.minPrice}
            </Price>
            <PriceToggleText>
              <FormattedMessage {...messages.seeUpdatePrice} />
            </PriceToggleText>
          </PriceBoxWrapper>
        </SalePriceBox>
      )}

      {currentPrice.savePrice && (
        <PriceWrapper>
          <a href={pricingPageUrl}>
            <YouSaveLabel>
              <FormattedMessage {...messages.youSave} />
            </YouSaveLabel>
            <Price data-testid="price-toggle-save-price">
              <Text
                sx={{ display: 'block' }}
                color={theme.colors.productTileAcidGreen}
              >
                {currentPrice.savePrice}
              </Text>
            </Price>
          </a>
        </PriceWrapper>
      )}
    </Flex>
  );
};

export default PriceToggle;
