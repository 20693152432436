import { gql } from '@apollo/client';

//@TODO [WWW-11705]: Migrate these queries/fragments to new codegen generator syntax
export const CART_TOTALS_FRAGMENT = gql`
  fragment CartTotals on Magento2_CartPrices {
    subtotalExcludingTax {
      currency
      value
    }
    subtotalIncludingTax {
      currency
      value
    }
    discounts {
      amount {
        currency
        value
      }
      label
    }
    appliedTaxes {
      label
      amount {
        currency
        value
      }
    }
    grandTotal {
      currency
      value
    }
  }
`;

export const CART_UPDATE_RESULT_FRAGMENT = gql`
  ${CART_TOTALS_FRAGMENT}

  fragment CartUpdateResult on Magento2_Cart {
    id
    isVirtual
    items {
      uid
      quantity
      prices {
        price {
          currency
          value
        }
        priceIncludingTax {
          currency
          value
        }
        rowTotalIncludingTax {
          currency
          value
        }
        rowTotal {
          currency
          value
        }
      }
      product {
        uid
        name
        sku
        sdbsId
        stockStatus
        smallImage {
          url
          label
          position
          disabled
        }
      }
      __typename
    }
    prices {
      ...CartTotals
    }
  }
`;

export const STORE_CONFIG_FRAGMENT = gql`
  fragment StoreConfig on Magento2_StoreConfig {
    storeCode
    taxSalesDisplayPrice
    taxSalesDisplaySubtotal
  }
`;

export const CART_QUERY = gql`
  ${CART_TOTALS_FRAGMENT}
  ${STORE_CONFIG_FRAGMENT}
  ${CART_UPDATE_RESULT_FRAGMENT}

  query CartQuery($cartId: String!) {
    magento2 {
      storeConfig {
        ...StoreConfig
      }
      cart(cart_id: $cartId) {
        ...CartUpdateResult
        appliedCoupons {
          code
        }
        appliedGiftCards {
          code
          appliedBalance {
            value
            currency
          }
        }
        shippingAddresses {
          selectedShippingMethod {
            amount {
              value
              currency
            }
            amountInclTax {
              value
              currency
            }
          }
          __typename
        }
      }
    }
  }
`;
