import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Flex, Image } from '@theme-ui/components';

import { getTranslationWithFallback } from '@app/helpers/translations';

import type {
  ContentElement2ColGrid_translations as ContentElement2ColGridTranslations,
  ContentElement2ColGrid as TContentElement2ColGrid,
} from '../ContentSection/__generated__/ContentElement2ColGrid';
import {
  ContentGrid,
  contentGridPadding,
  contentGridWidth,
  MainContent,
  Wrapper,
} from './ContentElement2ColGrid.style';

const SeamlessElement2ColGrid: FC<{
  data: TContentElement2ColGrid;
}> = ({
  data: {
    image,
    image_left: imageLeft,
    image_right: imageRight,
    translations,
    background,
  },
}) => {
  const { language } = useParams<{ language: string }>();

  const content = getTranslationWithFallback<
    ContentElement2ColGridTranslations['main_content']
  >({
    translations: translations || [],
    language,
    key: 'main_content',
  });

  const contentLeft = getTranslationWithFallback<
    ContentElement2ColGridTranslations['content_left']
  >({
    translations: translations || [],
    language,
    key: 'content_left',
  });

  const contentRight = getTranslationWithFallback<
    ContentElement2ColGridTranslations['content_right']
  >({
    translations: translations || [],
    language,
    key: 'content_right',
  });

  const imageSrc = image?.full_url;
  const imageLeftSrc = imageLeft?.full_url;
  const imageRightSrc = imageRight?.full_url;

  return (
    <Box
      backgroundColor={background}
      py={4}
      data-testid="seamless-element-2col-grid"
    >
      <Wrapper px={2} sx={{ width: ['100%', '80%'] }}>
        <MainContent mb={5}>
          {content && <Box dangerouslySetInnerHTML={{ __html: content }} />}
          {imageSrc && <Image src={imageSrc} />}
        </MainContent>
        <Flex sx={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
          {contentLeft && (
            <ContentGrid
              px={contentGridPadding}
              sx={{ width: contentGridWidth }}
            >
              {imageLeftSrc && <Image src={imageLeftSrc} />}
              <Box my={3} dangerouslySetInnerHTML={{ __html: contentLeft }} />
            </ContentGrid>
          )}
          {contentRight && (
            <ContentGrid
              px={contentGridPadding}
              sx={{ width: contentGridWidth }}
            >
              {imageRightSrc && <Image src={imageRightSrc} />}
              <Box my={3} dangerouslySetInnerHTML={{ __html: contentRight }} />
            </ContentGrid>
          )}
        </Flex>
      </Wrapper>
    </Box>
  );
};

export default SeamlessElement2ColGrid;
