import ReactDropdown, { type Group, type Option } from 'react-dropdown';
import { Box } from '@theme-ui/components';

import { rem } from '@app/styles/theme/layout';

import styles from './Dropdown.styles';

const arrow = () => <Box css={styles.arrow} />;

type DropdownProps = {
  options: (Group | Option | string)[];
  value: string;
  placeholder: string;
  onChange: (arg: string) => void;
  className: string;
  menuClassName: string;
};

const Dropdown = ({
  onChange,
  value,
  options,
  placeholder,
  className,
  menuClassName,
}: DropdownProps) => (
  <Box sx={{ position: 'relative', minWidth: rem(236) }}>
    <ReactDropdown
      css={styles.dropdownStyles}
      className={className}
      controlClassName="dropdown-control"
      placeholderClassName="dropdown-placeholder"
      menuClassName={
        menuClassName ? `dropdown-menu ${menuClassName}` : 'dropdown-menu'
      }
      arrowClassName="dropdown-arrow"
      arrowClosed={arrow()}
      arrowOpen={arrow()}
      options={options}
      onChange={({ value: newValue }) => onChange(newValue)}
      value={value}
      placeholder={placeholder}
    />
  </Box>
);

export default Dropdown;
