import { css } from '@emotion/react';

export const center = css`
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const ratioToPercentage = (arr: number[]): string => {
  return `${((Math.round(arr[1]) / Math.round(arr[0])) * 100).toFixed(2)}%`;
};

export default {
  center,
  ratioToPercentage,
};
