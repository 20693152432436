import { defineMessages } from 'react-intl';

export default defineMessages({
  youSave: {
    id: 'components.PriceToggle.youSave',
    defaultMessage: 'You Save',
  },
  regularPrice: {
    id: 'components.PriceToggle.regularPrice',
    defaultMessage: 'Regular Price',
  },
  salePrice: {
    id: 'components.PriceToggle.salePrice',
    defaultMessage: 'Sale Price',
  },
  updatePrice: {
    id: 'components.PriceToggle.updatePrice',
    defaultMessage: 'Update Price',
  },
  seeUpdatePrice: {
    id: 'components.PriceToggle.seeUpdatePrice',
    defaultMessage: 'See Update Price',
  },
  seeFullPrice: {
    id: 'components.PriceToggle.seeFullPrice',
    defaultMessage: 'See Full Price',
  },
});
