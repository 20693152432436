import { FormattedMessage } from 'react-intl';
import { Box, Text } from '@theme-ui/components';

import LoginLink from '../../../components/LoginLink';
import messages from './ProtectedContent.messages';
import styles from './ProtectedContent.styles';

const ProtectedContent = () => (
  <Box css={styles.wrapper}>
    <Text
      sx={{ display: 'block', textAlign: 'center' }}
      data-testid="protected-content-message"
    >
      <FormattedMessage
        {...messages.body}
        values={{
          loginLink: (
            <LoginLink>
              <span data-testid="protected-content-login-link">
                <FormattedMessage {...messages.linkText} />
              </span>
            </LoginLink>
          ),
        }}
      />
    </Text>
  </Box>
);

export default ProtectedContent;
