const success = '#0C582C';
const successBackground = 'rgba(38, 191, 102, 0.1);';
const error = '#9F2222';
const errorBackground = 'rgba(255, 124, 124, 0.1);';
const info = '#161819';
const infoBackground = 'rgba(10, 123, 195, 0.1)';
const infoBorder = '#0A7BC3';
const warning = '#644B19';
const warningBackground = 'rgba(255, 163, 0, 0.1);';

export default {
  success,
  successBackground,
  error,
  errorBackground,
  info,
  infoBackground,
  infoBorder,
  warning,
  warningBackground,
};
