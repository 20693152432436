import GlobalBaseStyles from './GlobalBaseStyles';
import GlobalFormStyles from './GlobalFormStyles';

const GlobalStyles = () => (
  <>
    <GlobalBaseStyles key="glob-base-styles" />
    <GlobalFormStyles key="glob-form-styles" />
  </>
);

export default GlobalStyles;
