import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

import logoImageLong from '../../assets/images/ni-logo-long.svg';
import logoImage from '../../assets/images/ni-logo.svg';
import theme from '../../styles/theme';
import { screenLarge, screenSmall } from '../../styles/theme/breakpoints';
import { rem } from '../../styles/theme/layout';

const flexItem: SerializedStyles = css`
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const header: SerializedStyles = css`
  height: ${theme.layout.headerHeight};
  width: 100%;
  background-color: ${theme.colors.white};
  position: sticky;
  top: 0;
  z-index: 9;
  box-shadow: 0 ${rem(1)} ${rem(1)} 0 ${theme.colors.headerShadowBottom};
`;

const contentWrapper: SerializedStyles = css`
  justify-content: space-between;
  height: 100%;
  align-items: center;
  padding-left: ${rem(24)};
  padding-right: ${rem(15)};
  transition: padding 0.3s;

  @media screen and (min-width: ${screenLarge}) {
    padding-left: ${rem(32)};
    padding-right: ${rem(35)};
  }
`;

const logo: SerializedStyles = css`
  width: ${rem(216)};
  display: block;
  background: url(${logoImageLong}) no-repeat;
  height: ${rem(24)};
  transition: width 0.3s;
  justify-self: flex-start;
  span {
    display: none;
  }

  @media screen and (max-width: ${screenSmall}) {
    width: ${rem(42)};
    background: url(${logoImage}) no-repeat;
  }
`;

const navContainer: SerializedStyles = css`
  height: 100%;
  justify-content: center;
`;

const nav = (isMenuOpen = false): SerializedStyles => css`
  height: 100%;
  min-width: ${rem(100)};
  max-width: ${rem(500)};

  @media screen and (max-width: ${screenSmall}) {
    position: absolute;
    top: 100%;
    background: ${theme.colors.white};
    width: 100%;
    left: 0;
    height: auto;
    display: ${isMenuOpen ? 'block' : 'none'};
    opacity: ${isMenuOpen ? 1 : 0};
    transition: opacity 0.3s;
    max-width: 100%;
  }
`;

const navUl: SerializedStyles = css`
  ${flexItem};
  margin: 0;
  padding: 0;
  @media screen and (max-width: ${screenSmall}) {
    flex-wrap: wrap;
    position: relative;
  }
`;

const navLi: SerializedStyles = css`
  ${flexItem};
  list-style: none;
  position: relative;
  margin-right: 3rem;
  &:last-child {
    margin-right: 0;
  }
  @media screen and (min-width: ${screenLarge}) {
    margin-right: ${theme.space[5]};
  }
  @media screen and (max-width: ${screenSmall}) {
    width: 50%;
    margin: 0;
    position: static;
  }
`;

const navLiWithTrigger: SerializedStyles = css`
  ${navLi};

  &:hover {
    &:after {
      content: '';
      position: absolute;
      left: -50%;
      width: 50%;
      bottom: 0;
      height: 100%;
    }
    &:before {
      content: '';
      position: absolute;
      right: -50%;
      width: 50%;
      bottom: 0;
      height: 100%;
    }
  }
`;

const navLink: SerializedStyles = css`
  ${flexItem};
  font-weight: 500;
  color: ${theme.colors.headerNavLink};
  text-decoration: none;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    background: ${theme.colors.headerNavLink};
    bottom: 0;
    height: ${rem(4)};
    opacity: 0;
  }

  &:hover {
    &:after {
      opacity: 1;
      transition: opacity 0.3s;
    }
  }

  @media screen and (max-width: ${screenSmall}) {
    width: 100%;
    border: 1px solid #ccc; /* TODO: use colors constants */
    padding: 10px;
    margin: 10px;
    transition: border-color 0.3s;

    &:after {
      display: none;
    }

    &:hover {
      border-color: ${theme.colors.black};
      &:after {
        display: none;
      }
    }
  }
`;

const mobileMenu: SerializedStyles = css`
  font-weight: 500;
  padding: 7px 28px 7px 13px;
  background-color: transparent;
  border: 1px solid ${theme.colors.headerNavLink};
  color: ${theme.colors.headerNavLink};
  align-items: center;
  display: none;
  cursor: pointer;
  @media screen and (max-width: ${screenSmall}) {
    display: flex;
  }
`;

const accountContainer: SerializedStyles = css`
  justify-content: flex-end;
  align-items: center;
  font-size: ${rem(16)};
`;

const hamburger = (isMenuOpen = false): SerializedStyles => css`
  display: block;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  height: 13px;
  position: relative;
  span {
    position: absolute;
    display: block;
    width: 14px;
    height: 2px;
    background-color: ${theme.colors.headerNavLink};
    border-radius: 2px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
  span:nth-of-type(1) {
    top: 0;
    transform-origin: left center;
    transform: rotate(${isMenuOpen ? '45deg' : 0});
  }
  span:nth-of-type(2) {
    top: 5px;
    transform-origin: left center;
    opacity: ${isMenuOpen ? 0 : 1};
  }
  span:nth-of-type(3) {
    top: 10px;
    transform: rotate(${isMenuOpen ? '-45deg' : 0});
    transform-origin: left center;
  }
`;

export default {
  header,
  logo,
  contentWrapper,
  navContainer,
  nav,
  navUl,
  navLi,
  navLink,
  mobileMenu,
  hamburger,
  accountContainer,
  navLiWithTrigger,
};
