import { useCallback, useState } from 'react';
import isEqualWith from 'lodash.isequalwith';

import type { Alert } from '../components/Alert';

export interface AlertItem
  extends Pick<
    InferComponentProps<typeof Alert>,
    'type' | 'shouldShake' | 'layout' | 'actions'
  > {
  content: React.ReactNode;
  persist?: boolean;
}

const useAlerts = (
  initialAlerts: AlertItem[] = [],
): {
  alerts: AlertItem[];
  addAlerts: (alerts: AlertItem[]) => void;
  clearAlerts: () => void;
} => {
  const [alerts, setAlerts] = useState<AlertItem[]>(initialAlerts);

  const addAlerts = useCallback(
    (alerts: AlertItem[]) =>
      setAlerts((state) => {
        /* To avoid duplicated alerts, we need to make sure we don't add same state twice.
         * To be improved on a future redesign of the alerts state management
         */
        if (!isEqualWith(state, alerts)) {
          return [...state, ...alerts];
        }

        return [...state];
      }),
    [],
  );

  const clearAlerts = useCallback(
    () => setAlerts((state) => state.filter((alert) => alert.persist)),
    [],
  );

  return { alerts, addAlerts, clearAlerts };
};

export default useAlerts;
