import type { FC } from 'react';
import { Box, Flex } from '@theme-ui/components';
import styled from '@emotion/styled';

import { isNotNullish } from '@app/helpers/predicate';

import type { GetProductsForFinder_getProducts_result_items_price as PriceType } from '../__generated__/GetProductsForFinder';
import theme from '../../../styles/theme';
import { rem } from '../../../styles/theme/layout';
import BundlePrice from '../BundlePrice';
import PriceBox from '../PriceBox';
import { Price, Wrapper } from '../PriceBox/styles';
import PriceToggle from '../PriceToggle';

const UPDATE_LICENSE_ID = 'update';

const Separator = styled.div`
  background-color: ${theme.colors.productFinderBg};
  width: ${rem(2)};
`;

export interface ProductPriceProps {
  price: DeepOmit<PriceType, '__typename'> | null;
  text: string | null;
  shouldReplacePrice: boolean | null;
  isFree: boolean | null;
  pricingPageUrl: string;
}

const ProductPrice: FC<ProductPriceProps> = ({
  isFree,
  price,
  text,
  shouldReplacePrice,
  pricingPageUrl,
}) => {
  if (shouldReplacePrice && (text || isFree)) {
    return (
      <Box className="product-price">
        <Flex sx={{ justifyContent: 'space-between' }}>
          <Wrapper>
            <Price>{isFree ? 'FREE' : text}</Price>
          </Wrapper>
        </Flex>
      </Box>
    );
  }

  if (price) {
    const { min, main, bundle } = price;
    const isOnSale = isNotNullish(main?.savePrice || min?.savePrice);
    const hasUpdate = min?.licenseId === UPDATE_LICENSE_ID;
    const hasDiscountedUpdate = min?.personalized;

    return (
      <Box className="product-price">
        {isOnSale && hasUpdate && !hasDiscountedUpdate ? (
          <PriceToggle price={price} pricingPageUrl={pricingPageUrl} />
        ) : (
          <a href={pricingPageUrl}>
            <Flex sx={{ justifyContent: 'space-between' }}>
              {min && <PriceBox {...min} />}
              {min && (main || bundle) && <Separator />}
              {bundle && <BundlePrice {...bundle} />}
              {!bundle && main && <PriceBox {...main} />}
            </Flex>
          </a>
        )}
      </Box>
    );
  }

  return null;
};

export default ProductPrice;
