import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Box, Flex } from '@theme-ui/components';
import { css } from '@emotion/react';

import Card from '@app/components/Card';
import { H2 } from '@app/components/typography';

import faqIcon from './assets/faq-icon.svg';
import supportIcon from './assets/support-icon.svg';
import messages from './Support.messages';
import {
  linkStyles,
  supportHeadingStyles,
  supportParagraphStyles,
} from './Support.styles';

const Support = () => {
  const { language = 'en' } = useParams<{ language?: string }>();
  const LINKS = {
    orderSupportLink: `https://support.native-instruments.com/hc/${language}/sections/360000092537/`,
    orderFaqLink: `https://www.native-instruments.com/${language}/shop-information/`,
  };

  return (
    <>
      <H2
        css={css`
          margin-bottom: 1.2rem;
        `}
      >
        <FormattedMessage {...messages.supportHeader} />
      </H2>
      <Card>
        <Flex sx={{ flexWrap: 'wrap', textAlign: 'center' }}>
          <Box sx={{ width: ['100%', '50%'] }} p="0.4rem">
            <img src={supportIcon} alt="" />
            <h3 css={supportHeadingStyles}>
              <FormattedMessage {...messages.helpTitle} />
            </h3>
            <p css={supportParagraphStyles}>
              <FormattedMessage {...messages.contactSupportDescription} />
            </p>
            <a
              href={LINKS.orderSupportLink}
              css={linkStyles}
              data-testid="order-support-link"
            >
              <FormattedMessage {...messages.orderSupport} />
            </a>
          </Box>

          <Box sx={{ width: ['100%', '50%'] }} p="0.4rem" mt={[40, 0]}>
            <img src={faqIcon} alt="" />
            <h3 css={supportHeadingStyles}>
              <FormattedMessage {...messages.shopFaq} />
            </h3>
            <p css={supportParagraphStyles}>
              <FormattedMessage {...messages.faqDescription} />
            </p>
            <a
              href={LINKS.orderFaqLink}
              css={linkStyles}
              data-testid="order-faq-link"
            >
              <FormattedMessage {...messages.shopFaq} />
            </a>
          </Box>
        </Flex>
      </Card>
    </>
  );
};

export default Support;
