import isMobile from 'is-mobile';
import cookie from 'js-cookie';
import { v4 as uuid } from 'uuid';
import { type FieldPolicy, type FieldReadFunction, gql } from '@apollo/client';

import canUseDOM from '@app/helpers/canUseDOM';
import { getLocalizationCountryCookie } from '@app/helpers/localization';

export const localTypeDefs = gql`
  extend type Query {
    abVisitorId: String!
    deviceType: String!
    referrer: String!
    referrerHost: String!
    localizationCountry: String!
  }
`;

const AB_VISITOR_ID_STORAGE_KEY = 'nicom_ab_visitor_id';

export const localQueryFields: Record<string, FieldPolicy | FieldReadFunction> =
  {
    abVisitorId: {
      read: (): string => {
        const stored = cookie.get(AB_VISITOR_ID_STORAGE_KEY);

        if (stored) return stored;

        const visitorId = uuid();

        cookie.set(AB_VISITOR_ID_STORAGE_KEY, visitorId);

        return visitorId;
      },
    },
    deviceType: {
      read: (): string => (isMobile() ? 'mobile' : 'desktop'),
    },
    referrer: {
      read: (): string => (canUseDOM() ? document.referrer : ''),
    },
    referrerHost: {
      read: (): string => {
        if (!canUseDOM()) {
          return '';
        }
        const referrer = document.referrer;
        if (referrer !== '') {
          return new URL(referrer).hostname;
        }
        return referrer;
      },
    },
    localizationCountry: {
      read: (): string => getLocalizationCountryCookie(),
    },
  };
