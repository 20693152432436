import { gql } from '@apollo/client';

const LanguageQuery = gql`
  query LanguageQuery {
    user {
      id
      language
    }
  }
`;

export default LanguageQuery;
