import type { ApolloQueryResult } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';

import type { IsAuthenticatedQuery } from './__generated__/IsAuthenticatedQuery';

export const QUERY = gql`
  query IsAuthenticatedQuery {
    user {
      id
      isAuthenticated
    }
  }
`;

interface AuthorizationHookResult
  extends Omit<ApolloQueryResult<IsAuthenticatedQuery>, 'data'> {
  isAuthenticated: boolean;
}

function useAuthentication(): AuthorizationHookResult {
  const { data, ...queryResult } = useQuery<IsAuthenticatedQuery>(QUERY, {
    ssr: false,
    fetchPolicy: 'network-only',
  });
  const isAuthenticated = Boolean(data?.user?.isAuthenticated);
  return { ...queryResult, isAuthenticated };
}

export default useAuthentication;
