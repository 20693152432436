/*
 * Footer messages
 *
 * This contains all the text for the Footer component
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  legal: {
    id: 'components.Footer.links.legal',
    defaultMessage: 'Legal Information',
  },
  privacy: {
    id: 'components.Footer.links.privacy',
    defaultMessage: 'Privacy Policy',
  },
  faq: {
    id: 'components.Footer.links.faq',
    defaultMessage: 'Shop Info & FAQ',
  },
  contact: {
    id: 'components.Footer.links.contact',
    defaultMessage: 'Contact',
  },
  manageCookie: {
    id: 'components.Footer.manageCookie',
    defaultMessage: 'Manage Cookies',
  },
});
