import styled from '@emotion/styled';

import { fadeIn, spinning } from '../../styles/animations';
import { rem } from '../../styles/theme/layout';
import { center } from '../../styles/utils';

export const Wrapper = styled.div`
  width: ${rem(34)};
  height: ${rem(34)};
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
  cursor: pointer;
`;

export const LoadingBox = styled.div`
  position: absolute;
  ${center};
  height: ${rem(30)};
  width: ${rem(30)};
  animation: ${fadeIn} 0.7s;
  &:after {
    z-index: 2;
    content: '';
    border-right: 5px solid #777;
    border-top: 5px solid #777;
    border-left: 5px solid #999;
    border-bottom: 5px solid #bbb;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    display: block;
    animation: ${spinning} 0.75s infinite linear;
    position: relative;
    left: -5px;
    top: -5px;
  }
`;

export default { Wrapper, LoadingBox };
